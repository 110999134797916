import { DomainModel } from '@domain/domain.model';

export class DefaultInventoryItem extends DomainModel {

  // Configuration
  public entity = 'default-inventory-item';
  public table = 'default_inventory_items';
  public schema = '++id, remote_id, default_inventory_id, default_item_id, type';
  public sync = true;

  // Fields
  public default_inventory_id: number;
  public default_item_id: number;
  public amount: number;
  public type: number;

  // Constructor
  constructor(attributes) {
    super(attributes);
  }
}
