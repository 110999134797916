import { Routes } from '@angular/router';
import { AuthGuard } from '@blueprint/auth/auth.guard';
import { UserGuard } from '@shared/guards/user.guard';
import { ClientGuard } from '@shared/guards/client.guard';
import { AddressGuard } from '@shared/guards/address.guard';
import { ContactGuard } from '@shared/guards/contact.guard';
import { ContactListGuard } from '@shared/guards/contact-list.guard';
import { OptionsGuard } from '@shared/guards/options.guard';
import { InventoryGuard } from '@shared/guards/inventory.guard';
import { QuotationGuard } from '@shared/guards/quotation.guard';
import { BootstrapGuard } from '@shared/guards/bootstrap.guard';
import { LoginComponent } from '@features/login/login.component';
import { UserOverviewComponent } from '@features/user/user-overview.component';
import { UserDetailComponent } from '@features/user/user-detail.component';
import { LoginLayoutComponent } from '@features/gui/layouts/loginLayout.component';
import { FooterNavigationLayoutComponent } from '@features/gui/layouts/footerNavigationLayout.component';
import { ProjectOverviewComponent } from '@features/project/project-overview.component';
import { InventoryFlowComponent } from '@features/inventory/inventory-flow.component';
import { InventoryAddressDetailComponent } from '@features/inventory/address/address-detail.component';
import { InventoryOptionsDetailComponent } from '@features/inventory/options/options-detail.component';
import { InventoryQuotationComponent } from '@features/inventory/quotation/quotation.component';
import { InventoryWorkAssignmentListComponent } from '@features/inventory/work-assignment/work-assignment-list.component';
import { InventoryWorkAssignmentDetailComponent } from '@features/inventory/work-assignment/work-assignment-detail.component';
import { InventoryBoardComponent } from '@features/inventory/inventory/inventory-board.component';
import { PopupLayoutComponent } from '@features/gui/layouts/popupLayout.component';
import { InventoryContactsDetailComponent } from '@features/inventory/contact/contact-detail.component';
import { InventoryClientComponent } from '@features/inventory/client/client.component';
import { InventoryAddressListComponent } from '@features/inventory/address/address-list.component';
import { InventoryClientSearchComponent } from '@features/inventory/client/client-search.component';
import { InventoryAddComponent } from '@features/inventory/inventory/add/inventory-add.component';
import { InventoryContactListComponent } from '@features/inventory/contact/contact-list.component';
import { SettingsOverviewComponent } from '@features/settings/settings-overview.component';
import { InventoryPicturesComponent } from '@features/inventory/pictures/pictures.component';
import { InventoryPictureItemDetailComponent } from '@features/inventory/pictures/picture-item-detail/picture-item-detail.component';
import { MaterialComponent } from '@features/inventory/material/material.component';
import { InventoryOverviewComponent } from '@features/inventory/overview/overview.component';
import { InventoryAddressSortComponent } from '@features/inventory/address/address-sort/address-sort.component';
import { ManageComponent } from '@features/manage/manage.component';
import { ManageCarsListComponent } from '@features/manage/cars/cars-list/cars-list.component';
import { ManageCarsDetailComponent } from '@features/manage/cars/cars-detail/cars-detail.component';
import { ManageAddressesListComponent } from '@features/manage/addresses/addresses-list/addresses-list.component';
import { ManageAddressesDetailComponent } from '@features/manage/addresses/addresses-detail/addresses-detail.component';
import { ManageContactsListComponent } from '@features/manage/contacts/contacts-list/contacts-list.component';
import { ManageContactsDetailComponent } from '@features/manage/contacts/contacts-detail/contacts-detail.component';
import { ManageBarracksListComponent } from '@features/manage/barracks/barracks-list/barracks-list.component';
import { ManageBarracksDetailComponent } from '@features/manage/barracks/barracks-detail/barracks-detail.component';
import { ManageTopMoversListComponent } from '@features/manage/topmovers/topmovers-list/topmovers-list.component';
import { ManageTopMoversDetailComponent } from '@features/manage/topmovers/topmovers-detail/topmovers-detail.component';
import { QuotationSignComponent } from '@features/quotationSign/quotation-sign.component';
import { ResetPasswordComponent } from '@features/login/reset-password/reset-password.component';
import { ReportComponent } from '@features/report/report.component';
import { ManageOrganisationsListComponent } from '@features/manage/organisations/organisations-list/organisations-list.component';
import { ManageOrganisationsDetailComponent } from '@features/manage/organisations/organisations-detail/organisations-detail.component';

export const ROUTES: Routes = [
  // Admin section
  {
    path: 'admin',
    canActivate: [AuthGuard, BootstrapGuard],
    canActivateChild: [AuthGuard],
    component: FooterNavigationLayoutComponent,
    children: [
      // Main redirect
      {
        path: '',
        redirectTo: 'projects',
        pathMatch: 'full'
      },

      // App views
      {
        path: 'settings',
        component: SettingsOverviewComponent
      },
      {
        path: 'report',
        component: ReportComponent
      },
      {
        path: 'projects',
        component: ProjectOverviewComponent
      },
      {
        path: 'users',
        component: UserOverviewComponent,
        canActivate: [UserGuard]
      },
      {
        path: 'project/:project',
        component: InventoryFlowComponent,
        children: [
          {
            path: 'client',
            component: InventoryClientComponent,
            canDeactivate: [ClientGuard]
          },
          {
            path: 'address',
            component: InventoryAddressListComponent,
            canDeactivate: [AddressGuard]
          },
          {
            path: 'contact',
            component: InventoryContactListComponent,
            canDeactivate: [ContactGuard]
          },
          {
            path: 'options',
            component: InventoryOptionsDetailComponent,
            canActivate: [ContactListGuard],
            canDeactivate: [OptionsGuard]
          },
          {
            path: 'inventory',
            component: InventoryBoardComponent,
            canActivate: [ContactListGuard],
            canDeactivate: [InventoryGuard]
          },
          {
            path: 'material',
            component: MaterialComponent,
            canActivate: [ContactListGuard]
          },
          {
            path: 'overview',
            component: InventoryOverviewComponent,
            canActivate: [ContactListGuard]
          },
          {
            path: 'quotation',
            component: InventoryQuotationComponent,
            canActivate: [QuotationGuard, ContactListGuard]
          },
          {
            path: 'pictures',
            component: InventoryPicturesComponent,
            canActivate: [ContactListGuard]
          },
          {
            path: 'work-assignment',
            component: InventoryWorkAssignmentListComponent,
            canActivate: [ContactListGuard]
          },
          {
            path: 'work-assignment/:id',
            component: InventoryWorkAssignmentDetailComponent,
            canActivate: [ContactListGuard]
          }
        ]
      },
      {
        path: 'manage',
        canActivate: [],
        children: [
          {
            path: 'addresses',
            component: ManageAddressesListComponent
          },
          {
            path: 'barracks',
            component: ManageBarracksListComponent
          },
          {
            path: 'cars',
            component: ManageCarsListComponent
          },
          {
            path: 'contacts',
            component: ManageContactsListComponent
          },
          {
            path: 'organisations',
            component: ManageOrganisationsListComponent
          },
          {
            path: 'topmovers',
            component: ManageTopMoversListComponent
          },
          {
            path: '',
            component: ManageComponent
          }
        ]
      },
    ]
  },
  {
    path: 'admin',
    canActivate: [AuthGuard, BootstrapGuard],
    canActivateChild: [AuthGuard],
    component: PopupLayoutComponent,
    outlet: 'popup',
    children: [
      {
        path: 'project/address/add',
        component: InventoryAddressDetailComponent
      },
      {
        path: 'project/address/:id',
        component: InventoryAddressDetailComponent
      },
      {
        path: 'manage/addresses/:id',
        component: ManageAddressesDetailComponent
      },
      {
        path: 'manage/barracks/:id',
        component: ManageBarracksDetailComponent
      },
      {
        path: 'manage/cars/:id',
        component: ManageCarsDetailComponent
      },
      {
        path: 'manage/contacts/:id',
        component: ManageContactsDetailComponent
      },
      {
        path: 'manage/organisations/add',
        component: ManageOrganisationsDetailComponent
      },
      {
        path: 'manage/organisations/:id',
        component: ManageOrganisationsDetailComponent
      },
      {
        path: 'manage/topmovers/:id',
        component: ManageTopMoversDetailComponent
      },
      {
        path: 'project/address-sort',
        component: InventoryAddressSortComponent
      },
      {
        path: 'project/contact/add',
        component: InventoryContactsDetailComponent
      },
      {
        path: 'project/contact/:id',
        component: InventoryContactsDetailComponent
      },
      {
        path: 'project/client-search',
        component: InventoryClientSearchComponent
      },
      {
        path: 'project/inventory/add',
        component: InventoryAddComponent
      },
      {
        path: 'project/pictures/add',
        component: InventoryPictureItemDetailComponent
      },
      {
        path: 'project/pictures/:id',
        component: InventoryPictureItemDetailComponent
      },
      {
        path: 'user/:id',
        component: UserDetailComponent,
        canActivate: [UserGuard]
      }
    ]
  },
  {
    path: '',
    redirectTo: 'admin/projects',
    pathMatch: 'full'
  },
  {
    path: 'quotation/sign/:id/:project_id',
    component: QuotationSignComponent
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'password/reset/:token',
        component: ResetPasswordComponent
      }
    ]
  },
  // Handle all other routes
  {
    path: '**',
    redirectTo: 'admin'
  }
];
