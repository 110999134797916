import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WorkAssignmentItem } from '@domain/models/work-assignment-item.model';
import { Machine } from '@domain/models/machine.model';
import { SelectItem } from 'primeng/api';

@Component({
    selector: 'app-inventory-work-assignment-dropdown-item',
    templateUrl: 'work-assignment-dropdown-item.component.html',
    styleUrls: ['./work-assignment-dropdown-item.component.scss']
})
export class InventoryWorkAssignmentDropdownItemComponent implements OnInit {
    @Input('item') item: WorkAssignmentItem;
    @Input('placeholderName') placeholderName;
    @Input('machines') machines: Machine[];
    @Output('onDeleteItemClick') onDeleteItemClick = new EventEmitter();

    public times: SelectItem[] = [];

    constructor() {
        // Fill times items
        for (let i = 0; i < 24; i++) {
            this.times.push({ label: i + ':00', value: i + ':00' });
            this.times.push({ label: i + ':15', value: i + ':15' });
            this.times.push({ label: i + ':30', value: i + ':30' });
            this.times.push({ label: i + ':45', value: i + ':45' });
        }
    }

    public ngOnInit(): void {
    }

    public onDeleteClick(): void {
        this.onDeleteItemClick.emit(this.item);
    }
}
