import { DomainModel } from '@domain/domain.model';
import { Location } from '@domain/models/location.model';
import { Organisation } from '@domain/models/organisation.model';

export class User extends DomainModel {

  // Configuration
  public entity = 'user';
  public table = 'users';
  public schema = '++id, remote_id, role_id, name_lcase, location_id';
  public sync = false;

  // Fields
  public role_id: number;
  public location_id: number;
  public id_number: string;
  public date_of_birth: string | Date;
  public vca: boolean;
  public vog: boolean;
  public sr: boolean;
  public is_location_manager: boolean;
  public first_name: string;
  public last_name: string;
  public name_lcase: string;
  public email: string;
  public password?: string;
  public password_confirmation?: string;
  public is_topmover?: boolean;

  public location: Location;
  public organisations: Organisation[] = [];

  // Constructor
  constructor(attributes) {
    super(attributes);
  }
}
