import { DomainModel } from '@domain/domain.model';

import * as uuid from 'uuid/v4';

export class OrganisationUsers extends DomainModel {
  // Configuration
  public entity = 'organisation_user';
  public table = 'organisation_user';
  public schema = '[organisation_id+user_id], organisation_id, user_id';
  public sync = true;

  // Fields
  public organisation_id: string;
  public user_id: number;

  public getData(): any {
    return {
      organisation_id: this.organisation_id,
      user_id: this.user_id,
    };
  }
}
