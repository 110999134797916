import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '@blueprint/auth/auth.service';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public currentRoute = 'home';
  public columnClass = 'col-xs-3';

  constructor(private router: Router,
              private authService: AuthService,
              public user: UserService) {
  }

  public ngOnInit(): void {
    this.setCurrentRoute(this.router.url);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setCurrentRoute(this.router.url);
      }
    });

    if (this.user.isAdministrator()) {
      this.columnClass = 'col-xs-2 col-xs-2-4';
    }
  }

  public navigate(url): void {
    this.setCurrentRoute(url);
    this.router.navigate([url]);
  }

  public logout(): void {
    this.authService.logout().subscribe((result) => {
      if (result) {
        this.router.navigate(['/login']);
      }
    });
  }

  private setCurrentRoute(url: string): void {
    if (url.includes('/admin/projects')) {
      this.currentRoute = 'home';
    } else if (url.includes('/admin/project')) {
      this.currentRoute = 'project';
    } else if (url.includes('/admin/manage')) {
      this.currentRoute = 'manage';
    } else if (url.includes('/admin/report')) {
      this.currentRoute = 'report';
    } else if (url.includes('/admin/users')) {
      this.currentRoute = 'users';
    } else if (url.includes('/admin/settings')) {
      this.currentRoute = 'settings';
    } else {
      this.currentRoute = 'home';
    }
  }
}
