import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OnlineTableOptions } from '@shared/controls/table/online-table.component';
import { ProjectService } from '@shared/services/project.service';

@Component({
  selector: 'app-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.scss']
})
export class ManageContactsListComponent implements OnInit {
  public tableOptions: OnlineTableOptions;
  public tableVisible: boolean;

  constructor(private router: Router,
              private projectService: ProjectService) {
    this.tableVisible = true;
  }

  public async ngOnInit(): Promise<void> {
    this.tableOptions = new OnlineTableOptions({
      columns: [
        { title: 'Naam', name: 'name', filter: { global: true } },
        { title: 'E-mail', name: 'email', filter: { global: true } },
        { title: 'Telefoon', name: 'phone', filter: { global: true } }
      ],
      endpoint: '/contact-template',
      search: true,
      withDelete: true,
      withFilter: false
    });

    // In case contact is added (from popup)
    this.projectService.contactTemplatesUpdated$.subscribe(() => {
      this.tableVisible = false;

      setTimeout(() => {
        this.tableVisible = true;
      }, 0);
    });
  }

  public onRowClick(row: any) {
    this.router.navigateByUrl(`admin/manage/contacts(popup:admin/manage/contacts/${row.id})`);
  }

  public addNewContact(): void {
    this.router.navigateByUrl('/admin/manage/contacts(popup:admin/manage/contacts/add)');
  }
}
