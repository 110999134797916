import { DomainModel } from '@domain/domain.model';

export class DefaultInventory extends DomainModel {

  // Configuration
  public entity = 'default-inventory';
  public table = 'default_inventories';
  public schema = '++id, remote_id';
  public sync = true;

  // Fields
  public name: string;

  // Constructor
  constructor(attributes) {
    super(attributes);
  }
}
