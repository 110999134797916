import { Injectable } from '@angular/core';
import { Project } from '@domain/models/project.model';
import { from, Observable } from '@node_modules/rxjs';
import { BulkPrice } from '@domain/models/bulk-price.model';
import { InventoryItem } from '@domain/models/inventory-item.model';
import { map } from '@node_modules/rxjs/internal/operators';
import { BulkPriceTypes } from '@shared/enums/bulk-price-type.enum';
import { InventoryType } from '@shared/enums/inventory-type.enum';


@Injectable({ providedIn: 'root' })
export class QuotationService {
  public getInventoryItemPrices(project: Project): Observable<Record<BulkPriceTypes, number>> {
    let totals: Record<BulkPriceTypes, number>;
    const bulkPrices$: Observable<BulkPrice[]> = from(BulkPrice.query.toArray());
    const items: InventoryItem[] = project.inventories.reduce((acc, inventory) => {
      return [...acc, ...inventory.items.filter((item) => item.amount > 0)]
    }, []);

    totals = items.reduce((acc, item) => {
      if (item.disassemble) {
        if (item.type === InventoryType.internal) {
          acc[BulkPriceTypes.internalIncludingDisassembly] += item.amount * +item.volume;
        } else {
          acc[BulkPriceTypes.externalIncludingDisassembly] += item.amount * +item.volume;
        }
      } else {
        if (item.type === InventoryType.internal) {
          acc[BulkPriceTypes.internalExcludingDisassembly] += item.amount * +item.volume;
        } else {
          acc[BulkPriceTypes.externalExcludingDisassembly] += item.amount * +item.volume;
        }
      }

      return acc;
    }, {
      [BulkPriceTypes.externalExcludingDisassembly]: 0,
      [BulkPriceTypes.externalIncludingDisassembly]: 0,
      [BulkPriceTypes.internalIncludingDisassembly]: 0,
      [BulkPriceTypes.internalExcludingDisassembly]: 0,
    });
    const today = new Date();

    return bulkPrices$.pipe(
        map((bulkPrices) => {
          const prices: Record<BulkPriceTypes, number> = {
            [BulkPriceTypes.externalExcludingDisassembly]: 0,
            [BulkPriceTypes.externalIncludingDisassembly]: 0,
            [BulkPriceTypes.internalIncludingDisassembly]: 0,
            [BulkPriceTypes.internalExcludingDisassembly]: 0,
          };

          Object.keys(totals).forEach((bulkPriceType) => {
            const price = bulkPrices.find((bulkPrice) => {
              return totals[bulkPriceType] > 0 && bulkPrice.volume_min <= totals[bulkPriceType] &&
                  bulkPrice.volume_max >= totals[bulkPriceType] &&
                  today <= new Date(bulkPrice.end_date) &&
                  today >= new Date(bulkPrice.start_date) &&
                  bulkPrice.bulkPriceTypeKey === bulkPriceType;
            });

            if (price) {
              prices[bulkPriceType] = price.price * totals[bulkPriceType];
            }
          });

          return prices;
        }),
    );
  }
}
