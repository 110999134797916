import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Project } from '@domain/models/project.model';
import { Address } from '@domain/models/address.model';
import { WorkAssignment } from '@domain/models/work-assignment.model';
import { WorkAssignmentItem } from '@domain/models/work-assignment-item.model';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ProjectService } from '@shared/services/project.service';
import { DataService } from '@shared/services/data.service';
import { SignatureComponent } from '@shared/controls/signature/signature.component';
import { WorkAssignmentAddress } from '@domain/models/work-assignment-address.model';
import { SelectItem } from 'primeng/primeng';
import { CalendarLocale } from '@domain/models/calendar-locale.model';
import { EnvironmentService } from '@shared/services/environment.service';
import { Machine } from '@domain/models/machine.model';
import { User } from '@domain/models/user.model';
import { UserService } from '@shared/services/user.service';
import { Car } from '@domain/models/car.model';

@Component({
  selector: 'app-inventory-work-assignment-detail',
  templateUrl: 'work-assignment-detail.component.html',
  styleUrls: ['./work-assignment-detail.component.scss']
})
export class InventoryWorkAssignmentDetailComponent implements OnInit, OnDestroy {
  @ViewChild('clientSignature')
  clientSignature: SignatureComponent;

  @ViewChild('teamleaderSignature')
  teamleaderSignature: SignatureComponent;

  public project: Project;
  public workAssignment: WorkAssignment;
  public addressOptionsPickup: SelectItem[] = [];
  public addressOptionsDelivery: SelectItem[] = [];
  public times = [];
  public executorList: SelectItem[];
  public carsList: SelectItem[];
  public localeNL: CalendarLocale = new CalendarLocale();
  public machines: any[] = [];

  private addresses: Address[];
  private subscriptionProjectLoaded: Subscription;

  constructor(public environmentService: EnvironmentService,
              private route: ActivatedRoute,
              private router: Router,
              private syncService: SynchronisationService,
              private projectService: ProjectService,
              private dataService: DataService,
              private userService: UserService) {
    this.project = this.projectService.getProject();
    this.addresses = this.project.addresses;
    this.workAssignment = new WorkAssignment({});

    this.subscriptionProjectLoaded = this.projectService.projectLoaded$.subscribe(project => {
      this.project = project;
      this.addresses = this.project.addresses;
      this.initialize();
    });

    // Fill times items
    for (let i = 0; i < 24; i++) {
      this.times.push({ label: i + ':00', value: i + ':00' });
      this.times.push({ label: i + ':15', value: i + ':15' });
      this.times.push({ label: i + ':30', value: i + ':30' });
      this.times.push({ label: i + ':45', value: i + ':45' });
    }

    this.initialize();
  }

  public async ngOnInit(): Promise<void> {
    // Get id of address to edit by route params
    this.route.params.subscribe(async params => {
      const result = await this.projectService.getWorkAssignment(params['id']);
      if (result) {
        this.workAssignment = result;
      }
    });

    this.setMachines();
    this.setExcecutorList();
    this.setCarsList();
  }

  public ngOnDestroy() {
    // Cleanup address items without selected addres
    this.workAssignment.address_work_assignments = this.workAssignment.address_work_assignments.filter((value: WorkAssignmentAddress) => {
      return value.address_id !== null;
    });

    this.projectService.saveWorkAssignment(this.workAssignment);
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }
  }

  public onAddPersonClick() {
    this.addWorkAssignmentItem('person');
  }

  public onAddCarClick() {
    this.addWorkAssignmentItem('car');
  }

  public onAddAddressClick(type: string): void {
    const newWorkAssignmentAddress = new WorkAssignmentAddress({
      work_assignment_id: this.workAssignment.id,
      type: type,
      _new: true,
      address_id: null
    });

    newWorkAssignmentAddress.setDefaults();
    this.workAssignment.address_work_assignments.push(newWorkAssignmentAddress);
  }

  public onDeleteItemClick(item: WorkAssignmentItem) {
    this.removeWorkAssignmentItem(item);
  }

  public showClientSignatureForm() {
    this.clientSignature.showForm();
  }

  public showTeamleaderSignatureForm() {
    this.teamleaderSignature.showForm();
  }

  public redirectToOverview(): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/work-assignment');
  }

  /**
   * On Executor change event
   *
   * @param event: any
   *
   * @returns void
   */
  public onExecutorChange(event: any): void {
    if (!event) {
      return;
    }

    const index = this.workAssignment.items.indexOf(event.item);

    if (!index) {
      return;
    }

    this.workAssignment.items[index].executor_id = event.value;
  }

  /**
   * On Car change event
   *
   * @param event: any
   *
   * @returns void
   */
  public onCarChange(event: any): void {
    if (!event) {
      return;
    }

    const index = this.workAssignment.items.indexOf(event.item);

    if (!index) {
      return;
    }

    this.workAssignment.items[index].car_id = event.value;
  }

  public onDeleteAddressClick(address: any): void {
    const index = this.workAssignment.address_work_assignments.indexOf(address);
    if (index === -1) {
      return;
    }

    this.workAssignment.address_work_assignments.splice(index, 1);
    this.projectService.deleteWorkAssignmentAddress(address);
  }

  /**
   * Print the work-assignment overview
   */
  public printOverview(): void {
    const divToPrint = document.getElementById('work-assignment');
    const head = document.getElementsByTagName('head')[0];
    const popupWin = window.open('', '_blank', 'width=1024,height=768');
    popupWin.document.open();
    popupWin.document.write('<html>' +
      ' <head>' + head.innerHTML + '</head>' +
      ' <body style="background-color: #fff">' +
      '  <img src="/assets/images/logo.png" style="height: 100px; display: flex; margin: 25px auto 0 auto;"><br /><br />' +
      '   ' + divToPrint.innerHTML + '' +
      ' </body>' +
      '</html>');

    // Make address dropdowns full width, as the button is removed in printing
    const viewChildDropdownElementsAddress = popupWin.document.getElementsByClassName('dropdowns');
    for (let i = 0; i < viewChildDropdownElementsAddress.length; i++) {
      viewChildDropdownElementsAddress[i].classList.remove('col-xs-10');
      viewChildDropdownElementsAddress[i].classList.add('col-xs-12');
    }

    // Timeout to make sure the window is loaded
    setTimeout(() => popupWin.print(), 300);
    popupWin.document.close();
  }

  /**
   * Returns the address in one complete string
   *
   * @param address: Address
   * @return string
   */
  public getDisplayAddress(address: Address): string {
    const addressParts = [
      `${address.street || ''} ${address.housenumber || ''}${address.housenumber_add || ''}`.trim(),
      `${address.zipcode || ''} ${address.city || ''}`.trim(),
      `${address.country}`.trim()
    ];

    return addressParts.reduce((prev, current) => (prev && current ? prev + ', ' : prev) + current);
  }

  public hasMachines(): boolean {
    return this.machines.length > 0;
  }

  private async setMachines(): Promise<void> {
    await Machine.query.each(machine => this.machines.push({ label: machine.name, value: machine.name }));
    this.machines = this.dataService.sortDropdownByLabel(this.machines);
  }

  private async setExcecutorList(): Promise<void> {
    const result = await User.query.where('location_id').equals(this.userService.hasLocation()).toArray();

    if (!result || result.length < 1) {
      return;
    }

    // Get executors
    this.executorList = [];
    result.forEach((item) => {
      if (item.role.code === 'transporter') {
        this.executorList.push({ label: `${item.first_name} ${item.last_name}`, value: item.id });
      }
    });

    this.executorList = this.dataService.sortDropdownByLabel(this.executorList);
  }

  private async setCarsList(): Promise<void> {
    const result = await Car.query.where('location_id').equals(this.project.location.id).toArray();

    // Get cars
    this.carsList = [];
    result.forEach((item) => {
        this.carsList.push({ label: `${item.name}`, value: item.id });
    });

    this.carsList = this.dataService.sortDropdownByLabel(this.carsList);
  }

  private addWorkAssignmentItem(type: string) {
    const item = new WorkAssignmentItem({ work_assignment_id: this.workAssignment.id, type: type });
    item.setDefaults();
    this.workAssignment.items.push(item);
  }

  private removeWorkAssignmentItem(item: WorkAssignmentItem) {
    const index = this.workAssignment.items.indexOf(item);
    if (index === -1) {
      return;
    }
    this.workAssignment.items.splice(index, 1);
    this.projectService.deleteWorkAssignmentItem(item);
  }

  private initialize() {
    // Add address options
    this.addressOptionsPickup = [];
    this.addressOptionsDelivery = [];
    for (const address of this.addresses) {
      // TODO: filter on type 'pickup' and 'delivery'; not relevant right now
      this.addressOptionsPickup.push({ label: address.getDisplayName(), value: address.id });
      this.addressOptionsDelivery.push({ label: address.getDisplayName(), value: address.id });
    }

    this.addressOptionsPickup = this.dataService.sortDropdownByLabel(this.addressOptionsPickup);
    this.addressOptionsDelivery = this.dataService.sortDropdownByLabel(this.addressOptionsDelivery);
  }
}
