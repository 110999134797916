import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/observable/fromEvent';

import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';

import { environment } from '@environments/environment';

// Emendis Blueprint
import { EmendisBlueprintModule } from '@blueprint/emendis-blueprint.module';

import { TableModule } from 'primeng/table';

// App services
import { AuthGuard } from '@blueprint/auth/auth.guard';
import { AuthService } from '@blueprint/auth/auth.service';

import { UserService } from '@shared/services/user.service';
import { DataService } from '@shared/services/data.service';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { EnvironmentService } from '@shared/services/environment.service';

// App modules/components
import { LayoutsModule } from '@features/gui/layouts/layouts.module';
import { LoginModule } from '@features/login/login.module';
import { UserModule } from '@features/user/user.module';
import { ProjectModule } from '@features/project/project.module';
import { ManageModule } from '@features/manage/manage.module';
import { InventoryModule } from '@features/inventory/inventory.module';
import { SettingsModule } from '@features/settings/settings.module';

import { ProjectGuard } from '@shared/guards/project.guard';
import { UserGuard } from '@shared/guards/user.guard';
import { ClientGuard } from '@shared/guards/client.guard';
import { AddressGuard } from '@shared/guards/address.guard';
import { ContactGuard } from '@shared/guards/contact.guard';
import { ContactListGuard } from '@shared/guards/contact-list.guard';
import { OptionsGuard } from '@shared/guards/options.guard';
import { InventoryGuard } from '@shared/guards/inventory.guard';
import { QuotationGuard } from '@shared/guards/quotation.guard';
import { BootstrapGuard } from '@shared/guards/bootstrap.guard';
import { SignaturePadModule } from 'angular2-signaturepad';

/** Sentry init */
import * as Raven from 'raven-js';
import { ServiceWorkerModule } from '@angular/service-worker';
import { QuotationSignApiService } from '@shared/services/api/quotation-sign-api.service';
import { QuotationSignModule } from '@features/quotationSign/quotation-sign.module';
import { QuotationSignService } from '@shared/services/quotation-sign.service';
import { ApiModule } from '@node_modules/@emendis/api';
import { AuthModule } from '@node_modules/@emendis/auth';
import { ReportModule } from "@features/report/report.module";
import { ProgressSpinnerModule } from '@node_modules/primeng/components/progressspinner/progressspinner';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { OnlineStatusService } from '@shared/services/online-status.service';

// if (environment.production) {
//   Raven.config(
//     'https://8f8967473439457489231f2dd674a14e@sentry.emendistest.nl/2'
//   ).install();
// }

export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    // if (environment.production) {
    //   Raven.captureException(err);
    // } else {
    //   throw err;
    // }
  }
}

/** Error handler method for ApiConfig */
export function onAuthError(): void {
  window.location.href = '/login';
  localStorage.clear();
}

registerLocaleData(localeNl, 'nl');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpModule,
    LayoutsModule,
    EmendisBlueprintModule,
    LoginModule,
    UserModule,
    ProjectModule,
    ManageModule,
    ReportModule,
    SettingsModule,
    InventoryModule,
    SignaturePadModule,
    QuotationSignModule,
    TableModule,
    ProgressSpinnerModule,
    ApiModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      onAuthError: onAuthError
    }),
    AuthModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production
    }),
    RouterModule.forRoot(ROUTES, { enableTracing: false }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    { provide: ErrorHandler, useClass: RavenErrorHandler },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    ProjectGuard,
    ClientGuard,
    UserGuard,
    AddressGuard,
    ContactGuard,
    ContactListGuard,
    OptionsGuard,
    AuthGuard,
    InventoryGuard,
    QuotationGuard,
    BootstrapGuard,
    AuthService,
    DataService,
    UserService,
    SynchronisationService,
    EnvironmentService,
    QuotationSignApiService,
    QuotationSignService,
    OnlineStatusService,
    ApiServiceWithLoaderService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
