import { Component, Input, Output, EventEmitter } from '@angular/core';
import { WorkAssignmentAddress } from '@domain/models/work-assignment-address.model';
import { SelectItem } from '@node_modules/primeng/components/common/selectitem';

@Component({
  selector: 'app-workassignment-address-detail',
  templateUrl: './workassignment-address-detail.component.html',
  styleUrls: ['./workassignment-address-detail.component.scss']
})
export class WorkassignmentAddressDetailComponent {
  @Input('addresses')
  addresses: SelectItem[];
  @Input('address')
  address: WorkAssignmentAddress;

  @Output('onDeleteAddressClick')
  onDeleteAddressClick = new EventEmitter();

  constructor() {}

  public onDeleteClick(address: any): void {
    this.onDeleteAddressClick.emit(address);
  }
}
