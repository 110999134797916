import { Component, EventEmitter, Output, OnInit, Input } from '@angular/core';
import { InventoryItem } from '@domain/models/inventory-item.model';
import { FormBuilder, Validators } from '@angular/forms';
import { ProjectService } from '@shared/services/project.service';

@Component({
  selector: 'app-inventory-add-item',
  templateUrl: 'inventory-add-item.component.html'
})
export class InventoryAddItemComponent implements OnInit {
  @Output('newItem') newItem = new EventEmitter();
  @Input('disabled') disabled;

  public form;
  public showErrors = false;

  private inventoryItem = new InventoryItem({});

  constructor(private formBuilder: FormBuilder, private projectService: ProjectService) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control(this.inventoryItem.id),
      name: this.formBuilder.control('', Validators.required),
      remarks: this.formBuilder.control(''),
      volume: this.formBuilder.control('')
    });
  }

  submit() {
    if (this.form.valid) {
      this.inventoryItem = new InventoryItem({ ...this.form.value });
      this.newItem.emit(this.inventoryItem);
    } else {
      this.showErrors = true;
    }
  }
}
