import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { ConfirmationService } from 'primeng/primeng';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { OfflineTableOptions, OfflineTableComponent } from '@shared/controls/table/offline-table.component';
import { WorkAssignment } from '@domain/models/work-assignment.model';

@Component({
  selector: 'app-inventory-work-assignment-list',
  templateUrl: 'work-assignment-list.component.html',
  styleUrls: ['./work-assignment-list.component.scss']
})
export class InventoryWorkAssignmentListComponent implements OnInit, OnDestroy {
  @ViewChild(OfflineTableComponent) table: OfflineTableComponent;

  public tableOptions: OfflineTableOptions;

  private project: Project;
  private subscriptionProjectLoaded: Subscription;

  constructor(private router: Router,
              private confirmationService: ConfirmationService,
              private projectService: ProjectService) {
  }

  public ngOnInit() {
    this.project = this.projectService.getProject();
    this.subscriptionProjectLoaded = this.projectService.projectLoaded$.subscribe((project) => {
      this.project = project;

      this.setTableOptions();
      this.projectService.setCurrentClient(this.project.client);
    });

    this.setTableOptions();
  }

  public ngOnDestroy() {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }
  }

  /**
   * On table row click
   *
   * @param data: any
   *
   * @returns void
   */
  public onRowClick(data: any): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/work-assignment/' + data.id);
  }

  /**
   * On add click
   *
   * @returns Promise<void>
   */
  public async onAddClick(): Promise<void> {
    // Create new work assignment, cope work description and open detail
    const workAssignment = new WorkAssignment({
      project_id: this.project.id,
      description_own: this.project.own_description_activities,
      description_client: this.project.client_description_activities
    });

    workAssignment.setDefaults();

    await this.projectService.saveWorkAssignment(workAssignment);

    this.router.navigateByUrl('/admin/project/' + this.project.id + '/work-assignment/' + workAssignment.id);
  }

  /**
   * On delete click
   *
   * @returns void
   */
  public onDeleteClick(): void {
    if (!this.table || !this.table.selectedRows || this.table.selectedRows.length === 0) {
      return;
    }

    this.confirmationService.confirm({
      message: 'Wilt u de geselecteerde werkopdrachten verwijderen?',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      accept: () => {
        this.table.selectedRows.forEach(async (workAssignment: WorkAssignment) => {
          await this.projectService.deleteWorkAssignment(workAssignment);
        });

        this.table.onChangeTable();
      }
    });
  }

  /**
   * Send work assignment after synchronisation
   *
   * @returns void
   */
  public setSendWorkAssignment(): void {
    this.confirmationService.confirm({
      message: 'Wilt u dat de geselecteerde werkopdrachten na het synchroniseren worden verstuurt?',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      accept: async () => {
        await this.saveEachWorkAssignment(true);
      },
      reject: async () => {
        await this.saveEachWorkAssignment(false);
      }
    });
  }

  /**
   * Export to PDF
   *
   * @returns void
   */
  public printOverview(): void {
    const divToPrint = document.getElementById('work-assignment-overview');
    const head = document.getElementsByTagName('head')[0];
    const popupWin = window.open('', '_blank', 'width=1024,height=768');

    popupWin.document.open();

    popupWin.document.write('<html>' +
      ' <head>' + head.innerHTML + '</head>' +
      ' <body style="background:#fff;">' +
      '  <img src="/assets/images/logo.png" style="height: 100px; display: flex; margin: 25px auto 0 auto;"><br /><br />' +
      '   ' + divToPrint.innerHTML + '' +
      ' </body>' +
      '</html>');
    popupWin.document.getElementById('work-assignment-overview-print-button').remove();
    popupWin.document.getElementById('work-assignment-overview-title').innerText = 'Overzicht - ' + this.project.clientName +
      (this.project.reference_nr.length > 0 ? ' (' + this.project.reference_nr + ')' : '');
    popupWin.document.getElementById('work-assignment-overview-footer').remove();

    const elements = popupWin.document.getElementsByTagName('h5');
    for (let i = 0; i < elements.length; i++) {
      elements[i].setAttribute('style', 'font-size: 18px; font-weight: bold;');
    }

    /** Timeout to make sure the window is loaded */
    setTimeout(() => popupWin.print(), 300);
    popupWin.document.close();
  }

  /**
   * Loops through the selected items and saves them individual
   *
   * @param status
   */
  private async saveEachWorkAssignment(status: boolean): Promise<void> {
    this.table.selectedRows.forEach(async (workAssignment: WorkAssignment) => {
      workAssignment.send_work_assignment = status;
      await this.projectService.saveWorkAssignment(workAssignment);
    });

    this.updateTable();
  }

  /**
   * Updates the table and set the selected rows to none
   */
  private updateTable(): void {
    this.setTableOptions();
    this.table.selectedRows = {};
  }

  /**
   * Set dependencies
   *
   * @returns void
   */
  private setTableOptions(): void {
    this.tableOptions = new OfflineTableOptions({
      paging: false,
      search: false,
      withDelete: true,
      columns: [
        { title: 'Datum', name: 'date' },
        { title: 'Laadadres', name: 'pickupAddressName' },
        { title: 'Losadres', name: 'deliverAddressName' },
        { title: 'Verzenden', name: 'sendWorkAssignment' }
      ],
      noResultsMessage: 'Er zijn nog geen werkopdrachten aangemaakt',
      url: '/work_assignments',
      tableName: 'work_assignments',
      filtering: {
        hiddenColumns: [{ name: 'project_id', filter: this.project.id || 0, filterMode: 'equals' }]
      },
      rowDataTransformer: (rows) => this.transformRows(rows)
    });
  }

  /**
   * Transform the table rows
   *
   * @param workAssignments: any[]
   *
   * @returns any[]
   */
  private transformRows(workAssignments: any[]): any[] {
    for (const workAssignment of workAssignments) {
      workAssignment.pickupAddressName = workAssignment.address_work_assignments
        .filter(item => item.type === 'pickup')
        .map(item => (item.address ? item.address.getDisplayName() : ''))
        .join('<br/>');

      workAssignment.deliverAddressName = workAssignment.address_work_assignments
        .filter(item => item.type === 'delivery')
        .map(item => (item.address ? item.address.getDisplayName() : ''))
        .join('<br/>');

      workAssignment.send_work_assignment ? (workAssignment.sendWorkAssignment = 'Ja') : (workAssignment.sendWorkAssignment = 'Nee');

      workAssignment.date = workAssignment.displayDate(workAssignment.assignmentDate);
    }

    workAssignments.sort((a: any, b: any) => {
      /** Sort the rows on date order, newest first */
      return a.assignmentDate - b.assignmentDate;
    });

    return workAssignments;
  }
}
