import { Injectable } from '@angular/core';
import { AuthService } from '@blueprint/auth/auth.service';

import * as jwtDecode from 'jwt-decode';

@Injectable()
export class UserService {
  constructor(private auth: AuthService) {}

  /**
   * Checks if the current user has the given role
   *
   * @param roleCode Role code
   */
  public hasRole(roleCode: string): boolean {
    const jwt = this.getTokenAsObject();

    return jwt.role === roleCode;
  }

  /**
   * Returns the location id of the user who is logged in
   */
  public hasLocation(): number {
    const jwt = this.getTokenAsObject();

    return jwt.location_id;
  }

  /**
   * Checks if the current user had administrator rights
   */
  public isAdministrator(): boolean {
    const jwt = this.getTokenAsObject();

    return jwt.role === 'superuser' || jwt.role === 'administrator' || this.getUserId() === 1;
  }

  /**
   * Checks if the current user is a location manager
   */
  public isLocationManager(): boolean {
    return this.getTokenAsObject().is_location_manager;
  }

  /**
   * Returns the user id of the current user
   */
  public getUserId(): number {
    return this.getTokenAsObject().sub;
  }

  /**
   * Return the role id of the current user
   */
  public getUserRoleId(): number {
    return +this.getTokenAsObject().role_id;
  }

  /**
   * Return the role code of the current user
   */
  public getUserRoleCode(): string {
    return this.getTokenAsObject().role;
  }

  public userAppendsToTopMover(): boolean {
    return !!this.getTokenAsObject().is_topmover;
  }

  public getLocationId(): number {
    return +this.getTokenAsObject().location_id;
  }

  /**
   * Decodes and returns the current JWT token string as an object
   */
  private getTokenAsObject(): any {
    return jwtDecode(this.auth.getToken());
  }
}
