import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/primeng';
import { OfflineTableOptions, OfflineTableComponent } from '@shared/controls/table/offline-table.component';
import { DataService } from '@shared/services/data.service';
import { ProjectService } from '@shared/services/project.service';
import { Project } from '@domain/models/project.model';
import { Address } from '@domain/models/address.model';
import { takeUntil } from '@node_modules/rxjs/operators';
import { Subject } from '@node_modules/rxjs';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';

@Component({
  selector: 'app-inventory-address-list',
  templateUrl: 'address-list.component.html',
  styleUrls: ['./address-list.component.scss']
})
export class InventoryAddressListComponent implements OnInit, OnDestroy {
  @ViewChild(OfflineTableComponent) table: OfflineTableComponent;

  public tableOptions: OfflineTableOptions;
  public project = new Project({});
  public addresses: Address[];


  private destroy$: Subject<void> = new Subject<void>();

  public constructor(private api: ApiServiceWithLoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private dataService: DataService,
    private projectService: ProjectService) {
  }

  public ngOnInit(): void {
    this.project = this.projectService.getProject();
    this.setTableOptions();

    this.getAddresses();

    // In case project was loaded
    this.projectService.projectLoaded$
        .pipe(takeUntil(this.destroy$))
        .subscribe(project => {
      this.project = project;
      this.setTableOptions();
      this.projectService.setCurrentClient(this.project.client);
    });

    // In case address was added (from popup)
    this.projectService.addressAdded$.subscribe(contact => {
      this.table.onChangeTable();
      this.getAddresses();
    });
  }

  public cloneAddress(row: Address): void {
    delete row.id;
    row.index = this.table.rows.length + 1;

    this.projectService.saveAddress(row);
  }

  public onRowClick(data: any): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/address(popup:admin/project/address/' + data.id + ')');
  }

  public openSortModal(): void {
      this.router.navigateByUrl('/admin/project/' + this.project.id + '/address(popup:admin/project/address-sort)');
  }

  public onActionClick(data: any): void {
    this[data.action](data.row);
  }

  public onAddClick(): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/address(popup:admin/project/address/add)');
  }

  public onDeleteClick(): void {
    this.confirmationService.confirm({
      message: 'Wilt u de geselecteerde adressen verwijderen?',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      accept: () => {
        this.table.selectedRows.forEach(row => {
          this.projectService.deleteAddress(row.id);
        });
        this.table.onChangeTable();
      }
    });
  }

  private async getAddresses(): Promise<void> {
    this.addresses = await Address.query.where('project_id').equals(this.project.id).toArray();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setTableOptions(): void {
    this.tableOptions = new OfflineTableOptions({
      paging: false,
      search: false,
      columns: [
        { title: 'Volgorde', name: 'index' },
        { title: 'Type', name: 'type' },
        { title: 'Adres', name: 'address' }
      ],
      actions: [
        { title: 'Adres dupliceren', action: 'cloneAddress', icon: 'fa-clone' }
      ],
      noResultsMessage: 'Er zijn nog geen adressen aangemaakt',
      withDelete: true,
      url: '/address',
      tableName: 'addresses',
      filtering: { hiddenColumns: [{ name: 'project_id', filter: this.project.id || 0, filterMode: 'equals' }] },
      rowDataTransformer: (rows) => {
        for (const row of rows) {
          row.address = `${row.street} ${row.housenumber}${row.housenumber_add || ''}, ${row.zipcode} ${row.city}, ${row.country}`;

          if (!row.index) {
            row.index = 'n.v.t.';
          }
        }

        return rows.sort((a, b) => a.index > b.index ? 1 : -1 );
      },
    });
  }

  /**
   * Save Address index
   *
   * @param row: Address
   */
  private saveAddressIndex(row: Address): void {
    this.projectService.saveAddressIndex(row);
  }
}
