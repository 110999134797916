import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Material } from '@domain/models/material.model';
import { ProjectMaterial } from '@domain/models/project-material.model';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';

@Component({
  selector: 'app-material',
  templateUrl: './material.component.html',
  styleUrls: ['./material.component.scss']
})
export class MaterialComponent implements OnInit, OnDestroy {
  public project = new Project({});
  public materials: Material[];
  public projectMaterials: ProjectMaterial[];

  private subscriptionProjectLoaded: Subscription;

  constructor(private projectService: ProjectService) {
    this.materials = [];
    this.projectMaterials = [];
  }

  public async ngOnInit(): Promise <void> {
    this.project = this.projectService.getProject();

    // Load on project
    this.subscriptionProjectLoaded = this.projectService.projectLoaded$.subscribe(
      async (project) => {
        this.project = project;

        await this.getMaterials();
      }
    );

    await this.getMaterials();
  }

  public async getMaterials(): Promise<void> {
    this.projectMaterials = await ProjectMaterial.query
      .where('project_id')
      .equals(this.project.id)
      .toArray();

    const result = await Material.query.toArray();

    // Sort the materials alphabetically
    this.materials = result.sort((a: any, b: any) => {
      const textA = a.name.toUpperCase();
      const textB = b.name.toUpperCase();

      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
  }

  public async ngOnDestroy(): Promise<void> {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }

    await this.projectService.saveMaterials(this.projectMaterials);
    await this.projectService.setProjectUpdated();
    await this.projectService.saveProject();
  }

  public getAmount(material: Material): number {
    const projectMaterial = this.getMaterialItem(material);

    return projectMaterial ? projectMaterial.amount : 0;
  }

  public async changeAmount(amount: number, material: Material): Promise<void> {
    if (!amount) {
      amount = 0;
    }

    const oldAmount = this.getAmount(material);
    if (oldAmount < amount) {
      await this.addAmount(material, (amount - oldAmount));
    } else if (oldAmount > amount) {
      await this.removeAmount(material, (oldAmount - amount));
    }
  }

  public async addAmount(material: Material, load: number): Promise<void> {
    const projectMaterial = this.getMaterialItem(material);
    if (projectMaterial) {
      projectMaterial.amount += load;

      await this.projectService.updateMaterial(projectMaterial);
    } else {
      const newProjectMaterial = new ProjectMaterial({
        project_id: this.project.id,
        material_id: material.id,
        amount: load,
        material: material
      });

      this.projectMaterials.push(newProjectMaterial);

      await this.projectService.addMaterial(newProjectMaterial);
    }
  }

  public async removeAmount(material: Material, load: number): Promise<void> {
    const item = this.getMaterialItem(material);
    if (item && item.amount > 0) {
      item.amount -= load;

      await this.projectService.updateMaterial(item);
    }
  }

  private getMaterialItem(material: Material): ProjectMaterial {
    return this.projectMaterials.find((projectMaterial: ProjectMaterial) => {
      return projectMaterial.material_id === material.id;
    });
  }
}
