import { DomainModel } from '@domain/domain.model';
import { AddressTemplate } from '@domain/models/address-template.model';

export class Location extends DomainModel {
  // Configuration
  public entity = 'location';
  public table = 'locations';
  public schema = '++id, remote_id, name';
  public sync = true;

  // Fields
  public id: number;
  public name: string;
  public type: string;

  public street?: string;
  public housenumber?: string;
  public housenumber_suffix?: string;
  public zipcode?: string;
  public city?: string;
  public country?: string;
  public email?: string;
  public address?: AddressTemplate;

  // Constructor
  constructor(attributes) {
    super(attributes);
  }

  /**
   * Return a display friendly name
   */
  public getDisplayName(): string {
    return `${this.name || ''} ${this.street || ''} ${this.housenumber || ''}${this.housenumber_suffix || ''},
        ${ this.zipcode || ''}, ${this.city || ''}`;
  }

  public getData(): any {
    return {
      id: this.id,
      name: this.name,
      street: this.street || '',
      housenumber: this.housenumber || '',
      housenumber_add: this.housenumber_suffix || '',
      zipcode: this.zipcode || '',
      city: this.city || '',
      country: this.country || '',
      email: this.email || '',
      address: this.address || null
    };
  }
}
