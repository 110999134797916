import { DomainModel } from '@domain/domain.model';

export class Specialty extends DomainModel {

  // Configuration
  public entity = 'specialty';
  public table = 'specialties';
  public schema = '++id, remote_id';
  public sync = true;

  // Fields
  public id: string;
  public name: string;
  public type?: string;
  public measure?: string;
  public cost_rate: number;
  public volume?: number;
  public has_hours: boolean;
  public has_remarks: boolean;
  public has_date: boolean;
  public includes_vat: boolean;
  public default_description?: string;

  // Constructor
  constructor(attributes) {
    super(attributes);
  }
}
