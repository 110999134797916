import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as toastr from 'toastr';
import { ConfirmationService } from 'primeng/primeng';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from '@shared/services/data.service';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ProjectService } from '@shared/services/project.service';
import { Organisation } from '@domain/models/organisation.model';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';

@Component({
  selector: 'app-organisations-detail',
  templateUrl: './organisations-detail.component.html',
  styleUrls: ['./organisations-detail.component.scss']
})
export class ManageOrganisationsDetailComponent implements OnInit, OnDestroy {
  public organisation: Organisation = new Organisation({}).getData();
  public isAdd = false;
  public loading = false;
  public showErrors = false;
  public form: FormGroup = new FormGroup({});
  public mode = { isAdd: true };
  public paramId: string = null;

  private destroy$: Subject<void> = new Subject<void>();

  constructor (private formBuilder: FormBuilder,
               private dataService: DataService,
               private api: ApiServiceWithLoaderService,
               private router: Router,
               private sanitizer: DomSanitizer,
               private projectService: ProjectService,
               private synchronisationService: SynchronisationService,
               private confirmationService: ConfirmationService,
               private route: ActivatedRoute) {
    this.isAdd = false;
    this.loading = false;
    this.showErrors = false;
  }

  public ngOnInit(): void {
    this.loading = true;
    this.initForm();

    // Get id of url to edit by route params
    this.route.params
        .pipe(takeUntil(this.destroy$))
        .subscribe(({ id }) => {
      this.paramId = id;
      this.isAdd = !this.paramId || (this.paramId === 'add');

      if (!this.isAdd) {
        this.api.get('/organisation/' + this.paramId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((result: any) => {
          if (result && result.data) {
            this.organisation = result.data;
          }

          this.initForm();
          this.loading = false;
        });
      } else {
        this.loading = false;
        this.initForm();
      }
    });
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      name: [this.organisation.name || '', Validators.required],
    });
  }

  public onSubmit(): void {
    if (this.form.valid) {
      this.organisation = this.form.value;
      this.showErrors = false;
      this.loading = true;

      const organisationData = { ...this.form.value, ...this.form.value };
      const request = this.isAdd ? this.api.post('/organisation', organisationData) : this.api.patch('/organisation/' + this.paramId, organisationData);

      request.subscribe(
        () => {
          toastr.success('Deelnemer succesvol opgeslagen', 'Deelnemers');
          this.projectService.organisationTemplatesUpdated$.next();
        },
        (error: any) => {
          this.loading = false;

          if (error.status === 422 && error.json) {
            toastr.warning('Ongeldige invoer', 'Deelnemers');
          } else {
            toastr.error('Fout bij opslaan deelnemer', 'Deelnemers');
          }
        }
      );

      this.onCancel();
    } else {
      this.showErrors = true;
    }
  }

  public onCancel(): void {
    this.router.navigateByUrl('/admin/manage/organisations');
  }

  public ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
  }
}
