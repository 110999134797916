import { DomainModel } from '@domain/domain.model';
import * as uuid from 'uuid/v4';

export class AddressTemplate extends DomainModel {
    // Configuration
    public entity = 'address_template';
    public table = 'address_templates';
    public schema = 'id, location_id';
    public sync = true;
    public id: string;

    // Fields
    public location_id?: number;
    public street?: string;
    public housenumber?: string;
    public housenumber_suffix?: string;
    public zipcode?: string;
    public city?: string;
    public country?: string;

    // Constructor
    constructor(attributes) {
        super(attributes);

        if (!attributes.id) {
            this.id = uuid();
        }
    }

    /**
     * Return a display friendly name
     */
    public getDisplayName(): string {
        return `${ this.street || '' } ${ this.housenumber || '' }${ this.housenumber_suffix || '' },
        ${ this.zipcode || '' }, ${ this.city || '' }, ${ this.country || '' }`;
    }

    public getData(): any {
        return {
            location_id: this.location_id,
            street: this.street || '',
            housenumber: this.housenumber || '',
            housenumber_add: this.housenumber_suffix || '',
            zipcode: this.zipcode || '',
            city: this.city || '',
            country: this.country || ''
        };
    }
}
