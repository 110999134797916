import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { isString } from 'util';

@Component({
  selector: 'app-inventory-static',
  templateUrl: 'inventory-static.component.html',
  styleUrls: ['../inventory.scss']
})
export class InventoryStaticComponent implements OnInit, OnChanges {
  @Input('amount') amount: number;
  @Input('increment') increment: number;
  @Input('disabled') disabled;

  @Output() amountChange = new EventEmitter();

  constructor() {
  }

  public ngOnInit(): void {
    if (!this.amount || this.amount < 0 || isNaN(this.amount)) {
      this.amount = 0;
    }
    if (!this.increment) {
      this.increment = 1;
    }
  }

  public ngOnChanges(): void {
    if (!this.amount || this.amount < 0 || isNaN(this.amount)) {
      this.amount = 0;
    } else if (isString(this.amount)) {
      // Fix possible string values
      this.amount = parseFloat('' + this.amount);
    }
  }

  public addAmount(): void {
    this.amount += this.increment;
    this.amountChange.emit(this.amount);
  }

  public removeAmount(): void {
    if (this.amount === 0) {
      return;
    }
    this.amount -= this.increment;
    this.amountChange.emit(this.amount);
  }
}
