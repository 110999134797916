import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as toastr from 'toastr';
import * as moment from 'moment';
import { SelectItem } from 'primeng/primeng';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { UserService } from '@shared/services/user.service';
import { DataService } from '@shared/services/data.service';
import { Location } from '@domain/models/location.model';
import { User } from '@domain/models/user.model';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CalendarLocale } from '@domain/models/calendar-locale.model';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['user-detail.component.scss']
})
export class UserDetailComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public user: User;
  public isAdd: boolean;
  public loading: boolean;
  public showErrors: boolean;
  public formIsInitialized: boolean;
  public showLocationFields: boolean;
  public useOrganisations: boolean = false;
  public locations: Location[] = [];
  public roleList: SelectItem[] = [];
  public localeNL: CalendarLocale = new CalendarLocale();
  public topMoversList: SelectItem[] = [];
  public maxDate = new Date();
  public organisationsList: SelectItem[] = [];

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private api: ApiServiceWithLoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private formBuilder: FormBuilder,
    public userService: UserService
  ) {
    this.isAdd = false;
    this.loading = false;
    this.showErrors = false;
    this.formIsInitialized = false;
    this.showLocationFields = false;
  }

  public async ngOnInit(): Promise<void> {
    this.loading = true;

    this.api.get(`/organisation/list`).subscribe((result: any) => {
      this.organisationsList = result.data;
    });

    this.api.get(`/location/topmovers-list`).subscribe((result: any) => {
      this.topMoversList = result.data;
    });

    this.topMoversList = this.dataService.sortDropdownByLabel(this.topMoversList);

    // Get id of address to edit by route params
    this.route.params
      .pipe(
        takeUntil(this.destroy$),
        filter(params => params['id'])
      )
      .subscribe((params: any) => {
        const id = params['id'];
        this.isAdd = id === 'add';

        if (!this.isAdd) {
          this.api.get('/user/' + id).subscribe((user) => {
            this.user = user.data;
            this.initForm();
            this.loading = false;
          });
        } else {
          this.loading = false;
          this.initForm();
        }
      });

    this.roleList = [];
    this.roleList.push({ label: 'Selecteer rol', value: null });
    this.roleList.push({ label: 'Administrator', value: 2 });
    this.roleList.push({ label: 'Aanvrager', value: 3 });
    this.roleList.push({ label: 'Verhuizer', value: 4 });

    this.roleList = this.roleList.filter(role => role.value >= this.userService.getUserRoleId());
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  public initForm() {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control(null),
      organisations: this.formBuilder.control(''),
      first_name: this.formBuilder.control('', Validators.required),
      last_name: this.formBuilder.control('', Validators.required),
      email: this.formBuilder.control('', [Validators.email, Validators.required]),
      id_number: this.formBuilder.control(''),
      date_of_birth: this.formBuilder.control('', Validators.required),
      password: this.formBuilder.control('', Validators.minLength(8)),
      password_confirmation: this.formBuilder.control('', Validators.minLength(8)),
      vog: this.formBuilder.control(false),
      vca: this.formBuilder.control(false),
      sr: this.formBuilder.control(false),
      role_id: this.formBuilder.control(null, Validators.required),
      is_location_manager: this.formBuilder.control(false),
      location_id: this.formBuilder.control(null)
    });

    if (this.isAdd) {
      this.form.controls.password.setValidators([Validators.required, Validators.minLength(8)]);
    } else {
      this.user.date_of_birth = new Date(this.user.date_of_birth as string);
      this.form.patchValue(this.user);
      this.form.get('location_id').patchValue(this.user.location_id);

      if (this.user.organisations && this.user.organisations.length > 0) {
        this.form.get('organisations').patchValue(this.user.organisations.map(organisation => organisation.id));
      }
    }

    this.formIsInitialized = true;
  }

  public onSubmit() {
    if (!this.form.valid) {
      this.showErrors = true;

      return;
    }

    if (this.form.get('role_id').value === 3) {
      this.form.get('location_id').patchValue(null);
      this.form.get('is_location_manager').patchValue(false);
    }

    // Check password match
    if (this.form.value.password !== this.form.value.password_confirmation) {
      this.showErrors = true;
      toastr.warning('Wachtwoorden komen niet overeen', 'Gebruikers');

      return;
    }

    this.showErrors = false;
    this.loading = true;

    const userData = { ...this.form.value };

    // Remove password properties if empty
    if (!userData.password || userData.password.length === 0) {
      delete userData.password;
      delete userData.password_confirmation;
    }

    userData.date_of_birth = `${moment(userData.date_of_birth).format('YYYY-MM-DD')} 00:00:00`;

    const request = this.isAdd ? this.api.post('/user', userData) : this.api.patch('/user/' + userData.id, userData);

    request.subscribe(
      (data: any) => {
        toastr.success('Gebruiker succesvol opgeslagen', 'Gebruikers');
        this.router.navigateByUrl('/').then(() => {
          // HACK Use double navigation to force reload..
          this.router.navigateByUrl('/admin/users');
        });
      },
      (error: any) => {
        if (error.status === 422 && error.json) {
          toastr.warning('Ongeldige invoer', 'Gebruikers');
        } else {
          toastr.error('Fout bij opslaan gebruiker', 'Gebruikers');
        }

        this.loading = false;
      }
    );
  }

  public onCancel() {
    this.router.navigateByUrl('/admin/users');
  }
}
