import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OnlineTableOptions } from '@shared/controls/table/online-table.component';
import { ProjectService } from '@shared/services/project.service';

@Component({
  selector: 'app-organisations-list',
  templateUrl: './organisations-list.component.html',
  styleUrls: ['./organisations-list.component.scss']
})
export class ManageOrganisationsListComponent implements OnInit, OnDestroy {
  public tableOptions: OnlineTableOptions;
  public tableVisible: boolean = true;

  constructor(private router: Router,
              private projectService: ProjectService) {
  }

  public ngOnInit(): void {
    this.tableOptions = new OnlineTableOptions({
      columns: [
        { title: 'Naam', name: 'name', filter: { global: true } },
      ],
      endpoint: '/organisation',
      search: true,
      withDelete: true,
      withFilter: false
    });

    // In case contact is added (from popup)
    this.projectService.organisationTemplatesUpdated$.subscribe(() => {
      this.tableVisible = false;

      setTimeout(() => {
        this.tableVisible = true;
      }, 0);
    });
  }

  public onRowClick(row: any) {
    this.router.navigateByUrl(`admin/manage/organisations(popup:admin/manage/organisations/${row.id})`);
  }

  public addNewOrganisation(): void {
    this.router.navigateByUrl('/admin/manage/organisations(popup:admin/manage/organisations/add)');
  }

  public ngOnDestroy() {
    if (this.projectService.organisationTemplatesUpdated$) {
      this.projectService.organisationTemplatesUpdated$.unsubscribe();
    }
  }
}
