import { Component } from '@angular/core';
import { ConfirmationService } from 'primeng/primeng';
import { Router } from '@angular/router';
import { Store } from '@domain/store';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';

@Component({
  selector: 'app-settings-overview',
  templateUrl: 'settings-overview.component.html'
})
export class SettingsOverviewComponent {

  public constructor(
    private api: ApiServiceWithLoaderService,
    private router: Router,
    private confirmationService: ConfirmationService) {
  }

  public onDatabaseReset() {
    this.confirmationService.confirm({
      message: 'Wilt u de database resetten?<br/>Nieuwe projecten of wijzigingen gaan hierdoor verloren.',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      accept: async () => {
        await Store.getStore().reset();
        window.location.reload();
      }
    });
  }

  public onSyncNow() {
    this.confirmationService.confirm({
      message: 'Weet u zeker dat u nu wilt sychroniseren?',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      accept: async () => {
        await this.router.navigateByUrl('/');
      }
    });
  }
}
