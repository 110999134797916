import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-blank-invoice',
  templateUrl: './blank-invoice.component.html',
  styleUrls: ['./blank-invoice.component.scss']
})
export class BlankInvoiceComponent {
  @Input() type: string;
  @Input() specialties: any[];
  @Input() activities: any[];

  constructor() {}
}
