import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as toastr from 'toastr';
import { ConfirmationService, SelectItem } from 'primeng/primeng';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from '@root/node_modules/rxjs';
import { AddressTemplate } from '@domain/models/address-template.model';
import { DataService } from '@shared/services/data.service';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ValidateAlphaNumeric } from '@shared/validators/alpha-numeric-validator';
import { ValidateDutchZipcode } from '@shared/validators/dutch-zipcode-validator';
import { ValidateNonNegative } from '@shared/validators/non-negative-validator';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';

@Component({
  selector: 'app-addresses-detail',
  templateUrl: './addresses-detail.component.html',
  styleUrls: ['./addresses-detail.component.scss']
})
export class ManageAddressesDetailComponent implements OnInit {
  public address: AddressTemplate = new AddressTemplate({});
  public barrackLocations: SelectItem[];
  public isAdd = false;
  public loading = false;
  public showErrors = false;
  public form: FormGroup;
  public mode = { isAdd: true };

  constructor(
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private api: ApiServiceWithLoaderService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private synchronisationService: SynchronisationService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute
  ) {
    this.form = new FormGroup({});
    this.address = new AddressTemplate({}).getData();
    this.barrackLocations = [];
    this.isAdd = false;
    this.loading = false;
    this.showErrors = false;
  }

  public async ngOnInit(): Promise<void> {
    this.loading = true;
    this.barrackLocations = await this.synchronisationService.getBarrackLocations();
    this.barrackLocations = this.dataService.sortDropdownByLabel(this.barrackLocations);

    this.initForm();

    // Get id of url to edit by route params
    this.route.params.subscribe((params) => {
      const id = params['id'];
      this.isAdd = id === 'add';

      if (!this.isAdd) {
        this.api.get('/address-template/' + id).subscribe((result: any) => {
          this.address = result;
          this.initForm();
          this.loading = false;
        });
      } else {
        this.initForm();
        this.loading = false;
      }
    });
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      location_id: [this.address.location_id || null],
      street: [{ value: this.address.street || '', disabled: true }, [Validators.required, ValidateAlphaNumeric]],
      housenumber: [this.address.housenumber, [Validators.required, ValidateNonNegative]],
      housenumber_suffix: [this.address.housenumber_suffix || ''],
      zipcode: [this.address.zipcode, [Validators.required, ValidateDutchZipcode]],
      city: [{ value: this.address.city || '', disabled: true }, [Validators.required]],
      country: [{ value: this.address.country || '', disabled: true }, [Validators.required, ValidateAlphaNumeric]]
    });
  }

  /**
   * Retrieves auto fill of address
   *
   * @param event: any
   *
   * @returns Promise<void>
   */
  public async onAddressChange(event: any): Promise<void> {
    const zipcode = this.form.value.zipcode;
    const housenumber = this.form.value.housenumber;
    const street = this.form.value.street;
    const city = this.form.value.city;
    const country = this.form.value.country;

    if (!zipcode || !housenumber) {
      return;
    }

    if (street || city || country) {
      return;
    }

    const result = await this.api
      .post('/address/search', {
        zipcode: this.form.value.zipcode,
        housenumber: this.form.value.housenumber,
        city: this.form.value.city
      })
      .toPromise();

    if (!result || !result.city || !result.street || !result.country) {
      this.form.get('street').enable();
      this.form.get('city').enable();
      this.form.get('country').enable();

      return;
    }

    this.form.patchValue({
      street: result.street,
      city: result.city,
      country: result.country
    });
  }

  public onSubmit(): void {
    this.route.params.subscribe((params) => {
      const id = params['id'];

      if (this.form.valid) {
        const addressData = this.form.getRawValue();
        this.showErrors = false;
        this.loading = true;

        const request = this.isAdd ? this.api.post('/address-template', addressData) : this.api.patch('/address-template/' + id, addressData);

        request.subscribe(() => {
          toastr.success('Adres succesvol opgeslagen', 'Adres templates');
          this.router.navigateByUrl('/').then(() => {
            // HACK Use double navigation to force reload..
            this.router.navigateByUrl('/admin/manage/addresses');
          });
        }, (error: any) => {
          if (error.status === 422 && error.json) {
            toastr.warning('Ongeldige invoer', 'Adres templates');
          } else {
            toastr.error('Fout bij opslaan adres', 'Adres templates');
          }

          this.loading = false;
        });

        this.onCancel();
      } else {
        this.showErrors = true;
      }
    });
  }

  public onCancel(): void {
    this.router.navigateByUrl('/admin/manage/addresses');
  }
}
