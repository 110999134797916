import { Component, OnInit, OnDestroy } from '@angular/core';
import { SelectItem } from 'primeng/primeng';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { ProjectSpecialty } from '@domain/models/project-specialty.model';
import { ProjectActivity } from '@domain/models/project-activity.model';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { Address } from '@domain/models/address.model';
import { CalendarLocale } from '@domain/models/calendar-locale.model';
import { DataService } from '@shared/services/data.service';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'app-inventory-options-detail',
  templateUrl: 'options-detail.component.html',
  styleUrls: ['./options-detail.component.scss']
})
export class InventoryOptionsDetailComponent implements OnInit, OnDestroy {
  public form;
  public project = new Project({});
  public activities: ProjectActivity[] = [];
  public specialties: ProjectSpecialty[] = [];
  public filteredSpecialties: {specialties: ProjectSpecialty[], label: string, code: string}[] = [
    { code: "uwv", label: 'In te vullen door UWV-medewerkers', specialties: [] },
    { code: "topmovers", label: 'In te vullen door Top Movers medewerkers', specialties: [] },
  ];
  public disabled = false;
  public addresses: Address[] = [];
  public addressOptions: SelectItem[] = [];
  public displayActivityArray: any;

  public value: Date;
  public numberTrucks: SelectItem[];
  public numberMovers: SelectItem[];

  public localeNL: CalendarLocale = new CalendarLocale();

  private subscriptionProjectLoaded: Subscription;

  constructor(
    private projectService: ProjectService,
    private dataService: DataService,
    private formBuilder: FormBuilder,
    public user: UserService) {
    this.numberMovers = [];
    this.numberMovers.push({ label: '1', value: '1' });
    this.numberMovers.push({ label: '2', value: '2' });
    this.numberMovers.push({ label: '3', value: '3' });
    this.numberMovers.push({ label: '4', value: '4' });
    this.numberMovers.push({ label: '5', value: '5' });
    this.numberMovers.push({ label: '8', value: '8' });
    this.numberMovers.push({ label: '10', value: '10' });
    this.numberMovers.push({ label: '15', value: '15' });
    this.numberMovers.push({ label: '20', value: '20' });
    this.numberMovers.push({ label: '25', value: '25' });
    this.numberMovers.push({ label: '50', value: '50' });

    this.numberTrucks = this.numberMovers;
    this.displayActivityArray = [];
  }

  async ngOnInit() {
    this.project = this.projectService.getProject();
    this.initProjectData();
    this.updateActivities();
    this.initAddresses();

    // Reload when project changes
    this.subscriptionProjectLoaded = this.projectService.projectLoaded$.subscribe(async project => {
      this.project = project;
      this.initProjectData();
      this.projectService.setCurrentClient(this.project.client);
      this.updateActivities();
      this.initForm();
      this.initAddresses();
    });

    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      own_description_activities: this.formBuilder.control(
        { value: this.project.own_description_activities || '', disabled: this.disabled }),
      client_description_activities: this.formBuilder.control(
        { value: this.project.client_description_activities || '', disabled: this.disabled }),
    });
  }

  async ngOnDestroy() {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }

    if (this.projectService.project) {
      this.projectService.project.own_description_activities = this.form.value.own_description_activities;
      this.projectService.project.client_description_activities = this.form.value.client_description_activities;

      await this.projectService.saveActivities(this.activities);
      await this.projectService.saveSpecialties(this.specialties);
      await this.projectService.setProjectUpdated();
      await this.projectService.saveProject();
    }
  }

  public setApplicable(activities: any): void {
    activities.forEach((activityItem: ProjectActivity) => {
      activityItem.applicable = activities.applicable;
    });
  }

  public onRemoveActivityClick(activity: ProjectActivity): void {
    const index = this.project.activities.indexOf(activity);
    this.project.activities.splice(index, 1);

    // Also delete from displayArray
    const indexDisplayArray = this.displayActivityArray[activity.activity_id].indexOf(activity);

    this.displayActivityArray[activity.activity_id].splice(indexDisplayArray, 1);
    // Delete from database
    this.projectService.deleteProjectActivity(activity.id);
    this.updateActivities();
    this.projectService.setProjectUpdated();
  }

  async onAddActivityClick(type: number) {
    const newActivity = new ProjectActivity({
      project_id: this.project.id, activity_id: type, applicable: true
    });
    await newActivity.init();
    this.project.activities.push(newActivity);
    this.updateActivities();
    await this.projectService.setProjectUpdated();
  }

  public optionSwitchToggled(index: number): void {
    if (this.project.specialties[index].applicable === false) {
      this.project.specialties[index].details = null;
      this.project.specialties[index].hours_estimate = null;
    }
  }

  private updateActivities(): void {
    const activityIdArray = [];
    // Slice original array to remove reference
    const array = this.project.activities.slice();

    // Read all activity ID's and put them in list to check later
    for (const element of array) {
      if (!activityIdArray.includes(element.activity_id)) {
        activityIdArray.push({ id: element.activity_id, name: element.activity.name, applicable: element.applicable || false });
      }
    }

    // Check for all activity ID's in list, put every activity in array to corresponding activity ID
    for (const key of activityIdArray) {
      this.displayActivityArray[key.id] = array.filter((activity) => activity.activity_id === key.id);
      this.displayActivityArray[key.id].name = key.name;
      this.displayActivityArray[key.id].applicable = false;

      for (const item of this.displayActivityArray[key.id]) {
        if (item.applicable) {
          this.displayActivityArray[key.id].applicable = true;
        }
      }
    }
  }

  private initAddresses(): void {
    this.addressOptions = [];
    for (const address of this.addresses) {
      this.addressOptions.push({ label: address.getDisplayName(), value: address.id });
    }

    this.addressOptions = this.dataService.sortDropdownByLabel(this.addressOptions);
  }

  private initProjectData(): void {
    this.addresses = this.project.addresses;
    this.specialties = this.project.specialties;

    this.filteredSpecialties = [
      { code: 'uwv', label: 'In te vullen door UWV-medewerkers', specialties: this.specialties.filter((value: ProjectSpecialty) => {
      return value.specialty.type === 'uwv';
    })},
      { code: 'topmovers', label: 'In te vullen door Top Movers medewerkers', specialties: this.specialties.filter((value: ProjectSpecialty) => {
      return value.specialty.type === 'client';
    })}];

    this.activities = this.project.activities;
    this.disabled = this.project.status === 'booked';
  }
}

