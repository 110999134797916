import { Component, Input, Output, EventEmitter } from '@angular/core';
import { WorkAssignmentItem } from '@domain/models/work-assignment-item.model';
import { SelectItem } from '@node_modules/primeng/components/common/selectitem';

@Component({
  selector: 'app-inventory-work-assignment-item',
  templateUrl: 'work-assignment-item.component.html',
  styleUrls: ['./work-assignment-item.component.scss']
})
export class InventoryWorkAssignmentItemComponent {
  @Input() dataList: SelectItem[];
  @Input() item: WorkAssignmentItem;
  @Input() placeholderName: string;

  @Output() onDeleteItemClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSelectorChange: EventEmitter<any> = new EventEmitter<any>();

  public times = [];

  constructor() {
    // Fill times items
    for (let i = 0; i < 24; i++) {
      this.times.push({ label: i + ':00', value: i + ':00' });
      this.times.push({ label: i + ':15', value: i + ':15' });
      this.times.push({ label: i + ':30', value: i + ':30' });
      this.times.push({ label: i + ':45', value: i + ':45' });
    }
  }

  /**
   * On selector change
   *
   * @param event: any
   *
   * @returns void
   */
  public onSelectChange(event: any): void {
    this.onSelectorChange.emit({ value: event.value, item: this.item });
  }

  public onDeleteClick() {
    this.onDeleteItemClick.emit(this.item);
  }
}
