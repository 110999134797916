import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Picture } from '@domain/models/picture.model';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import * as jwt_decode from 'jwt-decode';
import { Subscription } from '@node_modules/rxjs/Subscription';

@Component({
  selector: 'app-pictures',
  templateUrl: './pictures.component.html',
  styleUrls: ['./pictures.component.scss']
})
export class InventoryPicturesComponent implements OnInit, OnDestroy {
  public project: Project;
  public pictures: Picture[];
  public disabled = false;
  private picturesChanged: Subscription;
  private subscriptionProjectLoaded: Subscription;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private projectService: ProjectService,
              private router: Router,
              private confirmationService: ConfirmationService) {

    this.project = new Project({});
    this.pictures = [];

    this.subscriptionProjectLoaded = this.projectService.projectLoaded$.pipe(takeUntil(this.destroy$)).subscribe(async (project: Project) => {
      this.project = project;
    });
  }

  public async ngOnInit(): Promise<void> {
    this.project = this.projectService.getProject();

    if (this.project.id) {
      await this.projectService.loadProject(this.project.id);
    }

    this.pictures = this.project.pictures

    // In case contact was added (from popup)
    this.picturesChanged = this.projectService.picturesChanged$.pipe(takeUntil(this.destroy$)).subscribe(contact => {
      this.project.loadPictures().then((value: void) => {
        this.pictures = this.project.pictures;
      });

    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Prints the file
   *
   * @param fileId: string
   * @returns Promise<void>
   */
  public async printFile(fileId: string): Promise<void> {
    const picture = this.pictures.find((pic: Picture) => pic.id === fileId);
    const head = document.getElementsByTagName('head')[0];
    const popupWin = window.open('', '_blank', 'width=1024,height=768');
    popupWin.document.open();
    popupWin.document.write('<html>' +
        '<head>' + head.innerHTML + '</head>' +
        '<body>' +
        '<h5>Project</h5>' + this.project.reference_nr +
        '<h5>Klant</h5>' + this.project.client.name +
        '<h5>Datum</h5>' + picture.timestamp +
        '<h5>Beschrijving</h5>' + picture.description + '<br /><br />' +
        '<img src="' + picture.data + '"><br /><br />' +
        '</body>' +
        '</html>');

    popupWin.print();
    popupWin.document.close();
  }

  /**
   * Gives confirm toast
   *
   * @param fileId: string
   * @returns void
   */
  public deleteFileWithConfirm(fileId: string): void {
    if (!this.disabled) {
      this.confirmationService.confirm({
        message: 'Weet u zeker dat u dit bestand wilt verwijderen?',
        header: 'Bevestiging',
        icon: 'fa fa-question-circle',
        accept: async _ => {
          await this.delete(fileId);
        }
      });
    }
  }

  /**
   * Add another image
   *
   * @returns void
   */
  public addImage(): void {
    if (!this.disabled) {
      this.router.navigateByUrl('/admin/project/' + this.project.id + '/pictures(popup:admin/project/pictures/add)');
    }
  }

  /**
   * Deletes the picture
   *
   * @param fileId: string
   * @returns Promise<void>
   */
  private async delete(fileId: string): Promise<void> {
    if (!this.disabled) {
      await this.projectService.deletePicture(fileId);
      this.projectService.setProjectUpdated();
      await this.projectService.saveProject();
    }
  }
}
