import { Injectable } from '@angular/core';
import { Observable } from '@root/node_modules/rxjs';
import { QuotationSignApiService } from './api/quotation-sign-api.service';

@Injectable()
export class QuotationSignService {
  constructor(private quotationApi: QuotationSignApiService) { }

  public async validateQuotation(data: any): Promise<boolean> {
    const result = await this.quotationApi.postValidateQuotation(data).toPromise();

    return true;
  }

  public getQuotation(quotationSignId: string, projectId: string): Observable<any> {
    return this.quotationApi.getQuotationByProject(quotationSignId, projectId);
  }

  public signQuotation(data: { quotation_sign_id: string, quotation: any }): Observable<any> {
    return this.quotationApi.create(data);
  }
}
