import { Component, OnInit, ViewChild } from '@angular/core';
import * as toastr from 'toastr';
import * as _ from 'lodash';
import { ConfirmationService } from 'primeng/primeng';
import { OfflineTableComponent, OfflineTableOptions } from '@shared/controls/table/offline-table.component';
import { OnlineTableComponent, OnlineTableOptions } from '@shared/controls/table/online-table.component';
import { Router } from '@angular/router';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ProjectService } from '@shared/services/project.service';
import { Project } from '@domain/models/project.model';
import { UserService } from '@shared/services/user.service';
import { SelectItem } from 'primeng/api';
import { Subject } from '@node_modules/rxjs';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { OnlineStatusService } from '@shared/services/online-status.service';
import * as moment from '@node_modules/moment';

@Component({
  selector: 'app-project-overview',
  templateUrl: 'project-overview.component.html',
  styleUrls: ['project-overview.component.scss']
})
export class ProjectOverviewComponent implements OnInit {
  @ViewChild('projectSearchTable')
  projectSearchTable: OnlineTableComponent;

  @ViewChild('projectOpenTable')
  projectOpenTable: OfflineTableComponent;

  public projectOpenTableOptions: OfflineTableOptions;
  public projectSearchTableOptions: OnlineTableOptions;

  public displayModal: boolean;
  public specialties: any[];
  public activities: any[];
  public numberOfSpecialties: number;
  public numberOfActivities: number;
  public organisationsList: SelectItem[] = [];
  public onlineMode = true;
  public $searchTableSubject = new Subject<void>();
  public statusFilterOptions: SelectItem[] = [];

  public searchTableReady: boolean = false;

  public constructor(
      private api: ApiServiceWithLoaderService,
      private projectService: ProjectService,
      private synchronisationService: SynchronisationService,
      private confirmationService: ConfirmationService,
      private router: Router,
      private userService: UserService,
      private onlineStatusService: OnlineStatusService,
  ) {
    this.onlineMode = navigator.onLine;
    this.displayModal = false;
    this.specialties = [];
    this.activities = [];
    this.numberOfSpecialties = 0;
    this.numberOfActivities = 0;
    this.statusFilterOptions = [
      { label: 'Alle excl. gearchiveerd', value: 'new,pending,canceled,booked,digi-known,viewing_planned' },
      { label: 'Alle incl. gearchiveerd', value: 'new,pending,canceled,booked,digi-known,closed,viewing_planned' },
      { label: 'Nieuw', value: 'new' },
      { label: 'Voorcalculatie', value: 'pending' },
      { label: 'Geannuleerd', value: 'canceled' },
      { label: 'Nacalculatie', value: 'booked' },
      { label: 'PS Nummer', value: 'digi-known' },
      { label: 'Shouw gepland', value: 'viewing_planned' },
      { label: 'Gearchiveerd', value: 'closed' },
    ];
    this.organisationsList = [{ label: 'Alle', value: '' }];
    this.api.get('/organisation/list').subscribe((result) => {
      if (result && result.data && result.data.length > 0) {
        this.organisationsList = [...this.organisationsList, ...result.data];
      }
    });
  }

  public async ngOnInit(): Promise<void> {
    this.api.increaseLoaderValueByOne();

    this.$searchTableSubject.pipe().subscribe(() => {
      this.loadSearchProjectTable();
    });

    this.loadOpenProjectTable();
    // await this.synchronisationService.updateEditingByFlags();

    // Always synchronise when opening project overview
    await this.synchronisationService.synchronise();

    this.api.decreaseLoaderValueByOne();
  }

  public loadOpenProjectTable() {
    this.searchTableReady = false;

    this.projectOpenTableOptions = new OfflineTableOptions({
      columns: [
        { title: 'Referentie', name: 'reference_nr_display' },
        { title: 'PS Nummer', name: 'digi_purchase_number' },
        { title: 'A Nummer', name: 'a_number' },
        { title: 'Status', name: 'status' },
        { title: 'Projectomschrijving', name: 'clientName' },
      ],
      withDelete: true,
      url: '/projects',
      tableName: 'projects',
      search: false,
      rowDataTransformer: (rows: any) => {
        for (const row of rows) {
          row.reference_nr_display = row.reference_nr ? row.reference_nr : '*Wordt gegenereerd na synchronisatie*';
          row.clientName = (row.client && row.client.name) ? row.client.name : '-';
          row.status = Project.getStatusName(row.status);
        }

        this.$searchTableSubject.next();

        return rows;
      }
    });
  }

  public loadSearchProjectTable() {
    this.projectSearchTableOptions = new OnlineTableOptions({
      columns: [
        {
          title: 'Referentie',
          name: 'reference_nr',
          filter: { global: true }
        },
        {
          title: 'PS Nummer',
          name: 'digi_purchase_number',
          filter: { global: true }
        },
        {
          title: 'A Nummer',
          name: 'a_number',
          filter: { global: true }
        },
        {
          title: 'Status',
          name: 'status',
          filter: {
            type: 'select',
            options: this.statusFilterOptions,
            global: true
          }
        },
        {
          title: 'Responstijd',
          name: 'responseTime',
        },
        {
          title: 'Projectomschrijving',
          name: 'client.name',
          filter: { global: true, field: 'clients.name' }
        },
      ],
      endpoint: '/projects',
      search: true,
      rowDataTransformer: this.transformRows.bind(this),
      withDelete: this.userService.isAdministrator(),
      defaultFilters: { 'status': { value: this.statusFilterOptions[0].value } }
    });

    this.searchTableReady = true;
  }

  public async onOpenProjectTableRowClick(data: any) {
    this.router.navigateByUrl('/admin/project/' + data.id + '/client');
  }

  public async onSearchProjectTableRowClick(data: any) {
    // Load project data from server and then navigate to detail view
    this.projectSearchTable.loading = true;
    this.synchronisationService.loadSingleProjectData(data.id).then(() => {
      this.projectSearchTable.loading = false;
      this.router.navigateByUrl('/admin/project/' + data.id + '/client');
    });
  }

  public async addNewProject() {
    const project = await this.projectService.newProject();
    this.router.navigateByUrl(`/admin/project/${project.id}/client`);
  }

  public async closeOpenProjects(): Promise<void> {
    if (!this.projectOpenTable.selectedRows || this.projectOpenTable.selectedRows.length === 0) {
      toastr.warning('Geen projecten geselecteerd');

      return;
    }

    const notSyncableProjectReferences = '';
    // for (let i = 0; i < this.projectOpenTable.selectedRows.length; i++) {
    //   const project: Project = this.projectOpenTable.selectedRows[i];
    //
    //   if (project.editing_by && +project.editing_by !== +jwt_decode(localStorage.getItem('token')).sub) {
    //     notSyncableProjectReferences += (project.reference_nr + '\n');
    //   }
    // }

    this.confirmationService.confirm({
      message: notSyncableProjectReferences.length > 0 ?
          'Let op! De volgende projecten worden niet gesynchroniseerd bij het sluiten:\n ' + notSyncableProjectReferences :
          'Let op! De projecten worden gesynchroniseerd en gesloten.',
      header: 'Bevestiging',
      acceptLabel: 'Akkoord',
      rejectLabel: 'Annuleren',
      icon: 'fa fa-question-circle',
      accept: async () => {
        this.api.increaseLoaderValueByOne();

        this.onlineStatusService.checkStatus().subscribe(async isOnline => {
          if (isOnline === true) {
            this.projectOpenTable.loading = true;

            const closeProjectIds: number[] = this.projectOpenTable.selectedRows.map(row => row.id);
            const result = await this.synchronisationService.syncToBackend(true, closeProjectIds);

            if (!result) {
              toastr.error('Projecten synchroniseren sluiten mislukt. Controleer uw verbinding.');
              return;
            }

            // Retrieve open projects except selected rows
            for (const row of this.projectOpenTable.rows) {
              if (closeProjectIds.indexOf(row.id) === -1) {
                await this.synchronisationService.loadSingleProjectData(row.id);
              }
            }

            this.loadOpenProjectTable();
            // await this.synchronisationService.updateEditingByFlags();
            this.projectOpenTable.loading = false;


            await this.synchronisationService.synchronise();
          } else {
            toastr.warning('Mogelijk geen stabiele internetverbinding. Projecten niet gesynchroniseerd!');
          }

          this.api.decreaseLoaderValueByOne();
        });
      }
    });
  }

  /**
   * Toggle display boolean for showing the print modal
   *
   * @param event
   */
  public toggleModalDisplay(event: any): void {
    this.displayModal = true;
  }

  /**
   * Print the blank invoice based on project type
   */
  public printBlankInvoice(): void {
    this.displayModal = false;

    const divToPrint = document.getElementById('blank_invoice_pdf');
    const head = document.getElementsByTagName('head')[0];
    const popupWin = window.open('', '_blank', 'width=1024,height=768');
    popupWin.document.open();
    popupWin.document.write('<html>' +
        ' <head>' + head.innerHTML + '</head>' +
        ' <body style="background:#fff;">' +
        '  <img src="/assets/images/logo.png" style="height: 100px; display: flex; margin: 25px auto 0 auto;"><br /><br />' +
        '   ' + divToPrint.innerHTML + '' +
        ' </body>' +
        '</html>');

    const elements = popupWin.document.getElementsByTagName('h5');
    for (let i = 0; i < elements.length; i++) {
      elements[i].setAttribute('style', 'font-size: 18px; font-weight: bold;');
    }

    /** Timeout to make sure the window is loaded */
    setTimeout(() => popupWin.print(), 300);
    popupWin.document.close();
  }

  public setSpecialtiesLength(): void {
    if (!(Number(this.numberOfSpecialties) && Number(this.numberOfSpecialties < 15))) {
      return;
    }

    const length = Number(this.numberOfSpecialties);

    this.specialties = [];
    for (let i = 0; i < length; i++) {
      this.specialties.push(i);
    }
  }

  public setActivitiesLength(): void {
    if (!(Number(this.numberOfActivities) && Number(this.numberOfActivities < 15))) {
      return;
    }

    const length = Number(this.numberOfActivities);

    this.activities = [];
    for (let i = 0; i < length; i++) {
      this.activities.push(i);
    }
  }

  private transformRows(rows: Array<any>): any {
    // Determine open projects
    const openProjectIDs = this.projectOpenTable.rows ? this.projectOpenTable.rows.map(project => project.id) : [];
    const selectedProjectIds = this.projectOpenTable.selectedRows ? this.projectOpenTable.selectedRows.map(project => project.id) : [];
    const hideProjectIds = openProjectIDs.filter(projectID => !selectedProjectIds.includes(projectID));
    const existingRows = rows.filter(row => !hideProjectIds.includes(row.id));

    for (const row of existingRows) {
      row.status = Project.getStatusName(row.status);
      row.organisations = _.cloneDeep(row.organisation);
      row.responseTime = this.getDateDifference(row.created_at, row.responded_at);
    }

    return existingRows;
  }

  private getDateDifference(date1: string, date2: string): string {
    const created = moment(date1);
    const responseDate = moment(date2);

    if (date1 && date2) {
      const duration = moment.duration(responseDate.diff(created));

      return `${duration.days()} dag(en) ${duration.hours()} uur`;
    }

    return null;
  }
}
