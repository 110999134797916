import { DomainModel } from '@domain/domain.model';
import { Specialty } from '@domain/models/specialty.model';

import * as uuid from 'uuid/v4';

export class WorkAssignmentItem extends DomainModel {

  // Configuration
  public entity = 'work-assignment-item';
  public table = 'work_assignment_items';
  public schema = 'id, work_assignment_id';
  public sync = true;
  public id: string;

  // Fields
  public work_assignment_id: string;
  public details: string;
  public start: string;
  public end: string;
  public type: string;
  public executor_id?: string;
  public car_id?: string;

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public async init() {}

  public getData() {
    return {
      id: this.id,
      work_assignment_id: this.work_assignment_id,
      details: this.details,
      start: this.start,
      end: this.end,
      type: this.type,
      executor_id: this.executor_id,
      car_id: this.car_id
    };
  }

  /**
   * Set default values
   */
  public setDefaults() {
    this.start = '8:00';
    this.end = '17:30';
  }
}
