import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-inventory-item',
  templateUrl: 'inventory-item.component.html',
  styleUrls: ['../inventory.scss']
})
export class InventoryItemComponent implements OnInit {
  @Input('inventoryItem') inventoryItem;
  @Input('disabled') disabled;

  @Output('inventoryItemChanged') inventoryItemChanged = new EventEmitter;
  @Output('inventoryItemDeleted') inventoryItemDeleted = new EventEmitter;

  constructor() {
  }

  public ngOnInit() {
    if (!this.inventoryItem.amount || this.inventoryItem.amount < 0) {
      this.inventoryItem.amount = 0;
    }
  }

  public changeAmount(amount: number) {
    if (!amount) {
      amount = 0;
    }

    const oldAmount = this.inventoryItem.amount;
    this.inventoryItem.amount = +amount;
    if (oldAmount !== this.inventoryItem.amount) {
      this.inventoryItemChanged.emit({
        ...this.inventoryItem,
        increment: oldAmount - +amount,
        subtraction: +amount <= oldAmount
      });
    }
  }

  public addAmount() {
    this.inventoryItem.amount++;
    this.inventoryItemChanged.emit({...this.inventoryItem, increment: 1, subtraction: false});
  }

  public removeAmount() {
    if (this.inventoryItem.amount === 0) {
      return;
    }

    this.inventoryItem.amount --;
    this.inventoryItemChanged.emit({ ...this.inventoryItem, increment: 1, subtraction: true });
  }

  public onDetailsChange(inventoryItem) {
    this.inventoryItemChanged.emit(inventoryItem);
  }

  public onInventoryItemDelete(inventoryItem) {
    this.inventoryItemDeleted.emit(inventoryItem);
  }
}
