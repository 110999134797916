import { DomainModel } from '@domain/domain.model';
import { Activity } from '@domain/models/activity.model';
import * as uuid from 'uuid/v4';

export class ProjectActivity extends DomainModel {
  // Configuration
  public entity = 'project-activity';
  public table = 'project_activities';
  public schema =
    'id, remote_id, project_id, activity_id, [activity_id+project_id]';
  public sync = true;
  public id: string;

  // Fields
  public project_id: string;
  public activity_id: number;
  public applicable: boolean;
  public start: string|null;
  public startDate: Date|null;
  public number_movers: number;
  public number_trucks: number;
  public details: string;
  public address_id?: string;
  public machine?: string;

  public address?: string;
  public activity: Activity;

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public async init() {
    if (this.start === 'Invalid date') {
      this.start = null;
    }

    this.startDate = this.start ? new Date(this.start) : null;

    // Set relations
    this.activity = await Activity.query.get(this.activity_id);
  }

  public getData() {
    return {
      id: this.id,
      project_id: this.project_id,
      activity_id: this.activity_id,
      applicable: this.applicable,
      details: this.details || '',
      number_movers: this.number_movers || 0,
      number_trucks: this.number_trucks || 0,
      start: this.formatDate(this.startDate),
      address_id: this.address_id || null,
      address: this.address || null,
      machine: this.machine || null
    };
  }

  public updateDate() {
    this.start = this.formatDate(this.startDate);

    if (this.start === 'Invalid date') {
      this.start = null;
    }
  }
}
