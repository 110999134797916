import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Address } from '@domain/models/address.model';
import { Location } from '@domain/models/location.model';
import { DataService, QueryOptions } from '@shared/services/data.service';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from '@node_modules/rxjs';
import 'rxjs/add/operator/takeUntil';
import { AddressTemplate } from '@domain/models/address-template.model';
import { ValidateAlphaNumeric } from '@shared/validators/alpha-numeric-validator';
import { ValidateDutchZipcode } from '@shared/validators/dutch-zipcode-validator';
import { ValidateNonNegative } from '@shared/validators/non-negative-validator';
import { SelectItem } from '@node_modules/primeng/components/common/selectitem';
import { takeUntil } from '@node_modules/rxjs/operators/takeUntil';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';

@Component({
  selector: 'app-inventory-address-detail',
  templateUrl: 'address-detail.component.html'
})
export class InventoryAddressDetailComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public errors: any = {};
  public showErrors = false;
  public addressTypes: SelectItem[] = [];
  public barrackLocationsList: SelectItem[] = [];
  public barrackLocations: Location[] = [];
  public address = new Address({});
  public project = new Project({});
  public routeAddressId;
  public addressTemplates: AddressTemplate[] = [];
  public indexes: SelectItem[] = [];
  public mode = { isAdd: true };
  public disabled = false;

  private subscriptionAddressLoaded: Subscription;
  private destroy$ = new Subject<void>();

  public constructor(private api: ApiServiceWithLoaderService,
                     private router: Router,
                     private route: ActivatedRoute,
                     private dataService: DataService,
                     private projectService: ProjectService,
                     private formBuilder: FormBuilder) {
  }

  public async ngOnInit(): Promise<any> {
    this.addressTemplates = (await this.api.get('/address-templates').toPromise()).data;

    await this.loadLists();

    this.project = this.projectService.getProject();
    this.disabled = this.project.status === 'booked';

    this.initForm();

    // Get id of address to edit by route params
    this.route.params
      .pipe(takeUntil(this.destroy$))
      .subscribe((params: Params) => {
        this.routeAddressId = params['id'];

        if (this.routeAddressId) {
          this.mode.isAdd = false;
          this.projectService.getAddress(this.routeAddressId);
          this.subscriptionAddressLoaded = this.projectService.addressLoaded$.subscribe((address) => {
            this.address = address;

            this.updateForm();
          });
        }

        this.projectService.projectLoaded$.subscribe((project) => {
          this.project = project;
          this.disabled = this.project.status === 'booked';

          this.updateForm();
        });
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public selectAddressTemplate(locationId: number): void {
    this.address.location_id = locationId;

    this.updateFormToAddressTemplate(this.addressTemplates.find((address: AddressTemplate) => address.location_id === locationId));
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control(this.address.id),
      project_id: this.formBuilder.control(this.project.id || null),
      address_type_id: this.formBuilder.control({
        value: this.address.address_type_id,
        disabled: this.disabled
      }, Validators.required),
      location_id: this.formBuilder.control({
        value: this.address.location_id,
        disabled: this.disabled
      }),
      street: this.formBuilder.control({
        value: this.address.street,
        disabled: this.disabled
      }, [Validators.required, ValidateAlphaNumeric]),
      housenumber: this.formBuilder.control({
        value: this.address.housenumber,
        disabled: this.disabled
      }, [Validators.required, ValidateNonNegative]),
      housenumber_add: this.formBuilder.control({ value: this.address.housenumber_add, disabled: this.disabled }),
      zipcode: this.formBuilder.control({
        value: this.address.zipcode,
        disabled: this.disabled
      }, [Validators.required, ValidateDutchZipcode]),
      city: this.formBuilder.control({ value: this.address.city, disabled: this.disabled }, [Validators.required]),
      country: this.formBuilder.control({
        value: this.address.country || 'Nederland',
        disabled: this.disabled
      }, [Validators.required, ValidateAlphaNumeric]),
      lat: this.formBuilder.control(this.address.lat),
      lon: this.formBuilder.control(this.address.lon),
    });
  }

  public async onAddressChange() {
    const zipcode = this.form.value.zipcode;
    const housenumber = this.form.value.housenumber;
    const street = this.form.value.street;
    const city = this.form.value.city;

    if (!zipcode || !housenumber) {
      return;
    }

    if (street || city) {
      return;
    }

    const result = await this.api
      .post('/address/search', {
        zipcode: this.form.value.zipcode,
        housenumber: this.form.value.housenumber
      })
      .toPromise();

    if (!result) {
      return;
    }

    // Update street and city values
    this.form.patchValue({
      street: result.street,
      city: result.city
    });
  }

  public async onSubmit(): Promise<void> {
    if (this.form.valid) {
      this.address = this.form.value;

      if (!this.address.index) {
        const existingAddress = this.project.addresses.find(address => address.id === this.address.id);
        if (existingAddress) {
          this.address.index = existingAddress.index;
        } else {
          this.address.index = this.getIndexNumber();
        }
      }

      await this.projectService.saveAddress(this.address);
      await this.projectService.setProjectUpdated();
      await this.projectService.saveProject();

      this.onCloseClick();
    } else {
      this.showErrors = true;
    }
  }

  public onCloseClick(): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/address');
  }

  public setAddressType(id: number): void {
    this.address.address_type_id = id;
  }

  private updateFormToAddressTemplate(address: Address): void {
    this.address.street = address ? address.street : '';
    this.address.housenumber = address ? address.housenumber : '';
    this.address.housenumber_add = address ? address.housenumber_add : '';
    this.address.zipcode = address ? address.zipcode : '';
    this.address.city = address ? address.city : '';
    this.address.country = address ? address.country : '';

    this.updateForm();
  }

  private async loadAddressTypes() {
    const result = await this.dataService.get('address_types', new QueryOptions(), '/address-type/list');
    result.forEach((item) => {
      this.addressTypes.push({ label: item.name, value: item.id });
    });

    this.addressTypes = this.dataService.sortDropdownByLabel(this.addressTypes);
  }

  private async loadLists() {
    await this.loadAddressTypes();

    const queryOptions = new QueryOptions({
      sortColumn: 'name',
      usePaging: false
    });

    const result = await this.dataService.get('locations', queryOptions, '/location-group/list');
    result.forEach((location) => {
      if (location.type === 'barrack') {
        this.barrackLocations.push(location);
        this.barrackLocationsList.push({ label: location.name, value: location.id });
      }
    });

    this.barrackLocationsList = this.dataService.sortDropdownByLabel(this.barrackLocationsList);
  }

  private getIndexNumber(): number {
    let index = 1;
    while (this.project.addresses.find(address => address.index === index)) {
      index++;
    }

    return index;
  }

  /**
   * Update form information
   */
  private updateForm(): void {
    this.form.reset({
      id: this.address.id,
      project_id: this.project.id || null,
      address_type_id: { value: this.address.address_type_id, disabled: this.disabled },
      location_id: { value: this.address.location_id, disabled: this.disabled },
      street: { value: this.address.street, disabled: this.disabled },
      housenumber: { value: this.address.housenumber, disabled: this.disabled },
      housenumber_add: { value: this.address.housenumber_add, disabled: this.disabled },
      zipcode: { value: this.address.zipcode, disabled: this.disabled },
      city: { value: this.address.city, disabled: this.disabled },
      country: { value: this.address.country || 'Nederland', disabled: this.disabled },
      lat: this.address.lat,
      lon: this.address.lon
    });
  }
}
