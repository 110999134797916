import { Subject } from 'rxjs/Subject';
import { DomainModel } from '@domain/domain.model';
import { Client } from '@domain/models/client.model';
import { Location } from '@domain/models/location.model';
import { RelationGroup } from '@domain/models/relation-group.model';
import { User } from '@domain/models/user.model';
import { Project } from '@domain/models/project.model';
import { Address } from '@domain/models/address.model';
import { AddressType } from '@domain/models/address-type.model';
import { Contact } from '@domain/models/contact.model';
import { Specialty } from '@domain/models/specialty.model';
import { Activity } from '@domain/models/activity.model';
import { ProjectActivity } from '@domain/models/project-activity.model';
import { ProjectSpecialty } from '@domain/models/project-specialty.model';
import { ProjectMaterial } from '@domain/models/project-material.model';
import { DefaultInventory } from '@domain/models/default-inventory.model';
import { DefaultInventoryItem } from '@domain/models/default-inventory-item.model';
import { AddressTemplate } from '@domain/models/address-template.model';
import { Inventory } from '@domain/models/inventory.model';
import { InventoryItem } from '@domain/models/inventory-item.model';
import { DefaultItem } from '@domain/models/default-item.model';
import { Task } from '@domain/models/task.model';
import { Material } from '@domain/models/material.model';
import { MaterialGroup } from '@domain/models/material-group.model';
import { Quotation } from '@domain/models/quotation.model';
import { QuotationTask } from '@domain/models/quotation-task.model';
import { QuotationMaterial } from '@domain/models/quotation-material.model';
import { BulkPrice } from '@domain/models/bulk-price.model';
import { WorkAssignment } from '@domain/models/work-assignment.model';
import { WorkAssignmentItem } from '@domain/models/work-assignment-item.model';
import { WorkAssignmentAddress } from './models/work-assignment-address.model';
import { environment } from '@environments/environment';
import { Machine } from '@domain/models/machine.model';
import { Car } from '@domain/models/car.model';
import Dexie from 'dexie';
import { Organisation } from '@domain/models/organisation.model';
import { OrganisationUsers } from '@domain/models/organisation-users.model';
import { ContactTemplate } from '@domain/models/contact-template.model';
import { Picture } from '@domain/models/picture.model';

export class Store extends Dexie {
  private static _store: Store = null;
  private static _isReady: boolean;
  public static onReady: Subject<boolean> = new Subject<boolean>();

  private currentDbVersion = 224;
  public entities: Dexie.Table<DomainModel, number>[];

  // Define all models to be used in the Dexie database here
  public models: Array<any> = [
    Client,
    Location,
    RelationGroup,
    User,
    Project,
    AddressType,
    Address,
    Contact,
    ContactTemplate,
    Specialty,
    Activity,
    ProjectActivity,
    ProjectSpecialty,
    ProjectMaterial,
    DefaultInventory,
    DefaultInventoryItem,
    AddressTemplate,
    Inventory,
    InventoryItem,
    DefaultItem,
    Task,
    Material,
    MaterialGroup,
    Organisation,
    OrganisationUsers,
    Quotation,
    QuotationTask,
    QuotationMaterial,
    BulkPrice,
    WorkAssignment,
    WorkAssignmentItem,
    WorkAssignmentAddress,
    Machine,
    Car,
    Picture,
  ];

  /**
   * Create indexedDB tables
   */
  constructor() {
    super(environment.db_name, { autoOpen: true });

    if (!environment.db_name || environment.db_name === '') {
      console.trace('LocalDB name is not set!');
    }
  }

  public async setup(): Promise<boolean> {
    if (Store._isReady) {
      return true;
    }

    if (await Dexie.exists(environment.db_name)) {
      await this.open();
      if (this.verno < this.currentDbVersion) {
        // Delete current outdated database if applicable
        await this.delete();
        await this.version(1).stores({});
      } else {
        await this.close();
      }
    }

    // Generate schema object
    const schema: any = {};

    // Add each model
    this.models.forEach(model => {
      const m = model.getInstance();
      schema[m.table] = m.schema;
    });

    // Create schema
    await this.version(this.currentDbVersion).stores(schema);
    await this.open();

    DomainModel.store = this;

    // Map each table to class model
    this.models.forEach(model => {
      const m = model.getInstance();
      this[m.table].mapToClass(model);
    });

    Store._isReady = true;
    Store.onReady.next(true);

    return true;
  }

  /**
   * Resets the database by removing and re-creating it
   */
  public async reset() {
    await this.delete();
    await this.setup();
  }

  /**
   * Return singleton instance
   */
  public static getStore(): Store {
    if (Store._store === null) {
      Store._store = new Store();
    }
    return Store._store;
  }
}
