import { DomainModel } from '@domain/domain.model';

export class BulkPrice extends DomainModel {

  // Configuration
  public entity = 'bulk-price';
  public table = 'bulk_prices';
  public schema = '++id, remote_id, distance_min, distance_max, volume_min, volume_max';
  public sync = true;

  // Fields
  public start_date: Date;
  public end_date: Date;
  public distance_min: number;
  public distance_max: number;
  public volume_min: number;
  public volume_max: number;
  public price: number;
  public bulkPriceTypeKey: string;

  // Constructor
  constructor(attributes) {
    super(attributes);
  }
}
