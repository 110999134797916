import { Injectable, OnDestroy } from '@angular/core';
import { Headers, Http, Response, ResponseContentType } from '@angular/http';
import { AuthService } from '@blueprint/auth/auth.service';
import { BehaviorSubject, Observable } from '@node_modules/rxjs';
import { environment } from '@environments/environment';
import { catchError, finalize, map, shareReplay } from '@node_modules/rxjs/operators';
import { throwError as observableThrowError } from '@node_modules/rxjs/internal/observable/throwError';

@Injectable({ providedIn: 'root' })
export class ApiServiceWithLoaderService {
  private _loader = new BehaviorSubject<number>(0);

  constructor(
    private http: Http,
    private authService: AuthService
  ) {
  }

  public getLoader(): Observable<number> {
    return this._loader.asObservable().pipe(shareReplay(1));
  }

  public onAuthError: () => void = () => {
    if (!environment.production) {
      console.warn('onAuthError has to be set in order to redirect to login page');
    }
  }

  public get(url: string): Observable<any> {
    return this.http.get(`${environment.baseUrl}${url}`,
      { headers: this.getHeaders(), responseType: ResponseContentType.Json }).pipe(
      map((response: Response) => response.json()),
      catchError((response: Response) => this.handleError(response)));
  }

  public getBlob(url: string, type: string): Observable<any> {
    const headers = this.getHeaders();
    headers.delete('Content-Type');

    return this.http.get(`${environment.baseUrl}${url}`,
      { headers: headers, responseType: ResponseContentType.Blob }).pipe(
      map((response: Response) => new Blob([response.blob()], { type: type })),
      catchError((response: Response) => this.handleError(response)));
  }

  public post(url: string, data: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}${url}`,
      data,
      { headers: this.getHeaders(), responseType: ResponseContentType.Json }).pipe(
      map((response: Response) => response.json()),
      catchError((response: Response) => this.handleError(response)));
  }

  public patch(url: string, data: any): Observable<any> {
    return this.http.patch(`${environment.baseUrl}${url}`,
      data,
      { headers: this.getHeaders(), responseType: ResponseContentType.Json }).pipe(
      map((response: Response) => response.json()),
      catchError((response: Response) => this.handleError(response)));
  }

  public delete(url: string): Observable<any> {
    return this.http.delete(`${environment.baseUrl}${url}`,
      { headers: this.getHeaders(), responseType: ResponseContentType.Json }).pipe(
      map((response: Response) => response.json()),
      catchError((response: Response) => this.handleError(response)));
  }

  public increaseLoaderValueByOne(): void {
    return this._loader.next(+this._loader.getValue() + 1);
  }

  public decreaseLoaderValueByOne(): void {
    setTimeout(() => {
      let amount = this._loader.getValue() - 1;

      if (amount < 0) {
        amount = 0;
      }

      if (+this._loader.getValue() <= 0) {
        return this._loader.next(0);
      } else {
        return this._loader.next(+amount);
      }
    }, 10);
  }

  private getHeaders() {
    const headers = new Headers();
    headers.append('Authorization', 'Bearer ' + this.authService.getToken());
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return headers;
  }

  private handleError(error: any): Observable<any> {
    if (error.status === 401) {
      this.onAuthError();
    }

    return observableThrowError(error);
  }
}
