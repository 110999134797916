import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Contact } from '@domain/models/contact.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { customEmailValidator } from '@shared/services/custom-validators.service';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { Subscription } from 'rxjs/Subscription';
import { environment } from '@environments/environment';
import { UserService } from '@shared/services/user.service';
import { DataService } from '@shared/services/data.service';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';

@Component({
  selector: 'app-inventory-contacts-detail',
  templateUrl: 'contact-detail.component.html'
})
export class InventoryContactsDetailComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public showErrors = false;
  public errors: any = {};
  public disabled = false;
  public project = new Project({});
  public contactTemplates: any[] = [];
  public environment: any;
  public mode = { isAdd: true, alertText: 'Contact succesvol toegevoegd' };

  private locationId: number;
  private contact = new Contact({});
  private routeContactId;
  private subscriptionContactsChanged: Subscription;

  public constructor(
    private api: ApiServiceWithLoaderService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private dataService: DataService,
    private projectService: ProjectService) {
    this.locationId = this.userService.getLocationId();
    this.environment = environment;
    this.makeContactTemplates();
  }

  public ngOnInit(): void {
    this.project = this.projectService.getProject();
    this.disabled = this.project.status === 'booked';
    this.initForm();

    this.route.params.subscribe((params) => {
      this.routeContactId = params['id'];

      if (this.routeContactId) {
        this.mode.isAdd = false;
        this.projectService.getContact(this.routeContactId);
        this.subscriptionContactsChanged = this.projectService.contactsChanged$.subscribe((contact) => {
          this.contact = contact;
          this.updateForm();
        });
      }

      this.projectService.projectLoaded$.subscribe((project) => {
        this.project = project;
        this.disabled = this.project.status === 'booked';
        this.updateForm();
      });
    });
  }

  public ngOnDestroy() {
    if (this.subscriptionContactsChanged) {
      this.subscriptionContactsChanged.unsubscribe();
    }
  }

  public initForm() {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control({ value: this.contact.id, disabled: this.disabled }),
      client_id: this.formBuilder.control({ value: this.project.client.id || null, disabled: this.disabled }),
      contactTemplates: this.formBuilder.control({ value: this.contactTemplates, disabled: this.disabled }),
      name: this.formBuilder.control({ value: this.contact.name || '', disabled: this.disabled }, Validators.required),
      email: this.formBuilder.control({ value: this.contact.email || '', disabled: this.disabled }, [customEmailValidator]),
      phone: this.formBuilder.control({ value: this.contact.phone || '', disabled: this.disabled }, Validators.required),
      mobile_phone: this.formBuilder.control({ value: this.contact.mobile_phone || '', disabled: this.disabled }),
      remarks: this.formBuilder.control({ value: this.contact.remarks, disabled: this.disabled }),
    });
  }

  public onSubmit(): void {
    if (this.form.valid) {
      this.contact = this.form.value;
      this.projectService.saveContact(this.contact);
      this.onCloseClick();
    } else {
      this.showErrors = true;
    }
  }

  public onCloseClick(): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/contact');
  }

  public selectContactTemplate(event: any): void {
    const selected = this.contactTemplates.find((contact) => contact.value === event.value);
    this.updateFormToContactTemplate(selected);
  }

  private async makeContactTemplates(): Promise<void> {
    const result = await this.api.get('/contact-templates').toPromise();

    result.data.forEach((item) => {
      if (item) {
        this.contactTemplates.push({
          ...item,
          value: item.id,
          label: item.name
        });
      }
    }, this);

    this.contactTemplates = this.dataService.sortDropdownByLabel(this.contactTemplates);
  }

  private updateFormToContactTemplate(selected: any): void {
    this.form.patchValue({
      name: selected.name || '',
      email: selected.email || '',
      phone: selected.phone || '',
      mobile_phone: selected.mobile_phone || '',
      remarks: selected.remarks || ''
    });
  }

  /**
   * Update form values
   */
  private updateForm(): void {
    this.form.reset({
      id: { value: this.contact.id, disabled: this.disabled },
      client_id: { value: this.project.client.id || null, disabled: this.disabled },
      contactTemplates: { value: this.contactTemplates, disabled: this.disabled },
      name: { value: this.contact.name || '', disabled: this.disabled },
      email: { value: this.contact.email || '', disabled: this.disabled },
      phone: { value: this.contact.phone || '', disabled: this.disabled },
      mobile_phone: { value: this.contact.mobile_phone || '', disabled: this.disabled },
      remarks: { value: this.contact.remarks, disabled: this.disabled },
    });
  }
}
