import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProjectService } from '@shared/services/project.service';
import { Project } from '@domain/models/project.model';
import { Address } from '@domain/models/address.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inventory-address-sort',
  templateUrl: 'address-sort.component.html',
  styleUrls: ['./address-sort.component.scss'],
})
export class InventoryAddressSortComponent implements OnInit, OnDestroy {
  public addresses: Address[];
  public project: Project;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
      private projectService: ProjectService,
      private router: Router
  ) {
  }

  public async ngOnInit(): Promise<void> {
    this.project = await this.projectService.getProject();
    this.addresses = !!this.project.addresses ? this.project.addresses.sort((a, b) => a.index > b.index ? 1 : -1) : [];

    // In case project was loaded
    this.projectService.projectLoaded$
        .pipe(takeUntil(this.destroy$))
        .subscribe(project => {
          this.project = project;
          this.addresses = !!this.project.addresses ? this.project.addresses.sort((a, b) => a.index > b.index ? 1 : -1) : [];
        });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onCloseClick(): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/address');
  }

  public async save(): Promise<void> {
    await this.projectService.saveAddresses(this.addresses);
    this.onCloseClick();
  }

  /**
   * On Drag&Drop event
   *
   * @param event: any
   * @returns void
   */
  public drop(event: CdkDragDrop<any>): void {
    moveItemInArray(this.addresses, event.previousIndex, event.currentIndex);

    // Update index property
    for (let i = 0; i < this.addresses.length; i++) {
      this.addresses[i].index = i + 1;
    }
  }
}
