import { Component, OnInit } from '@angular/core';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss']
})
export class ManageComponent implements OnInit {
  public tenantName: string;
  public isAdministrator: boolean;
  public isLocationManager: boolean;
  public userAppendsToTopMover: boolean;
  public useOrganisations: boolean = true;

  constructor(public userService: UserService) {
    this.tenantName = '';
    this.isAdministrator = this.userService.isAdministrator();
    this.isLocationManager = this.userService.isLocationManager();
    this.userAppendsToTopMover = this.userService.userAppendsToTopMover();
  }

  public ngOnInit(): void {
    this.tenantName = this.userService.hasRole('superuser') ? 'entities.tenants' : 'entities.locations';
  }
}
