import { AbstractControl } from '@angular/forms';

export function ValidateDutchZipcode(control: AbstractControl) {
  const dutchZipcode = new RegExp('\\d{4}\\s?[a-zA-Z]{2}');

  return dutchZipcode.test(control.value) ? null : {
    ValidateDutchZipcode: {
      valid: false
    }
  };
}
