import { Component, OnInit, OnDestroy } from '@angular/core';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { ProjectMaterial } from '@domain/models/project-material.model';
import { Subscription } from '@node_modules/rxjs';
import { SelectItem } from 'primeng/api';
import { DataService, QueryOptions } from '@shared/services/data.service';
import { Inventory } from '@domain/models/inventory.model';
import { Address } from '@domain/models/address.model';
import { Contact } from '@domain/models/contact.model';
import { ProjectSpecialty } from '@domain/models/project-specialty.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class InventoryOverviewComponent implements OnInit, OnDestroy {
  public project = new Project({});
  public materials: ProjectMaterial[];
  public inventories: Inventory[];
  public addresses: Address[];
  public executorList: SelectItem[];
  public contacts: Contact[];
  public specialties: ProjectSpecialty[];
  public showMaterials: boolean = false;

  public volumeTotal: number;
  public assemblyTotal: number;
  public packingTotal: number;
  public meterboxTotal: number;

  private subscriptionProjectLoaded: Subscription;

  constructor(private projectService: ProjectService, private dataService: DataService, private route: ActivatedRoute) {
    this.executorList = [];
    this.contacts = [];

    this.getExecutors();
  }

  public ngOnInit(): void {
    this.project = this.projectService.getProject();
    this.materials = this.project.materials;
    this.inventories = this.project.inventories;
    this.addresses = this.project.addresses;

    this.getContacts();
    this.getSelectedSpecialties();

    this.volumeTotal = this.projectService.calculateVolume();
    this.packingTotal = this.projectService.calculatePackingTotal();
    this.assemblyTotal = this.projectService.calculateAssemblyTotal();
    this.meterboxTotal = this.projectService.calculateMeterboxTotal();

    this.subscriptionProjectLoaded = this.projectService.projectLoaded$.subscribe((project: Project) => {
      this.project = project;
      this.materials = project.materials;
      this.inventories = project.inventories;
      this.addresses = project.addresses;

      this.getContacts();
      this.getSelectedSpecialties();

      this.volumeTotal = this.projectService.calculateVolume();
      this.packingTotal = this.projectService.calculatePackingTotal();
      this.assemblyTotal = this.projectService.calculateAssemblyTotal();
      this.meterboxTotal = this.projectService.calculateMeterboxTotal();
      this.initMaterials();
    });

    this.initMaterials();
  }

  public ngOnDestroy(): void {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }
  }

  /**
   * Check if the given input is a number; otherwise return 0
   * @param input: any
   */
  public checkIfNumber(input: any): number {
    return Number(input) || 0;
  }

  /**
   * Returns the correct project status string, filtered on the statusList from Project model
   * @param projectStatus: string
   */
  public getCorrectStatus(projectStatus: string): string {
    const statusses: SelectItem[] = Project.getStatusList();
    const correctStatus = statusses.find((status: SelectItem) => status.value === projectStatus);

    return correctStatus && correctStatus !== undefined ? correctStatus.label : '- niet beschikbaar -';
  }

  /**
   * Returns the name of executor, filtered on the executorList
   * If no executors available, return the executor ID
   * @param projectExecutorId: number
   */
  public getCorrectExecutor(projectExecutorId: number): string {
    if (this.executorList.length > 0) {
      const result = this.executorList.find((executor: SelectItem) => executor.value === projectExecutorId);

      if (result && result !== undefined) {
        return result.label;
      }
    }

    return projectExecutorId ? projectExecutorId.toString() : 'n.v.t.';
  }

  /**
   * Returns the address in one complete string
   * 
   * @param address: Address
   * 
   * @returns string
   */
  public getDisplayAddress(address: Address): string {
    const addressParts = [
      `${address.street || ''} ${address.housenumber || ''}${address.housenumber_add || ''}`.trim(),
      `${address.zipcode || ''} ${address.city || ''}`.trim(),
      `${address.country}`.trim()
    ];

    return addressParts.reduce((prev, current) => (prev && current ? prev + ', ' : prev) + current);
  }

  /**
   * Export to PDF
   */
  public printOverview(): void {
    const divToPrint = document.getElementById('inventory-overview');
    const head = document.getElementsByTagName('head')[0];
    const popupWin = window.open('', '_blank', 'width=1024,height=768');
    popupWin.document.open();
    popupWin.document.write('<html>' +
      ' <head>' + head.innerHTML + '</head>' +
      ' <body style="background:#fff;">' +
      '  <img src="/assets/images/logo.png" style="height: 100px; display: flex; margin: 25px auto 0 auto;"><br /><br />' +
      '   ' + divToPrint.innerHTML + '' +
      ' </body>' +
      '</html>');
    popupWin.document.getElementById('inventory-overview-print-button').remove();
    popupWin.document.getElementById('inventory-overview-title').innerText = 'Overzicht - ' + this.project.clientName +
      (this.project.reference_nr.length > 0 ? ' (' + this.project.reference_nr + ')' : '');

    const elements = popupWin.document.getElementsByTagName('h5');
    for (let i = 0; i < elements.length; i++) {
      elements[i].setAttribute('style', 'font-size: 18px; font-weight: bold;');
    }

    /** Timeout to make sure the window is loaded */
    setTimeout(() => popupWin.print(), 300);
    popupWin.document.close();
  }

  /**
   * Get total amount of Room
   *
   * @param id
   */
  public getTotalOfRoom(id: string): number {
    let total = 0;

    for (const inventory of this.project.inventories) {
      if (inventory.items && inventory.id === id) {
        for (const item of inventory.items) {
          if (item.amount) {
            total += item.amount * item.volume;
          }
        }
      }
    }

    return total;
  }

  /**
   * Get total amount of Product
   *
   * @param name
   */
  public getTotalOfProduct(name: string): number {
    let total = 0;

    for (const inventory of this.project.inventories) {
      if (inventory.items) {
        for (const item of inventory.items) {
          if (item.name.toLowerCase() === name.toLowerCase()) {
            if (item.amount) {
              total += +item.amount;
            }
          }
        }
      }
    }

    return total;
  }

  /**
   * Retrieve the executors from data Service
   */
  private async getExecutors(): Promise<void> {
    const queryOptions = new QueryOptions({
      sortColumn: 'name_lcase',
      usePaging: false
    });

    const result = await this.dataService.get('users', queryOptions, '/users/list');

    result.forEach((item) => {
      this.executorList.push({ label: `${item.first_name} ${item.last_name}`, value: item.id });
    });

    this.executorList.sort((one, two) => (one > two ? 1 : -1));
  }

  /**
   * Get the contacts belonging to the client based on Project Client ID
   */
  private async getContacts(): Promise<void> {
    const result = await this.dataService.getBy('contacts', 'client_id', this.project.client_id);

    this.contacts = [];
    result.forEach((contact: Contact) => {
      this.contacts.push(contact);
    });
  }

  /**
   * Retrieve the selected / active specialties
   */
  private getSelectedSpecialties(): void {
    this.specialties = [];

    this.project.specialties.forEach((specialty: ProjectSpecialty) => {
      if (specialty.applicable) {
        this.specialties.push(specialty);
      }
    });
  }

  /**
   * Init the Materials
   */
  private initMaterials(): void {
    this.materials.forEach(async (material: ProjectMaterial) => {
      await material.init();
    });
  }
}
