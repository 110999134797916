import { DomainModel } from '@domain/domain.model';

import * as uuid from 'uuid/v4';

export class Car extends DomainModel {
  // Configuration
  public entity = 'car';
  public table = 'cars';
  public schema = 'id, location_id';
  public sync = true;
  public id: string;

  // Fields
  public location_id: number;
  public name: string;
  public license_plate: string;
  public fuel_type_id: number;
  public compensate_at_pump: boolean;

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public async init() {
  }

  public getData(): any {
    return {
      location_id: this.location_id || null,
      name: this.name || null,
      license_plate: this.license_plate || null,
      fuel_type_id: this.fuel_type_id || null,
      compensate_at_pump: this.compensate_at_pump || false
    };
  }
}
