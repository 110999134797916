import { Component, OnInit, ViewChild } from '@angular/core';
import { OnlineTableComponent, OnlineTableOptions } from '@shared/controls/table/online-table.component';
import { Router } from '@angular/router';
import { ConfirmationService } from '@node_modules/primeng/components/common/confirmationservice';
import { UserService } from '@shared/services/user.service';
import { ProjectService } from '@shared/services/project.service';

@Component({
  selector: 'app-cars-list',
  templateUrl: './cars-list.component.html',
  styleUrls: ['./cars-list.component.scss']
})
export class ManageCarsListComponent implements OnInit {
  @ViewChild(OnlineTableComponent) table: OnlineTableComponent;

  public tableOptions: OnlineTableOptions;
  public tableVisible: boolean;

  constructor(private router: Router,
              private confirmationService: ConfirmationService,
              private userService: UserService,
              private projectService: ProjectService) {
    this.tableVisible = true;
  }

  public async ngOnInit(): Promise<void> {
    this.setTableOptions();

    // In case car is added (from popup)
    this.projectService.carsUpdated$.subscribe(() => {
      this.tableVisible = false;

      setTimeout(() => {
        this.tableVisible = true;
      }, 0);
    });
  }

  public setTableOptions(): void {
    this.tableOptions = new OnlineTableOptions({
      columns: [
        { title: 'Locatie', name: 'location.name', filter: { global: true } },
        { title: 'Naam', name: 'name', filter: { global: true } },
        { title: 'Kenteken', name: 'license_plate', filter: { global: true } },
        { title: 'Brandstofsoort', name: 'fuel_type.name', filter: { global: true } },
        { title: 'Compenseren aan pomp', name: 'compensate_text', filter: { global: true } },
      ],
      endpoint: '/car',
      search: true,
      withDelete: true,
      withFilter: false,
      rowDataTransformer: (rows) => {
        for (const row of rows) {
          row.compensate_text = row.compensate_at_pump && row.compensate_at_pump === true ? 'Ja' : 'Nee';
        }

        return rows;
      },
    });
  }

  public openCarDetails(row: any) {
    this.router.navigateByUrl(`admin/manage/cars(popup:admin/manage/cars/${row.id})`);
  }

  public addNewCar(): void {
    this.router.navigateByUrl('/admin/manage/cars(popup:admin/manage/cars/add)');
  }
}
