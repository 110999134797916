import { Injectable } from '@angular/core';
import { ApiService } from '@root/node_modules/@emendis/api';
import { Observable } from 'rxjs';
import { Quotation } from '@domain/models/quotation.model';

@Injectable()
export class QuotationSignApiService extends ApiService<Quotation> {
    public endpoint = 'quotation/sign';

    public getQuotationByProject(quotationSignId: string, projectId: string): Observable<Quotation> {
        return this.apiHttp.get(`/quotation/sign/${quotationSignId}/${projectId}`);
    }

    public postValidateQuotation(data: any): Observable<Quotation> {
        return this.apiHttp.post('/verify/quotation/sign', [data]);
    }
}
