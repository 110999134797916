import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { Store } from '@domain/store';

@Injectable()
export class BootstrapGuard implements CanActivate {
  canActivate() {
    const store = Store.getStore();
    return Observable.fromPromise(store.setup());
  }
}
