import { Component, OnInit } from '@angular/core';
import { SelectItem } from "@node_modules/primeng/components/common/selectitem";
import { DataService } from "@shared/services/data.service";
import { SynchronisationService } from "@shared/services/synchronisation.service";
import { CalendarLocale } from "@domain/models/calendar-locale.model";
import { ApiService } from "@blueprint/services/api.service";
import * as toastr from 'toastr';
import * as moment from 'moment';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  public rangeDates: Date[] = [new Date(), new Date()];

  public topMoversList: SelectItem[] = [];
  public barracksList: SelectItem[] = [];

  public selectedTopMovers: string[] = [];
  public selectedBarracks: string[] = [];

  public localeNL: CalendarLocale = new CalendarLocale();

  constructor(private api: ApiServiceWithLoaderService,
              private dataService: DataService,
              private synchronisationService: SynchronisationService) {
  }

  public async ngOnInit(): Promise<void> {
    const topMovers = await this.synchronisationService.getTopMoverLocations();
    this.topMoversList = this.dataService.sortDropdownByLabel(topMovers);

    const barracks = await this.synchronisationService.getBarrackLocations();
    this.barracksList = this.dataService.sortDropdownByLabel(barracks);
  }

  public submit(): void {
    if (!this.rangeDates || this.rangeDates.length !== 2) {
      toastr.error('Vul een valide datumbereik in.');
      return;
    }

    toastr.success('De rapportage wordt gegenereerd', 'Rapportage');

    const request = {
      "date_start": moment(this.rangeDates[0]).format('YYYY-MM-DD'),
      "date_end": moment(this.rangeDates[1]).format('YYYY-MM-DD'),
      "top_mover_ids": this.selectedTopMovers,
      "barrack_ids": this.selectedBarracks
    };
    const response = this.api.post('/report', request);
    response.subscribe(_ => {
      toastr.success('De rapportage is succesvol gegenereerd en naar uw e-mailadres gestuurd', 'Rapportage');
      }, (error: any) => {
        if (error.status === 422 && error.json) {
          toastr.warning('Ongeldige aanvraag', 'Rapportage');
        } else {
          toastr.error('Fout bij genereren van rapportage', 'Rapportage');
        }
      }
    );
  }
}
