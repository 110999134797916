import { DomainModel } from '@domain/domain.model';

import * as uuid from 'uuid/v4';

export class ContactTemplate extends DomainModel {
    // Configuration
    public entity = 'contact_template';
    public table = 'contact_templates';
    public schema = 'id, location_id';
    public sync = true;
    public id: string;

    // Fields
    public location_id?: string;
    public name: string;
    public email: string;
    public phone: string;
    public mobile_phone?: string;
    public remarks?: string;

    // Constructor
    constructor(attributes) {
        super(attributes);

        if (!attributes.id) {
            this.id = uuid();
        }
    }

    /**
     * Return a display friendly name
     */
    public getDisplayName(): string {
        return `${ this.name || '' } ${ this.email || '' }${ this.phone || '' },
        ${ this.mobile_phone || '' }, ${ this.remarks || '' }`;
    }

    public getData(): any {
        return {
            location_id: this.location_id || null,
            name: this.name || '',
            email: this.email || '',
            phone: this.phone || '',
            mobile_phone: this.mobile_phone || '',
            remarks: this.remarks || ''
        };
    }
}
