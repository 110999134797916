import { DomainModel } from '@domain/domain.model';
import { Project } from '@domain/models/project.model';
import { QuotationTask } from '@domain/models/quotation-task.model';
import { QuotationMaterial } from '@domain/models/quotation-material.model';

import * as uuid from 'uuid/v4';

export class Quotation extends DomainModel {
  // Configuration
  public entity = 'quotation';
  public table = 'quotations';
  public schema = 'id, remote_id, project_id, reference';
  public sync = true;
  public id: string;

  // Fields
  public project_id: string;
  public reference?: string;
  public status?: string;
  public calculated_volume?: number;
  public estimated_distance?: number;
  public distance?: number;
  public estimated_distance_km?: number;
  public distance_km?: number;
  public specials_price?: number;
  public custom_option_1_name?: string;
  public custom_option_2_name?: string;
  public custom_option_3_name?: string;
  public custom_option_1_price?: number;
  public custom_option_2_price?: number;
  public custom_option_3_price?: number;
  public subtotal_price?: number;

  public intern_including_disassemble_total_price?: number;
  public intern_excluding_disassemble_total_price?: number;
  public extern_including_disassemble_total_price?: number;
  public extern_excluding_disassemble_total_price?: number;
  public total_options_price?: number;

  public vat_price?: number;
  public total_price?: number;
  public materials: QuotationMaterial[] = [];

  public tasks: QuotationTask[] = [];
  public signatureClientDate?: Date;

  public signatureValuatorDate?: Date;
  public signature_client_date?: string;

  public signature_valuator_date?: string;
  public signature_client_image?: string;

  public signature_valuator_image?: string;

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }

  }

  public async init() {
    this.signatureClientDate = this.signature_client_date
      ? new Date(this.signature_client_date)
      : null;
    this.signatureValuatorDate = this.signature_valuator_date
      ? new Date(this.signature_valuator_date)
      : null;

    if (!this.id) {
      return;
    }

    // Set relations
    const quotationMaterials = await QuotationMaterial.query
      .where('quotation_id')
      .equals(this.id)
      .toArray();
    this.materials = [];
    for (const quotationMaterial of quotationMaterials) {
      await quotationMaterial.init();
      this.materials.push(quotationMaterial);
    }

    const quotationTasks = await QuotationTask.query
      .where('quotation_id')
      .equals(this.id)
      .toArray();
    this.tasks = [];
    for (const quotationTask of quotationTasks) {
      await quotationTask.init();
      this.tasks.push(quotationTask);
    }
  }

  public getData(): any {
    return {
      id: this.id,
      project_id: this.project_id,
      reference: this.reference,
      status: this.status,
      calculated_volume: this.calculated_volume || 0,
      estimated_distance: this.estimated_distance || 0,
      distance: this.distance || 0,
      estimated_distance_km: this.estimated_distance_km || 0,
      distance_km: this.distance_km || 0,
      custom_option_1_name: this.custom_option_1_name || '',
      custom_option_2_name: this.custom_option_2_name || '',
      custom_option_3_name: this.custom_option_3_name || '',
      custom_option_1_price: this.custom_option_1_price || 0,
      custom_option_2_price: this.custom_option_2_price || 0,
      custom_option_3_price: this.custom_option_3_price || 0,
      subtotal_price: this.subtotal_price || 0,
      specials_price: this.specials_price || 0,
      extern_excluding_disassemble_total_price: this.extern_excluding_disassemble_total_price || 0,
      extern_including_disassemble_total_price: this.extern_including_disassemble_total_price || 0,
      intern_excluding_disassemble_total_price: this.intern_excluding_disassemble_total_price || 0,
      intern_including_disassemble_total_price: this.intern_including_disassemble_total_price || 0,
      total_options_price: this.total_options_price || 0,
      vat_price: this.vat_price || 0,
      total_price: this.total_price || 0,
      signature_client_image: this.signature_client_image || null,
      signature_valuator_image: this.signature_valuator_image || null,
      signature_client_date: this.formatDate(this.signatureClientDate) || null,
      signature_valuator_date: this.formatDate(this.signatureValuatorDate) || null,
    };
  }

  public updateDate() {
    this.signature_client_date = this.formatDate(this.signatureClientDate);
    this.signature_valuator_date = this.formatDate(this.signatureValuatorDate);
  }
}
