import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmendisBlueprintModule } from '@blueprint/emendis-blueprint.module';
import { DataService } from '@shared/services/data.service';
import { ReportComponent } from './report.component';
import { CalendarModule } from "@node_modules/primeng/components/calendar/calendar";

@NgModule({
  providers: [
    DataService
  ],
  declarations: [
    ReportComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    EmendisBlueprintModule,
    CalendarModule,
  ]
})
export class ReportModule {
}
