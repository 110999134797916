import { DomainModel } from '@domain/domain.model';
import { Client } from '@domain/models/client.model';
import { Address } from '@domain/models/address.model';
import { Activity } from '@domain/models/activity.model';
import { Specialty } from '@domain/models/specialty.model';
import { Location } from '@domain/models/location.model';
import { ProjectActivity } from '@domain/models/project-activity.model';
import { ProjectSpecialty } from '@domain/models/project-specialty.model';
import { Inventory } from '@domain/models/inventory.model';
import { Quotation } from '@domain/models/quotation.model';
import { SelectItem } from 'primeng/api';
import { User } from '@domain/models/user.model';
import { ProjectMaterial } from './project-material.model';
import * as uuid from 'uuid/v4';
import { Organisation } from '@domain/models/organisation.model';
import { Material } from '@domain/models/material.model';
import { Picture } from '@domain/models/picture.model';

export class Project extends DomainModel {
  // Configuration
  public entity = 'project';
  public table = 'projects';
  public schema = 'id';
  public sync = true;
  public id: string;

  // Fields
  public _original: any;
  public is_new = false;
  public is_changed = false;

  public organisation_id: string;
  public digi_purchase_number?: string;
  public a_number?: string;
  public applicant: string;
  public status = 'new';
  public client_id?: string;
  public clientName?: string;
  public location_manager_id: number;
  public locationmanagerName?: string;
  public executor_id?: number;
  public reference_nr?: string;
  public own_description_activities?: string;
  public client_description_activities?: string;
  public date_start?: string;

  public client = new Client({});
  public locationmanager = new User({});
  public location = new Location({});
  public organisation = new Organisation({});

  public addresses: Address[] = [];
  public specialties: ProjectSpecialty[] = [];
  public activities: ProjectActivity[] = [];
  public inventories: Inventory[] = [];
  public quotations: Quotation[] = [];
  public materials: ProjectMaterial[] = [];
  public pictures: Picture[] = [];

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public async init() {
    // Set relations
    if (this.client_id) {
      this.client = await Client.query.get(this.client_id);
      this.clientName = this.client ? this.client.name : '';
    }

    if (this.location_manager_id) {
      this.locationmanager = await User.query.get(this.location_manager_id);
      this.locationmanagerName = this.locationmanager ? `${this.locationmanager.first_name} ${this.locationmanager.last_name}` : '';
    }
  }

  public async loadActivities() {
    if (!this.id) {
      return;
    }

    this.activities = await ProjectActivity.query
      .where('project_id')
      .equals(this.id)
      .toArray();
    for (const activity of this.activities) {
      await activity.init();
    }

    // Fill activities if not available yet
    if (!this.activities || this.activities.length === 0) {
      const activities = await Activity.query.toArray();

      for (const activity of activities) {
        await activity.init();

        const projectActivity = new ProjectActivity({
          activity_id: activity.id,
          project_id: this.id
        });

        await projectActivity.init();

        this.activities.push(projectActivity);
      }
    }
  }

  public async loadMaterials() {
    if (!this.id) {
      return;
    }

    this.materials = await ProjectMaterial.query
      .where('project_id')
      .equals(this.id)
      .toArray();

    for (const material of this.materials) {
      await material.init();
    }

    // Fill activities if not available yet
    if (!this.materials || this.materials.length === 0) {
      const materials = await Material.query.toArray();

      for (const material of materials) {
        await material.init();

        const projectMaterial = new ProjectMaterial({
          material_id: material.id,
          project_id: this.id
        });

        await projectMaterial.init();

        this.materials.push(projectMaterial);
      }
    }
  }

  public async loadSpecialties() {
    if (!this.id) {
      return;
    }

    this.specialties = await ProjectSpecialty.query
      .where('project_id')
      .equals(this.id)
      .toArray();

    this.specialties.sort((a, b) => a.specialty_id - b.specialty_id);

    for (const specialty of this.specialties) {
      await specialty.init();
    }

    // Fill specialties if not available yet
    if (!this.specialties || this.specialties.length === 0) {
      const specialties = await Specialty.query.toArray();

      this.specialties = [];
      for (const specialty of specialties) {
        await specialty.init();

        const projectSpecialty = new ProjectSpecialty({
          specialty_id: specialty.id,
          project_id: this.id
        });

        await projectSpecialty.init();

        this.specialties.push(projectSpecialty);
      }
    }
  }

  public async loadInventories() {
    this.inventories = await Inventory.query
      .where('project_id')
      .equals(this.id)
      .toArray();

    for (const inventory of this.inventories) {
      await inventory.init();
    }
  }

  public async loadQuotations() {
    this.quotations = await Quotation.query
      .where('project_id')
      .equals(this.id)
      .toArray();

    for (const quotation of this.quotations) {
      await quotation.init();
    }
  }

  public async loadAddresses() {
    this.addresses = await Address.query
      .where('project_id')
      .equals(this.id)
      .toArray();

    for (const address of this.addresses) {
      await address.init();
    }
  }

  public async loadPictures(): Promise<void> {
    this.pictures = await Picture.query
      .where('project_id')
      .equals(this.id)
      .toArray();
    for (const picture of this.pictures) {
      await picture.init();
    }
  }

  /**
   * Retrieves the total price from desbribed specialty
   *
   * @param specialtyName
   * @returns number
   */
  public async getSpecialtyTotalPrice(specialtyName: string): Promise<number> {
    if (!this.specialties || this.specialties.length === 0) {
      await this.loadSpecialties();
    }

    // Find the specialty and return the price
    const specialty = this.specialties.find((specialtyItem) => specialtyItem.specialty.name === specialtyName);

    if (specialty && specialty.applicable) {
      // Determine whether the specialty has been toggled true, if not, don't show it and return null
      if (specialty.specialty.has_hours) {
        return (specialty.hours_estimate ? specialty.hours_estimate : 0) * specialty.specialty.cost_rate;
      } else {
        // If specialty does not contain hours, only display the inital cost rate
        return specialty.specialty.cost_rate;
      }
    } else {
      return null;
    }
  }

  public hasActivities() {
    return this.activities && this.activities.some((activity) => activity.applicable);
  }

  public hasSpecialties() {
    return this.specialties && this.specialties.some((specialty) => specialty.applicable);
  }

  public getData(): any {
    return {
      id: this.id,
      organisation_id: this.organisation_id || null,
      client_id: this.client_id || null,
      applicant: this.applicant || null,
      digi_purchase_number: this.digi_purchase_number || null,
      a_number: this.a_number || null,
      status: this.status || '',
      date_start: this.date_start || '',
      reference_nr: this.reference_nr || '',
      own_description_activities: this.own_description_activities || '',
      client_description_activities: this.client_description_activities || '',
      executor_id: this.executor_id || null,
      location_manager_id: this.location_manager_id || null,
      location: this.location || null
    };
  }

  /**
   * Returns the project status list options
   */
  public static getStatusList(): SelectItem[] {
    return [
      { label: 'Nieuw', value: 'new' },
      { label: 'Voorcalculatie', value: 'pending' },
      { label: 'Geannuleerd', value: 'canceled' },
      { label: 'Nacalculatie', value: 'booked' },
      { label: 'Schouw gepland', value: 'viewing_planned' },
      { label: 'PS Nummer', value: 'digi-known' },
      { label: 'Gearchiveerd', value: 'closed' }
    ];
  }

  /**
   * Returns the status name by value
   * @param status string
   */
  public static getStatusName(status: string): string {
    const result = this.getStatusList().find((item) => item.value === status);

    return result ? result.label : '-';
  }

  /**
   * Returns the project status list options
   */
  public static getRelationList(): SelectItem[] {
    return [
      { label: 'Website', value: 'website' },
      { label: 'Erkende verhuizer', value: 'acknowledged_mover' },
      { label: 'Mondeling', value: 'verbal' },
      { label: 'Overig', value: 'other' }
    ];
  }

  /**
   * Returns the relation name by value
   * @param relation string
   */
  public static getRelationName(relation: string): string {
    const result = this.getRelationList().find((item) => item.value === relation);

    return result ? result.label : '';
  }
}
