import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/primeng';

import { ProjectService } from '@shared/services/project.service';
import { DataService } from '@shared/services/data.service';

@Component({
  selector: 'app-inventory-header',
  templateUrl: 'inventory-header.component.html',
  styleUrls: ['./inventory-header.component.scss']
})
export class InventoryHeaderComponent implements OnInit, OnChanges {
  @Input('inventory') inventory;
  @Input('project') project;
  @Input('inventories') inventories;
  @Input('volumeTotal') volumeTotal;
  @Input('assemblyTotal') assemblyTotal;
  @Input('packingTotal') packingTotal;
  @Input('meterboxTotal') meterboxTotal;
  @Input('disabled') disabled;
  @Input('selectedType') selectedType: any;
  @Input('totalAmountOfSelectedUnit') totalAmountOfSelectedUnit;
  @Input('totalAmountOfUnits') totalAmountOfUnits;

  @Output('inventoryChange') inventoryChange = new EventEmitter;
  @Output() public onTypeChange: EventEmitter<SelectItem> = new EventEmitter<SelectItem>();
  @Output() public onDisassembleAllChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public floor;
  public inventoryList: SelectItem[] = [];

  constructor(private router: Router,
              private projectService: ProjectService,
              private confirmationService: ConfirmationService,
              private dataService: DataService) {
    this.totalAmountOfSelectedUnit = 0;
    this.totalAmountOfUnits = 0;
  }

  public ngOnInit(): void {
    this.project = this.projectService.getProject();

    this.projectService.inventoryAdded$.subscribe(_ => {
      this.totalAmountOfSelectedUnit = 0;
    });
  }

  public ngOnChanges(): void {
    this.loadInventories();
  }

  /**
   * Check if the given input is a number; otherwise return 0
   * @param input: any
   */
  public checkIfNumber(input: any): number {
    if (!Number(input)) {
      try {
        /** Parse string to float, and format to 2 decimals */
        const result = parseFloat(input);
        return Math.round(result * 100) / 100;
      } catch {
        return 0;
      }
    }

    return Number(input) || 0;
  }

  /**
   * When new inventory is selected
   */
  public onInventoryChange(): void {
    this.inventoryChange.emit(this.inventory);
  }

  public onAddClick(): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/inventory(popup:admin/project/inventory/add)');
  }

  public onInventoryTypeChange(type: SelectItem): void {
    this.onTypeChange.emit(type);
  }

  /**
   * Delete selected inventory
   */
  public onDeleteClick(): void {
    this.confirmationService.confirm({
      message: 'Wilt u deze inventarisatie verwijderen?',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      accept: async () => {
        await this.projectService.deleteInventory(this.inventory.id);
      }
    });
  }

  public getTotalOfProduct(name: string): number {
    let total = 0;

    for (const inventory of this.inventories) {
      if (this.inventory.items) {
        for (const item of inventory.items) {
          if (item.name.toLowerCase() === name.toLowerCase()) {
            if (item.amount) {
              total += +item.amount;
            }
          }
        }
      }
    }

    return total;
  }

  /**
   * Load inventories for dropdown
   */
  private loadInventories(): void {
    this.inventoryList = [];

    if (this.inventories) {
      this.inventories.forEach((inventory) => {
        this.inventoryList.push({ label: inventory.name, value: inventory });
      });

      this.inventoryList = this.dataService.sortDropdownByLabel(this.inventoryList);
    }
  }

  private onDisassembleNone(): void {
    this.onDisassembleAllChange.emit(false);
  }

  private onDisassembleAll(): void {
    this.onDisassembleAllChange.emit(true);
  }
}
