import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/toPromise';
import * as toastr from 'toastr';
import * as jwt_decode from 'jwt-decode';
import { AuthService } from '@blueprint/auth/auth.service';
import { Store } from '@domain/store';
import { Project } from '@domain/models/project.model';
import { Client } from '@domain/models/client.model';
import { Address } from '@domain/models/address.model';
import { Contact } from '@domain/models/contact.model';
import { ProjectActivity } from '@domain/models/project-activity.model';
import { ProjectSpecialty } from '@domain/models/project-specialty.model';
import { Inventory } from '@domain/models/inventory.model';
import { Quotation } from '@domain/models/quotation.model';
import { DataService } from '@shared/services/data.service';
import { WorkAssignment } from '@domain/models/work-assignment.model';
import { SelectItem } from '@node_modules/primeng/components/common/selectitem';
import { BehaviorSubject } from '@node_modules/rxjs';
import { environment } from '@environments/environment';
import { ProjectMaterial } from '@domain/models/project-material.model';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { Picture } from '@domain/models/picture.model';
import { Response } from '@angular/http';

@Injectable()
export class SynchronisationService {
  public SynchronisingCompleted = new Subject<any>();
  public synchronisingAction$ = new BehaviorSubject<boolean>(true);
  public shouldSync: boolean;

  private store = Store.getStore();
  private state = { added: false, finished: false };

  constructor(
    private api: ApiServiceWithLoaderService,
    private auth: AuthService,
    private dataService: DataService,
  ) {
    // Register to internet connection online event
    window.addEventListener(
      'online',
      async () => {
        if (this.shouldSync) {
          await this.synchronise();

          this.shouldSync = false;
        }
      },
      false,
    );
  }

  public async clearProjectData(projectId: string): Promise<any> {
    const project = await Project.query.get({ id: projectId });
    const quotation = await Quotation.query.get({ project_id: projectId });
    const inventory = await Inventory.query.get({ project_id: projectId });
    const workAssignment = await WorkAssignment.query.get({ project_id: projectId });

    return Store.getStore().table('projects').where('id').equals(projectId).delete().then(() => {
      try {
        return this.getProjectTables().forEach(async tableName => {
            let column = 'project_id';
            let value = project.id;

            if (['quotation_materials', 'quotation_tasks'].includes(tableName)) {
              if (!quotation || quotation.id) {
                return;
              }

              column = 'quotation_id';
              value = quotation.id;
            } else if (['inventory_items'].includes(tableName)) {
              if (!inventory || inventory.id) {
                return;
              }

              column = 'inventory_id';
              value = inventory.id;
            } else if (['projects'].includes(tableName)) {
              if (!project || !project.id) {
                return;
              }

              column = 'id';
              value = project.id;
            } else if (['clients'].includes(tableName)) {
              if (!project || !project.client_id) {
                return;
              }

              column = 'id';
              value = project.client_id;
            } else if (['contacts'].includes(tableName)) {
              if (!project || !project.client_id) {
                return;
              }

              column = 'client_id';
              value = project.client_id;
            } else if (['work_assignment_items', 'address_work_assignments', 'signatures'].includes(tableName)) {
              if (!workAssignment || !workAssignment.id) {
                return;
              }

              column = 'work_assignment_id';
              value = workAssignment.id;
            }

            await Store.getStore().table(tableName)
              .where(column)
              .equals(value)
              .delete();
          },
        );
      } catch (error) {
        console.error(error);
        return null;
      }
    });
  }

  public setSynchronisingAction(action: boolean): void {
    this.synchronisingAction$.next(action);
  }

  public getSynchronisingAction(): Observable<boolean> {
    return this.synchronisingAction$.asObservable();
  }

  public async synchronise(): Promise<void> {
    // Do not synchronize when not authenticated
    if (!this.auth.isAuthenticated()) {
      return;
    }

    // Check internet status, if not online, then sync later
    if (!navigator.onLine) {
      this.shouldSync = true;

      return;
    }

    await this.getListData();
    this.showSyncReadyToast();

    this.SynchronisingCompleted.next(this.state);
  }

  public showSyncReadyToast() {
    toastr.success('Gereed voor gebruik', 'Applicatie');
  }

  public showSyncStartToast() {
    toastr.info('Data wordt gesynchroniseerd', 'Synchroniseren');
  }

  public showSyncResultToast() {
    if (this.state.added === true) {
      toastr.success('Data geimporteeerd', 'Synchronisatie');
    } else {
      toastr.success('Data up-to-date', 'Synchronisatie');
    }
  }

  /**
   * State of synchronisation as observable
   */
  public synchronisationState() {
    return Observable.from([this.state]);
  }

  public async syncToBackend(clearOnSuccess: boolean = false, closedProjectIds: number[] = null): Promise<boolean> {
    let errorReceived = false;
    let projects = await Project.query.toArray();

    if (closedProjectIds) {
      projects = projects.filter(project => {
        return closedProjectIds.indexOf(project.id) !== -1;
      });
    }

    for (const project of projects) {
      // Check if id is set
      if (!project.id) {
        continue;
      }

      // Check if it isn't a read only project
      if (!project.editing_by || +project.editing_by === +jwt_decode(localStorage.getItem('token')).sub) {
        const data = await this.getSyncJson(project);
        const newData = JSON.parse(JSON.stringify(data));
        const originalData = project._original ? JSON.parse(JSON.stringify(project._original)) : {};

        // Compare new data and original
        const diff = this.getDiff(newData, originalData);
        if (diff && project.id) {
          diff.id = project.id;

          const projectWouldBeDuplicated = (originalData && originalData.project && (originalData.project.id === originalData.project.client_id)) ||
              newData && newData.project && (newData.project.id === newData.project.client_id);

          if (projectWouldBeDuplicated) {
            toastr.warning(`Project ${data.project.reference_nr} lijkt gedupliceerd te zijn! Neem contact op met de beheerder.`, 'Foutmelding synchronisatie');
            // errorReceived = true;
          }

          // Apply changes to server
          try {
            const results = await this.api.post('/sync', [diff]).toPromise();
            for (let i = 0; i < results.length; i++) {
              const response = results[i];
              if (response !== 'ok') {
                errorReceived = true;
                console.error('Error response: ', results);

                if (response === 'SEND_PROJECT_ERROR') {
                  toastr.error('Er is een fout opgetreden tijdens het synchroniseren van de planning.', 'Foutmelding synchronisatie');
                } else {
                  toastr.error('Foutmelding: ' + response, 'Foutmelding synchronisatie');
                }
              } else {
                toastr.success('Project succesvol gesynchroniseerd', 'Synchronisatie');
              }

              await this.loadSingleProjectData(project.id, true);
            }

            if (!errorReceived) {
              if (clearOnSuccess) {
                await this.clearProjectData(project.id);
              } else {
                // Update status
                const updateProjects = await Project.query.toArray();
                for (const proj of updateProjects) {
                  proj.is_changed = false;
                  proj.is_new = false;

                  await this.dataService.createOrUpdate('projects', proj);
                }
              }
            }
          } catch (exception) {
            if (exception.status === 422 && exception.json().errors) {
              let errorTexts = [];
              const errors = exception.json().errors;

              Object.keys(errors).forEach(key => {
                errorTexts = errorTexts.concat(errors[key]);
              });

              toastr.error(errorTexts.join('<br/>'), 'Synchronisatie');
            } else {
            toastr.error(exception.statusText, 'Synchronisatie');
            }
          }
        } else {
          await this.clearProjectData(project.id);
        }
      } else {
        await this.clearProjectData(project.id);
      }
    }

    return new Promise((resolve) => {
      resolve(true);
    });
  }

  /**
   * Retrieves a single projects from backend and updates the client store
   */
  public async loadSingleProjectData(projectId: string, forceLoad: boolean = false) {
    let result;
    try {
      result = await this.api.get('/project/' + projectId).toPromise();
    } catch (e) {
      // Ignore error
    }
    if (!result || !result.data || !result.data.projects || !result.data.projects[0]) {
      return;
    }

    // Check if project is already available locally
    const existingProject = await Project.query.get({
      id: result.data.projects[0].id,
    });
    if (!forceLoad && existingProject) {
      return;
    }

    // Determine order of processing
    const tables = this.getProjectTables();

    for (const table of tables) {
      // Save data from backend in table
      if (result.data[table]) {
        await this.store.table(table).bulkPut(result.data[table]);

        delete result.data[table];
      }
    }

    // Store a copy of the project tree to track changes
    const updateProject = await Project.query.get({ id: projectId });
    if (updateProject) {
      const copy = await this.getSyncJson(updateProject);
      updateProject._original = JSON.parse(JSON.stringify(copy)); // Clone object
      await this.dataService.createOrUpdate('projects', updateProject);
    }
  }

  /**
   * Retrieves SelectItem array from backend with the top mover locations
   */
  public async getTopMoverLocations(): Promise<SelectItem[]> {
    const result = await this.api.get('/location/topmovers-list').toPromise();

    return result.data;
  }

  /**
   * Retrieves SelectItem array from backend with the barrack locations
   */
  public async getBarrackLocations(): Promise<SelectItem[]> {
    const result = await this.api.get('/location/barracks-list').toPromise();

    return result.data;
  }

  /**
   * Retrieves SelectItem array from backend with the locationmanagers
   */
  public async getLocationManagers(): Promise<SelectItem[]> {
    const result = await this.api.get('/user/locationmanager/list').toPromise();

    return result.data;
  }

  /**
   * Retrieves SelectItem array from backend with the locationmanagers by location
   */
  public async getLocationManagersByLocation(id: number): Promise<SelectItem[]> {
    const result = await this.api.get(`/user/locationmanager/${id}/list`).toPromise();

    return result.data;
  }

  /**
   * Retrieves SelectItem array from backend with the car fuel types
   */
  public async getCarEuroCategories() {
    const result = await this.api.get('/car/euro-categories').toPromise();

    return result.data;
  }

  /**
   * Retrieves SelectItem array from backend with the car fuel types
   */
  public async getCarFuelTypes() {
    const result = await this.api.get('/car/fuel-types').toPromise();

    return result.data;
  }

  /**
   * Retrieves SelectItem array from backend with the locations
   */
  public async getLocationsList(): Promise<SelectItem[]> {
    const result = await this.api.get('/location/list').toPromise();

    return result.data;
  }


  /**
   * Lists all tables containing project data
   */
  public getProjectTables(): any {
    return [
      'quotations',
      'quotation_materials',
      'quotation_tasks',
      'inventories',
      'inventory_items',
      'projects',
      'clients',
      'contacts',
      'addresses',
      'project_activities',
      'project_specialties',
      'project_materials',
      // 'project_users',
      'work_assignments',
      'work_assignment_items',
      'address_work_assignments',
      'signatures',
      'pictures',
    ];
  }

  public async updateEditingByFlags(): Promise<void> {
    const openProjectIds: string[] = (await Project.query.toArray()).map((project: Project) => {
      return project.id;
    });

    await this.api.patch('/project/update-editing-by-flags', { data: openProjectIds }).toPromise();
  }


  /**
   * Retrieves list and base data from backend and updates the client store
   */
  private async getListData(): Promise<void> {
    const result = await this.api.get('/sync/lists').toPromise();
    const models = Object.keys(result);
    for (const model of models) {
      // Clear contents of table and replace with backend data
      await this.store.table(model).clear();
      await this.store.table(model).bulkAdd(result[model]);
    }

    // Add ARent materials if enabled
    // if (environment.features.arent_materials) {
    //   const arentMaterials = await this.api.get('/arent/materials').toPromise();
    //   await MaterialGroup.query.clear();
    //   await MaterialGroup.query.bulkAdd(arentMaterials.material_groups);
    //   await Material.query.clear();
    //   await Material.query.bulkAdd(arentMaterials.materials);
    // }
  }

  /**
   * Retrieves the transformed project data used for synchronising data to backend
   */
  private async getSyncJson(project: Project): Promise<any> {
    // Gather project, client, address, contact, options and quotation data

    const item: any = {};

    // Project
    item.project = project.getData();

    // Address
    item.addresses = [];
    const addresses = await Address.query
      .where('project_id')
      .equals(project.id)
      .toArray();
    for (const address of addresses) {
      item.addresses.push(address.getData());
    }

    // Client
    item.client = null;
    if (project.client_id) {
      const client = await Client.query.get(project.client_id);
      if (client && client.id && client.name) {
        item.client = client.getData();
      }
    }

    // Contact
    item.contacts = [];
    if (project.client_id) {
      const contacts = await Contact.query
        .where('client_id')
        .equals(project.client_id)
        .toArray();
      for (const contact of contacts) {
        item.contacts.push(contact.getData());
      }
    }

    // Inventory
    item.inventories = [];
    item.inventory_items = [];
    const inventories = await Inventory.query
      .where('project_id')
      .equals(project.id)
      .toArray();
    for (const inventory of inventories) {
      item.inventories.push(inventory.getData());

      await inventory.init();

      // Inventory items
      for (const inventoryItem of inventory.items) {
        item.inventory_items.push(inventoryItem.getData());
      }
    }

    item.pictures = await Picture.query
      .where('project_id')
      .equals(project.id)
      .toArray();


    // Project activities
    item.project_activities = [];
    const projectActivities = await ProjectActivity.query
      .where('project_id')
      .equals(project.id)
      .toArray();
    for (const projectActivity of projectActivities) {
      item.project_activities.push(projectActivity.getData());
    }

    // Project specialties
    item.project_specialties = [];
    const projectSpecialties = await ProjectSpecialty.query
      .where('project_id')
      .equals(project.id)
      .toArray();
    for (const projectSpecialty of projectSpecialties) {
      item.project_specialties.push(projectSpecialty.getData());
    }

    // Project materials
    item.project_materials = [];
    if (environment.features.arent_materials) {
      const projectMaterials = await ProjectMaterial.query
        .where('project_id')
        .equals(project.id)
        .toArray();
      for (const projectMaterial of projectMaterials) {
        item.project_materials.push(projectMaterial.getData());
      }
    }

    // Quotation
    item.quotations = [];
    item.quotation_materials = [];
    item.quotation_tasks = [];
    const quotations = await Quotation.query
      .where('project_id')
      .equals(project.id)
      .toArray();
    for (const quotation of quotations) {
      item.quotations.push(quotation.getData());

      await quotation.init();

      // Quotation materials
      for (const quotationMaterial of quotation.materials) {
        item.quotation_materials.push(quotationMaterial.getData());
      }

      // Quotation tasks
      for (const quotationTask of quotation.tasks) {
        item.quotation_tasks.push(quotationTask.getData());
      }
    }

    // Work assignment
    item.work_assignments = [];
    item.work_assignment_items = [];
    item.address_work_assignments = [];

    const workAssignments = await WorkAssignment.query
      .where('project_id')
      .equals(project.id)
      .toArray();

    for (const workAssignment of workAssignments) {
      item.work_assignments.push(workAssignment.getData());

      await workAssignment.init();

      // Work assignment items
      for (const workAssignmentItem of workAssignment.items) {
        item.work_assignment_items.push(workAssignmentItem.getData());
      }

      // Work assignment addresses
      for (const workAssignmentAddress of workAssignment.address_work_assignments) {
        item.address_work_assignments.push(workAssignmentAddress.getData());
      }
    }

    // Add project with associations to result
    return item;
  }

  private getDiff(newData: any, oldData: any): any {
    let result: any;
    if (Array.isArray(newData)) {
      result = [];
      for (const item of newData) {
        // Find item with same id in old data and compare
        const oldItem = oldData ? oldData.filter(o => o.id === item.id)[0] : undefined;
        if (!oldItem) {
          // Mark as new
          item._new = true;
          result.push(item);
        } else {
          // Item exists, add differences only
          const itemDiff = this.getDiff(item, oldItem);
          if (itemDiff) {
            // Always add id field
            itemDiff.id = item.id;
            result.push(itemDiff);
          }
        }
      }

      // Check if item is deleted
      if (oldData) {
        for (const oldDataItem of oldData) {
          if (newData.filter(o => o.id === oldDataItem.id).length === 0) {
            result.push({ _deleted: oldDataItem.id });
          }
        }
      }

      return result.length === 0 ? undefined : result;
    }

    result = {};
    for (const key of Object.keys(newData)) {
      const newEntity = newData[key];
      const oldEntity = oldData[key];

      // Always add if old item not exists
      if (newEntity && !oldEntity) {
        result[key] = newEntity;
        // Mark each array entry as _new if array
        if (Array.isArray(result[key])) {
          for (const item of result[key]) {
            if (typeof item === 'object') {
              // Mark as new
              item._new = true;
            }
          }
        } else if (typeof result[key] === 'object') {
          // Mark as new
          result[key]._new = true;
        }
        continue;
      }

      if (Array.isArray(newEntity)) {
        const itemDiff = this.getDiff(newEntity, oldEntity);
        if (itemDiff) {
          result[key] = itemDiff;
        }
        continue;
      }

      if (newEntity && typeof newEntity === 'object') {
        const itemDiff = this.getDiff(newEntity, oldEntity);
        if (itemDiff) {
          result[key] = itemDiff;
        }
        continue;
      }

      if (newEntity !== oldEntity) {
        result[key] = newEntity;
      }
    }

    // Add id field is result is available
    if (Object.keys(result).length === 0) {
      return undefined;
    }

    result.id = newData.id;
    return result;
  }
}
