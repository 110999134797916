import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ProjectService } from '@shared/services/project.service';
import { Project } from '@domain/models/project.model';
import { ConfirmationService, SelectItem } from 'primeng/primeng';
import { Inventory } from '@domain/models/inventory.model';
import { InventoryHeaderComponent } from '@features/inventory/inventory/header/inventory-header.component';
import { Subscription } from 'rxjs/Subscription';
import { Material } from '@domain/models/material.model';
import { ProjectMaterial } from '@domain/models/project-material.model';
import { DataService, QueryOptions } from '@shared/services/data.service';
import { InventoryItem } from '@domain/models/inventory-item.model';

@Component({
  selector: 'app-inventory-board',
  templateUrl: 'inventory-board.component.html'
})
export class InventoryBoardComponent implements OnInit, OnDestroy {
  @ViewChild('header') header: InventoryHeaderComponent;

  public project = new Project({});
  public inventories;
  public filteredInventoryItems: Array<any>;
  public selectedInventory = new Inventory({});
  public selectedInventoryId = null;
  public volumeTotal: number;
  public assemblyTotal: number;
  public packingTotal: number;
  public meterboxTotal: number;
  public totalAmountOfUnits: number;
  public totalAmountOfSelectedUnit: number;
  public disabled = false;
  public materials: Material[];
  public projectMaterials: any[];
  public selectedType: SelectItem;

  private subscriptionInventoryDeleted: Subscription;
  private subscriptionInventoryAdded: Subscription;
  private subscriptionProjectLoaded: Subscription;

  public constructor(private projectService: ProjectService,
                     private confirmationService: ConfirmationService,
                     private dataService: DataService) {
    this.selectedType = { label: 'Intern', value: 1 };
    this.materials = [];
    this.projectMaterials = [];
    this.totalAmountOfUnits = 0;
    this.totalAmountOfSelectedUnit = 0;
  }

  public async ngOnInit(): Promise<void> {
    this.filteredInventoryItems = [];
    this.project = this.projectService.getProject();
    this.disabled = this.project.status === 'booked';
    this.inventories = this.project.inventories;

    // When inventory is deleted reset selected id and inventory then reload all inventories
    this.subscriptionInventoryDeleted = this.projectService.inventoryDeleted$.subscribe(_ => {
      this.inventories = this.project.inventories;
      this.selectedInventoryId = 0;
      this.selectedType = { label: 'Intern', value: 1 };

      this.getSelectedInventory();
    });

    // Reload when inventory added
    this.subscriptionInventoryAdded = this.projectService.inventoryAdded$.subscribe(async (inventoryId) => {
      await this.project.loadInventories();

      this.inventories = this.project.inventories;
      this.selectedInventoryId = inventoryId;
      this.selectedType = { label: 'Intern', value: 1 };

      this.getSelectedInventory();
    });

    // Reload when project changes
    this.subscriptionProjectLoaded = this.projectService.projectLoaded$.subscribe((project) => {
      this.project = project;
      this.disabled = this.project.status === 'booked';
      this.inventories = this.project.inventories;

      this.projectService.setCurrentClient(this.project.client);
    });

    this.initTotalsAfterChange();

    await this.getSelectedInventory();
    await this.getMaterials();
    await this.getProjectMaterials();
    this.setInventoryType(this.selectedType);
  }

  public async getMaterials(): Promise<void> {
    const queryOptions = new QueryOptions({ usePaging: false });

    this.materials = await this.dataService.get('materials', queryOptions, '/materials');
  }

  public async getProjectMaterials(): Promise<void> {
    this.projectMaterials = await ProjectMaterial.query.where('project_id').equals(this.project.id).toArray();
  }

  /**
   * Check all inventories which match floor and room (default_inventory)
   */
  public onInventoryChange(inventory: any): void {
    if (!inventory) {
      return;
    }

    this.selectedInventory = inventory;
    this.selectedInventoryId = inventory.id;

    this.getSelectedInventory();
    this.initTotalsAfterChange();
  }

  /**
   * Set selected inventory type
   *
   * @param type
   */
  public setInventoryType(type: SelectItem): void {
    this.selectedType = type;
    this.setFilteredInventoryItems();
  }

  /**
   * Select or deselect assembly for every card on the board
   *
   * @param state
   */
  public setDisassembleAll(state: boolean): void {
    if (this.inventories.length > 0) {
      this.inventories.map((inventory: Inventory) => {
        if (inventory.id === this.selectedInventoryId) {
          this.filteredInventoryItems.forEach((item) => {
            item.disassemble = state;
          });
        }
      });
    }
  }

  /**
   * Search inventory for selected inventory from header
   */
  public getSelectedInventory(): void {
    this.filteredInventoryItems = [];

    if (this.inventories.length > 0) {
      this.inventories.map((inventory: Inventory) => {
        if (inventory.id === this.selectedInventoryId) {
          this.selectedInventory = inventory;

          this.setFilteredInventoryItems();

          this.filteredInventoryItems.forEach((item) => this.capitalizeItemName(item));
        }
      });
    }
  }

  /**
   * Update inventory item
   */
  public async onInventoryItemChange(inventoryItem: any): Promise<void> {
    await this.updateInventoryItem(this.selectedType, inventoryItem);

    this.initTotalsAfterChange();

    /** Sync with materials */
    await this.updateMaterials(inventoryItem);
  }

  /**
   * Delete inventory item
   */
  public onInventoryItemDelete(inventoryItem): void {
    this.removeInventoryItem(inventoryItem);
  }

  /**
   * Handle static item amount changes
   */
  public async onAmountChange(): Promise<void> {
    if (!this.selectedInventory) {
      return;
    }

    await this.projectService.updateInventory(this.selectedInventory);

    this.packingTotal = this.projectService.calculatePackingTotal();
    this.assemblyTotal = this.projectService.calculateAssemblyTotal();
  }

  /**
   * Create inventory item
   *
   * @param inventoryItem InventoryItem model
   */
  public async createInventoryItem(inventoryItem: any): Promise<void> {
    // Add inventory item to current selected inventory and also update in store
    inventoryItem.inventory_id = this.selectedInventoryId;

    this.selectedInventory.items.push(inventoryItem);

    await this.projectService.createOrUpdateInventoryItem(inventoryItem);

    this.getSelectedInventory();
  }

  /**
   * Update inventory item
   *
   * @param type
   * @param inventoryItem InventoryItem model
   */
  public async updateInventoryItem(type: SelectItem, inventoryItem: InventoryItem): Promise<void> {
    inventoryItem.inventory_id = this.selectedInventoryId;
    inventoryItem.type = type.value;

    await this.projectService.createOrUpdateInventoryItem(inventoryItem);
  }

  /**
   * Remove inventory item
   * @param inventoryItem
   */
  public removeInventoryItem(inventoryItem: any): void {
    this.confirmationService.confirm({
      message: 'Wilt u dit item verwijderen?',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      accept: async () => {
        await this.projectService.deleteInventoryItem(inventoryItem.id);

        const index = this.filteredInventoryItems.indexOf(inventoryItem);

        this.filteredInventoryItems.splice(index, 1);
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.subscriptionInventoryDeleted) {
      this.subscriptionInventoryDeleted.unsubscribe();
    }

    if (this.subscriptionInventoryAdded) {
      this.subscriptionInventoryAdded.unsubscribe();
    }

    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }
  }

  /** Capitalize the first charachter of item name */
  private capitalizeItemName(item: any): void {
    item.name = item.name.charAt(0).toUpperCase() + item.name.slice(1);
  }

  /**
   * Sort the inventory items on alphabetical order, filtered on inventory type
   *
   * @return void
   */
  private setFilteredInventoryItems(): void {
    const seperatedInventoryItems = this.selectedInventory.items.filter((item) => {
      return item.type === this.selectedType.value;
    });

    this.filteredInventoryItems = seperatedInventoryItems.sort((a, b) => {
      if (a.is_material === b.is_material) {
        return   (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
      }

      return (a.is_material < b.is_material) ? -1 : (a.is_material > b.is_material) ? 1 : 0;
    });
  }

  private async updateMaterials(inventoryItem): Promise<void> {
    /** shorthand function */
    const createNewProjectMaterial = async (material, item) => {
      if (item.amount === 0) {
        item.amount += item.increment;
      }

      const newProjectMaterial = new ProjectMaterial({
        project_id: this.project.id,
        material_id: material.id,
        amount: item.amount
      });

      await this.projectService.addMaterial(newProjectMaterial);
      await this.getProjectMaterials();
    };

    if (!(this.materials.length > 0)) {
      return;
    }

    const materialToUpdate = this.materials.find((material) => material.name.toLowerCase() === inventoryItem.name.toLowerCase());

    if (!materialToUpdate) {
      return;
    }

    const projectMaterialToUpdate = this.projectMaterials.find((item) => item.material_id === materialToUpdate.id);

    /** If no material exists yet for given ID, create a new one */
    if (projectMaterialToUpdate) {
      if (inventoryItem.subtraction) {
        (projectMaterialToUpdate.amount - inventoryItem.increment >= 0) ? projectMaterialToUpdate.amount -= inventoryItem.increment : null;
      } else {
        projectMaterialToUpdate.amount += inventoryItem.increment;
      }

      await this.projectService.updateMaterial(projectMaterialToUpdate);
    } else {
      await createNewProjectMaterial(materialToUpdate, inventoryItem);
    }
  }

  private initTotalsAfterChange(): void {
    this.volumeTotal = this.projectService.calculateVolume();
    this.packingTotal = this.projectService.calculatePackingTotal();
    this.assemblyTotal = this.projectService.calculateAssemblyTotal();
    this.meterboxTotal = this.projectService.calculateMeterboxTotal();
    this.totalAmountOfSelectedUnit = this.projectService.calculateTotalAmountOfUnit(this.selectedInventory);
    this.totalAmountOfUnits = this.projectService.calculateTotalAmountOfUnits(this.project.inventories);
  }
}
