import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from '@root/node_modules/rxjs';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { Location } from '@domain/models/location.model';
import { distinctUntilChanged, takeUntil, filter } from 'rxjs/operators';
import * as toastr from 'toastr';
import { ProjectService } from '@shared/services/project.service';
import { ValidateAlphaNumeric } from '@shared/validators/alpha-numeric-validator';
import { ValidateDutchZipcode } from '@shared/validators/dutch-zipcode-validator';
import { ValidateNonNegative } from '@shared/validators/non-negative-validator';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';

@Component({
  selector: 'app-topmovers-detail',
  templateUrl: './topmovers-detail.component.html',
  styleUrls: ['./topmovers-detail.component.scss']
})
export class ManageTopMoversDetailComponent implements OnInit, OnDestroy {
  public topmover: Location;
  public loading: boolean;
  public showErrors: boolean;
  public form: FormGroup;
  public topmoverId: number;
  public mode = { isAdd: true };

  private destroy$: Subject<boolean>;

  constructor(private formBuilder: FormBuilder,
    private api: ApiServiceWithLoaderService,
    private router: Router,
    private route: ActivatedRoute,
    private synchronisationService: SynchronisationService,
    private projectService: ProjectService
  ) {
    this.form = new FormGroup({});
    this.topmover = new Location({});

    this.mode.isAdd = false;
    this.loading = false;
    this.showErrors = false;
    this.destroy$ = new Subject<boolean>();
  }

  public async ngOnInit(): Promise<void> {
    this.loading = true;
    this.initForm();

    // Get id of url to edit by route params
    this.route.params
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$), filter((params) => params['id']))
      .subscribe((params) => {
        this.topmoverId = params['id'];
        this.mode.isAdd = (params['id'] === 'add');

        if (!this.mode.isAdd) {
          this.api.get(`/location/${this.topmoverId}`)
            .pipe(takeUntil(this.destroy$))
            .subscribe((result: any) => {
              this.topmover = result.data;
              this.initForm();
              this.loading = false;
            });
        } else {
          this.initForm();
          this.loading = false;
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  /**
   * Retrieves auto fill of address
   *
   * @param event: any
   *
   * @returns Promise<void>
   */
  public async onAddressChange(event: any): Promise<void> {
    const zipcode = this.form.value.zipcode;
    const housenumber = this.form.value.housenumber;
    const street = this.form.value.street;
    const city = this.form.value.city;

    if (!zipcode || !housenumber) {
      return;
    }

    if (street || city) {
      return;
    }

    const result = await this.api
      .post('/address/search', {
        zipcode: this.form.value.zipcode,
        housenumber: this.form.value.housenumber
      })
      .toPromise();

    if (!result || !result.city || !result.street || !result.country) {
      this.form.get('city').enable();
      this.form.get('street').enable();
      this.form.get('country').enable();
    }

    // Update street and city values
    this.form.patchValue({
      street: result.street,
      city: result.city,
      country: result.country
    });
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      name: [this.topmover.name ? this.topmover.name : '', Validators.required],
      street: [{ value: this.topmover.street ? this.topmover.street : '', disabled: true }, [Validators.required, ValidateAlphaNumeric]],
      housenumber: [this.topmover.housenumber ? this.topmover.street : '', [Validators.required, ValidateNonNegative]],
      housenumber_suffix: [this.topmover.housenumber_suffix ? this.topmover.housenumber_suffix : ''],
      zipcode: [this.topmover.zipcode ? this.topmover : '', [Validators.required, ValidateDutchZipcode]],
      city: [{ value: this.topmover.city ? this.topmover.city : '', disabled: true }, [Validators.required]],
      country: [{ value: this.topmover.country ? this.topmover.country : '', disabled: true }, [Validators.required, ValidateAlphaNumeric]],
      email: [this.topmover.email ? this.topmover.email : '', [Validators.required, Validators.email]],
    });

    if (this.topmover.address) {
      this.form.patchValue(this.topmover.address);
    }
  }

  public onSubmit(): void {
    if (this.form.valid) {
      this.showErrors = false;
      this.loading = true;

      const topmoverData = {
        ...this.topmover,
        ...this.form.getRawValue(),
        type: 'topmover',
      };

      let request;

      if (this.mode.isAdd) {
        request = this.api.post('/location', topmoverData);
      } else {
        request = this.api.patch(`/location/${this.topmoverId}`, topmoverData);
      }

      request.subscribe(() => {
        toastr.success('Top Mover succesvol opgeslagen', 'Top Movers');
        this.projectService.topMoversUpdated$.next();
      }, (error: any) => {
        if (error.status === 422 && error.json) {
          toastr.warning('Ongeldige invoer', 'Top Movers');
        } else {
          toastr.error('Fout bij opslaan Top Mover', 'Top Movers');
        }

        this.loading = false;
      });

      this.onCancel();
    } else {
      this.showErrors = true;
    }
  }

  public onCancel(): void {
    this.router.navigateByUrl('/admin/manage/topmovers');
  }
}
