import { Component, OnInit } from '@angular/core';
import { environment  } from '@environments/environment';
import { Store } from '@domain/store';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public environment: any;
  public version: string;

  constructor() {
    this.environment = environment;
    this.version = environment.version;

    // this.version = '1.0.0.2106150819';
  }

  public async ngOnInit(): Promise<void> {
    const versionNumber = this.version.substring(6);
    console.log('versionNumber: ', Number(versionNumber));
    if (Number(versionNumber) < 2106150819) {
      await Store.getStore().reset();
      location.reload(true);
    }
  }
}
