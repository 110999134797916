import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Quotation } from '@domain/models/quotation.model';
import { Project } from '@domain/models/project.model';
import { Contact } from '@domain/models/contact.model';
import { Address } from '@domain/models/address.model';
import { Client } from '@domain/models/client.model';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ProjectService } from '@shared/services/project.service';
import { DataService } from '@shared/services/data.service';
import { SignatureComponent } from '@shared/controls/signature/signature.component';
import { Subscription } from 'rxjs/Subscription';
import * as toastr from 'toastr';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { WorkAssignment } from '@domain/models/work-assignment.model';
import { CalendarLocale } from '@domain/models/calendar-locale.model';
import { environment } from '@environments/environment';
import { ConfirmationService } from 'primeng/primeng';
import { ProjectSpecialty } from '@domain/models/project-specialty.model';
import { Specialty } from '@domain/models/specialty.model';
import { Location } from '@domain/models/location.model';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { QuotationService } from '@shared/services/quotation.service';
import { Observable } from '@node_modules/rxjs';
import { BulkPriceTypes } from '@shared/enums/bulk-price-type.enum';

@Component({
  selector: 'app-inventory-quotation',
  templateUrl: 'quotation.component.html',
  styleUrls: ['./quotation.component.scss']
})
export class InventoryQuotationComponent implements OnInit, OnDestroy {
  @ViewChild('clientSignature') clientSignature: SignatureComponent;
  @ViewChild('valuatorSignature') valuatorSignature: SignatureComponent;

  public project: Project;
  public client: Client;
  public quotation: Quotation;
  public pickupAddress: Address;
  public deliverAddress: Address;
  public internalAddress: Address;
  public disabled = false;
  public project_id: string;
  public specialties: Specialty[];
  public projectSpecialties: ProjectSpecialty[];
  public workAssignments: WorkAssignment[];
  public workAssignmentAddresses: any;
  public environment: object;
  public localeNL: CalendarLocale;
  public locationDetails: any;
  public calculating: boolean;
  public hasSpecial: boolean;
  public totalOptionsPrice: number;
  public nowClient: string;
  public nowValuator: string;
  public inventoryItemPrices$: Observable<Record<BulkPriceTypes, number>>;

  private optionPricesIncludingVAT: number;
  private contact: Contact;
  private addresses: Address[];
  private quotationSent: boolean;
  private subscriptionProjectLoaded: Subscription;
  private doNotCalculate: boolean = false;

  constructor(private syncService: SynchronisationService,
              private projectService: ProjectService,
              private dataService: DataService,
              private api: ApiServiceWithLoaderService,
              private route: ActivatedRoute,
              private quotationService: QuotationService,
              private confirmationService: ConfirmationService) {
    this.localeNL = new CalendarLocale();
    this.calculating = false;
    this.hasSpecial = false;
    this.totalOptionsPrice = 0;
    this.optionPricesIncludingVAT = 0;
    this.quotation = new Quotation({});
    this.contact = new Contact({});
    this.client = new Client({});
    this.project = new Project({});
    this.environment = environment;
    this.addresses = [];
    this.specialties = [];
    this.projectSpecialties = [];
    this.nowClient = null;
    this.nowValuator = null;
    this.quotationSent = false;

    this.subscriptionProjectLoaded = this.projectService.projectLoaded$.subscribe(async (project) => {
      this.project = project;
      this.disabled = this.project.status === 'booked';

      if (this.project.client) {
        this.client = this.project.client;
      } else {
        this.client = new Client({});
      }

      this.addresses = this.project.addresses;

      // Determine main address and pickup/deliver address
      for (const address of this.addresses) {
        switch (address.type) {
          case 'Losadres':
            this.deliverAddress = address;
            break;

          case 'Intern':
            this.internalAddress = address;
            break;

          case 'Laadadres':
            this.pickupAddress = address;
            break;

          default:
            break;
        }
      }

      // Get first quotation
      if (this.project.quotations.length > 0) {
        this.quotation = this.project.quotations[0];
        // Update storage value total to make sure it's filled
      } else {
        const attributes = { project_id: this.project.id, estimated_distance_km: null };
        this.quotation = new Quotation(attributes);
      }

      await this.retrieveActivityAddresses();
      await this.initForm();
      this.projectService.setCurrentClient(this.project.client);

      await this.setInventoryItemPrices();

      await this.determineSpecial();
      await this.getTotalOptionsPrice();
      this.quotation.calculated_volume = this.projectService.calculateVolume();
      this.inventoryItemPrices$ = this.quotationService.getInventoryItemPrices(this.project);
    });

    this.initForm();
    this.determineSpecial();
    this.getTotalOptionsPrice();

    this.quotation.calculated_volume = this.projectService.calculateVolume();
  }

  public getProjectStatus(): string {
    return Project.getStatusName(this.project.status);
  }

  public async ngOnInit(): Promise<void> {
    // Reload project
    this.project = this.projectService.getProject();

    if (this.project.id) {
      await this.projectService.loadProject(this.project.id);
    }

    // this.route.params.subscribe(async (params) => {
    //   const result = await this.projectService.getAllWorkAssignments(params['project']);
    //   if (result) {
    //     this.workAssignments = result;
    //
    //     // await this.initWorkAssignmentAddresses();
    //   }
    // });

    await this.getLocation();
  }

  public async initForm(): Promise<void> {
    if (!this.project.digi_purchase_number) {
      this.project.digi_purchase_number = '606';
    }

    await this.updateDistance();
    await this.updateTotal();
  }

  /**
   * Determines whether project has Special option
   *
   * @returns boolean
   */
  public async determineSpecial(): Promise<void> {
    if (!this.project || !this.project.specialties) {
      return;
    }

    for (const specialty of this.project.specialties) {
      if (specialty.applicable && specialty.specialty_id) {
        const originalSpecialty = await Specialty.query.get(specialty.specialty_id);

        if (originalSpecialty.name === 'Special') {
          this.hasSpecial = true;
        }
      }
    }
  }

  public async ngOnDestroy(): Promise<void> {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }

    if (this.quotationSent) {
      this.quotation.status = 'already_queued';
    }

    await this.saveQuotation();
  }

  public async updateTotal(): Promise<void> {
    if (this.quotation) {
      if ((this.quotation.created_at &&
          new Date(this.quotation.created_at) >= new Date('2021-09-01')
      ) || this.quotation.created_at === undefined || this.project.status === 'new') {
        this.doNotCalculate = false;

        this.calculating = true;

        // Sum prices
        this.quotation.subtotal_price = 0;
        this.quotation.subtotal_price += this.quotation.extern_excluding_disassemble_total_price || 0;
        this.quotation.subtotal_price += this.quotation.extern_including_disassemble_total_price || 0;
        this.quotation.subtotal_price += this.quotation.intern_excluding_disassemble_total_price || 0;
        this.quotation.subtotal_price += this.quotation.intern_including_disassemble_total_price || 0;
        this.quotation.subtotal_price += this.quotation.specials_price || 0;
        this.quotation.subtotal_price += this.quotation.custom_option_1_price || 0;
        this.quotation.subtotal_price += this.quotation.custom_option_2_price || 0;
        this.quotation.subtotal_price += this.quotation.custom_option_3_price || 0;
        this.quotation.subtotal_price += this.quotation.total_options_price || 0;

        // Calculate vat (exclude certificates, insurances)
        const calcVat = (amount) => {
          return amount ? Math.round((amount * 100) * 0.21) / 100 : 0;
        };

        this.quotation.vat_price = 0;
        this.quotation.vat_price += calcVat(this.quotation.extern_excluding_disassemble_total_price);
        this.quotation.vat_price += calcVat(this.quotation.extern_including_disassemble_total_price);
        this.quotation.vat_price += calcVat(this.quotation.intern_excluding_disassemble_total_price);
        this.quotation.vat_price += calcVat(this.quotation.intern_including_disassemble_total_price);
        this.quotation.vat_price += calcVat(this.quotation.specials_price);
        this.quotation.vat_price += calcVat(this.quotation.custom_option_1_price);
        this.quotation.vat_price += calcVat(this.quotation.custom_option_2_price);
        this.quotation.vat_price += calcVat(this.quotation.custom_option_3_price);
        // Calcuate VAT for options, excluding the options without VAT
        this.quotation.vat_price += calcVat(this.optionPricesIncludingVAT);

        this.quotation.total_price = this.quotation.subtotal_price + this.quotation.vat_price;

        this.calculating = false;
      } else {
        if (this.doNotCalculate === false) {
          toastr.error('(Na)calculatie wordt niet herberekend i.v.m. tariefwijzigingen per 01-09-2021', 'Foutmelding');
          this.doNotCalculate = true;
        }
      }
    }
  }

  public async submitQuotation(status: string): Promise<void> {
    switch (status) {
      case 'pending':
        this.quotationSent = true;
        this.quotation.status = 'queued';
        break;
      case 'booked':
        this.quotationSent = true;
        this.quotation.status = 'quotation_sent';
        break;
      default:
        break;
    }

    this.saveQuotation().then(() => {
      this.project.status = status;

      this.projectService.saveProject().then(() => {
        this.syncService.syncToBackend().then((result: any) => {
          if (result) {
            this.api.get('/project/' + this.project.id).subscribe((project) => {
              if (project) {
                if (project.data.projects && project.data.projects[0]) {
                  // this.project.editing_by = project.data.projects[0].editing_by;
                  this.project.reference_nr = project.data.projects[0].reference_nr;
                }

                toastr.success('(Na)calculatie succesvol verstuurd', 'Synchronisatie');
              } else {
                toastr.error('Project niet succesvol opgehaald', 'Synchronisatie');
              }
            });
          }
        }).catch(_ => {
          toastr.error('Er is een fout opgetreden, (na)calculatie is niet verstuurd', 'Synchronisatie');
          this.syncService.shouldSync = true;
        });
      });
    });
  }

  public showClientSignatureForm(): void {
    this.nowClient = this.getMoment();

    this.clientSignature.showForm();
  }

  public showValuatorSignatureForm(): void {
    this.nowValuator = this.getMoment();

    this.valuatorSignature.showForm();
  }

  private getMoment(): string {
    return moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
  }

  /**
   * Get total price of all options
   *
   * @returns Promise<void>
   */
  private async getTotalOptionsPrice(): Promise<void> {
    if (!this.project || !this.project.specialties) {
      return;
    }

    this.quotation.total_options_price = 0;
    this.optionPricesIncludingVAT = 0;

    let result = 0;
    let includingVAT = 0;

    for (const projectSpecialty of this.project.specialties) {
      await projectSpecialty.init();

      if (projectSpecialty.applicable && projectSpecialty.specialty && projectSpecialty.hours_estimate > 0) {
        result += (projectSpecialty.hours_estimate * projectSpecialty.specialty.cost_rate);

        // Calculate options which include VAT calculation
        if (projectSpecialty.specialty.includes_vat) {
          includingVAT += (projectSpecialty.hours_estimate * projectSpecialty.specialty.cost_rate);
        }
      }

      if (projectSpecialty.applicable && projectSpecialty.applicable === true &&
          projectSpecialty.specialty.measure === null && projectSpecialty.specialty.cost_rate > 0) {
        result += projectSpecialty.specialty.cost_rate;

        if (projectSpecialty.specialty.includes_vat) {
          includingVAT += projectSpecialty.specialty.cost_rate;
        }
      }
    }

    this.quotation.total_options_price = result;
    this.optionPricesIncludingVAT = includingVAT;

    await this.updateTotal();
  }

  /**
   * Retrieve location details for topmover
   *
   * @returns Promise<void>
   */
  private async getLocation(): Promise<void> {
    await this.project.init();

    if (!this.project.location) {
      return;
    }

    if (this.project.location.id) {
      this.locationDetails = await Location.query.get(this.project.location.id);
    } else {
      this.locationDetails = this.project.location;
    }
  }

  // private async initWorkAssignmentAddresses(): Promise<void> {
  //   this.workAssignmentAddresses = [];
  //   for (const assignment of this.workAssignments) {
  //     if (assignment.address_work_assignments.length > 0) {
  //       for (const address of assignment.address_work_assignments) {
  //         this.workAssignmentAddresses.push(address);
  //       }
  //     }
  //   }
  //
  //   await this.updateDistance();
  // }

  private async saveQuotation(): Promise<void> {
    this.quotation.estimated_distance = this.quotation.estimated_distance_km > 0 ? this.quotation.estimated_distance_km * 1000 : null;
    await this.projectService.saveQuotation(this.quotation);
  }

  private async updateDistance(): Promise<void> {
    if (!this.pickupAddress || !this.deliverAddress) {
      return;
    }

    let result = null;
    try {
      result = await this.api
          .post('/address/distance', {
            from: {
              street: this.pickupAddress.street,
              housenumber: this.pickupAddress.housenumber,
              zipcode: this.pickupAddress.zipcode,
              city: this.pickupAddress.city,
              country: this.pickupAddress.country
            },
            to: {
              street: this.deliverAddress.street,
              housenumber: this.deliverAddress.housenumber,
              zipcode: this.deliverAddress.zipcode,
              city: this.deliverAddress.city,
              country: this.deliverAddress.country
            }
          })
          .toPromise();
    } catch (e) {
      toastr.error('Afstand kon niet worden berekend', 'Afstand berekenen');
    }

    if (result && result.data && result.data > 0) {
      this.quotation.distance = result.data;
    } else {
      this.quotation.distance = 0;
    }

    this.quotation.distance_km = this.quotation.distance / 1000;
    this.quotation.estimated_distance_km = this.quotation.estimated_distance > 0 ? this.quotation.estimated_distance / 1000 : null;
  }

  /** Asynchroniously fetch the address belonging to the activity */
  private async retrieveActivityAddresses(): Promise<void> {
    for (const activity of this.project.activities) {
      if (activity.address_id) {
        const address: Address = await this.dataService.getById('addresses', activity.address_id);

        activity.address = address ? address.getDisplayName() : null;
      }
    }
  }

  private async setInventoryItemPrices(): Promise<void> {
    const prices = await this.quotationService.getInventoryItemPrices(this.project).toPromise();

    this.quotation.extern_excluding_disassemble_total_price = prices[BulkPriceTypes.externalExcludingDisassembly];
    this.quotation.extern_including_disassemble_total_price = prices[BulkPriceTypes.externalIncludingDisassembly];
    this.quotation.intern_excluding_disassemble_total_price = prices[BulkPriceTypes.internalExcludingDisassembly];
    this.quotation.intern_including_disassemble_total_price = prices[BulkPriceTypes.internalIncludingDisassembly];

    await this.updateTotal();
  }
}
