import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OnlineTableOptions } from '@shared/controls/table/online-table.component';
import { UserService } from '@shared/services/user.service';
import * as toastr from 'toastr';
import { SelectItem } from '@node_modules/primeng/primeng';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';

@Component({
  selector: 'app-user-overview',
  templateUrl: 'user-overview.component.html'
})
export class UserOverviewComponent implements OnInit {
  public organisationsList: SelectItem[] = [];
  public tableOptions: OnlineTableOptions;

  private userId: number;

  public constructor(
    private api: ApiServiceWithLoaderService,
    private router: Router,
    private userService: UserService) {
    this.userId = this.userService.getUserRoleId();
  }

  public async ngOnInit() {
    this.api.get(`/organisation/list`).subscribe((result: any) => {
      this.organisationsList = result.data;
    });

    this.tableOptions = new OnlineTableOptions({
      columns: [
        {
          title: 'Voornaam',
          name: 'first_name',
          filter: {
            global: true,
            field: 'first_name',
            filterMode: 'like'
          }
        },
        {
          title: 'Achternaam',
          name: 'last_name',
          filter: {
            global: true,
            field: 'last_name',
            filterMode: 'like'
          }
        },
        {
          title: 'E-mail',
          name: 'email',
          filter: {
            global: true
          }
        },
        {
          title: 'Rol',
          name: 'roleName',
          filter: {
            type: 'select',
            column: 'role_id',
            options: [
              { label: 'Alles', value: '' },
              { label: 'Administrator', value: 2 },
              { label: 'Aanvrager', value: 3 },
              { label: 'Verhuizer', value: 4 },
            ],
          }
        },
        /*{
          title: 'Deelnemers',
          name: 'organisationName',
          filter: {
            global: false
          }
        },*/
      ],
      endpoint: '/user',
      withDelete: this.userService.isAdministrator(),
      withFilter: true,
      search: true,
      rowDataTransformer: (rows) => {
        for (const row of rows) {
          row.roleName = row.role.name;

          row.organisationName = '';
          row.organisationName = row.organisations.map(organisation => organisation.name).join(', ');
        }

        return rows;
      },
    });
  }

  public onRowClick(row: any) {
    if (this.userId <= row.role_id) {
      this.router.navigateByUrl(`/admin/users(popup:admin/user/${row.id})`);
    } else {
      toastr.error(`U bent niet bevoegd om een gebruiker met een ${row.role.name.toLowerCase()} rol te bewerken`, 'Authenticatie');
    }
  }

  public onAddClick() {
    this.router.navigateByUrl('/admin/users(popup:admin/user/add)');
  }
}
