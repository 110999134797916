import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmationService } from '@node_modules/primeng/components/common/confirmationservice';
import { ProjectService } from '@shared/services/project.service';
import { UserService } from '@shared/services/user.service';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import * as toastr from 'toastr';
import { Car } from '@domain/models/car.model';
import { SelectItem } from '@node_modules/primeng/components/common/selectitem';
import { DataService } from '@shared/services/data.service';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';

@Component({
  selector: 'app-cars-detail',
  templateUrl: './cars-detail.component.html',
  styleUrls: ['./cars-detail.component.scss']
})
export class ManageCarsDetailComponent implements OnInit {
  public form: FormGroup;

  public car: Car;
  public topMoverLocations: SelectItem[];
  public fuelTypes: SelectItem[];

  public isAdd: boolean;
  public loading: boolean;
  public showErrors: boolean;

  public mode = {
    isAdd: true
  };

  private readonly licensePlatePattern: RegExp;

  constructor(private formBuilder: FormBuilder,
              private api: ApiServiceWithLoaderService,
              private router: Router,
              private sanitizer: DomSanitizer,
              private confirmationService: ConfirmationService,
              private route: ActivatedRoute,
              private dataService: DataService,
              private projectService: ProjectService,
              private userService: UserService,
              private synchronisationService: SynchronisationService) {
    this.form = new FormGroup({});
    this.car = new Car({}).getData();
    this.topMoverLocations = [];
    this.fuelTypes = [];

    this.isAdd = false;
    this.loading = false;
    this.showErrors = false;

    this.licensePlatePattern = /^[A-Z0-9-]+-[A-Z0-9-]+-[A-Z0-9-]+$/;
  }

  public async ngOnInit(): Promise <void> {
    this.loading = true;

    this.topMoverLocations = await this.synchronisationService.getTopMoverLocations();
    this.topMoverLocations = this.dataService.sortDropdownByLabel(this.topMoverLocations);

    this.fuelTypes = await this.synchronisationService.getCarFuelTypes();
    this.fuelTypes = this.dataService.sortDropdownByLabel(this.fuelTypes);

    // Get id of url to edit by route params
    this.route.params.subscribe((params) => {
      const id = params['id'];
      this.isAdd = id === 'add';

      if (!this.isAdd) {
        this.api.get('/car/' + id).subscribe((car) => {
          this.car = car;
          this.initForm();
          this.loading = false;
        });
      } else {
        this.initForm();
        this.loading = false;
      }
    });
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      location_id: [this.car.location_id || null, Validators.required],
      name: [this.car.name || null, Validators.required],
      license_plate: [this.car.license_plate || null, [Validators.required, Validators.pattern(this.licensePlatePattern)]],
      fuel_type_id: [this.car.fuel_type_id || null, Validators.required],
      compensate_at_pump: [this.car.compensate_at_pump || false, Validators.required]
    });
  }

  public onSubmit(): void {
    if (this.form.valid) {
      this.showErrors = false;
      this.loading = true;

      const carData = { ...this.car, ...this.form.value };
      const request = this.isAdd ? this.api.post('/car', carData) : this.api.patch('/car/' + carData.id, carData);

      request.subscribe(
        () => {
          toastr.success('Voertuig succesvol opgeslagen', 'Voertuigen');
          this.projectService.carsUpdated$.next();
        },
        (error: any) => {
          this.loading = false;
          if (error.status === 422 && error.json) {
            toastr.warning('Ongeldige invoer', 'Voertuigen');
          } else {
            toastr.error('Fout bij opslaan voertuig', 'Voertuigen');
          }
        }
      );

      this.onCancel();
    } else {
      this.showErrors = true;
    }
  }

  public onCancel(): void {
    this.router.navigateByUrl('/admin/manage/cars');
  }
}
