import { DomainModel } from '@domain/domain.model';

import * as uuid from 'uuid/v4';

export class Organisation extends DomainModel {
  // Configuration
  public entity = 'organisation';
  public table = 'organisations';
  public schema = 'id, name';
  public sync = true;
  public id: string;

  // Fields
  public name: string;

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }

    if (!attributes.name) {
      this.name = '';
    }
  }

  public getData(): any {
    return {
      id: this.id,
      name: this.name,
    };
  }
}
