import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { EmendisBlueprintModule } from '@blueprint/emendis-blueprint.module';
import { SharedModule } from '@shared/shared.module';
import { LoginComponent } from './login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

@NgModule({
  declarations: [
    LoginComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    EmendisBlueprintModule,
    SharedModule,
  ],
  exports: [
    LoginComponent
  ],
})

export class LoginModule {
}
