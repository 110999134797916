import { Component, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@emendis/blueprint/src/module/auth/auth.service';
import { environment } from '@environments/environment';
import * as toastr from 'toastr';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
    @ViewChild('resetPasswordForm')
    public resetForm: FormGroup;

    public resetPasswordToken: string;
    public email: string;
    public password: string;
    public passwordConfirmation: string;
    public environment = environment;
    public today: Date = new Date();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService
    ) {
        this.route.params.subscribe((params) => {
            if (params['token']) {
                this.resetPasswordToken = params['token'];
            }
        });
    }

    public submit(): void {
        if (this.resetForm.valid) {
            const data = {
                token: this.resetPasswordToken,
                email: this.email,
                password: this.password,
                password_confirmation: this.passwordConfirmation
            };

            this.authService.setNewPassword(data).subscribe(() => {
                toastr.success('Wachtwoord gereset, u wordt doorgestuurd naar de login-pagina...');

                this.router.navigate(['auth/login']);
            }, (errorResponse) => {
                toastr.error('Er is iets fout gegaan. Probeer het opnieuw.');
            });
        } else {
            toastr.error('Controleer of u alle velden juist ingevuld hebt.');
        }
    }

    public back(): void {
        this.router.navigate(['auth/login']);
    }
}