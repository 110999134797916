import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ProjectService } from '@shared/services/project.service';

@Injectable()
export class ContactListGuard implements CanActivate {

  public constructor( private projectService: ProjectService ) {}

  /**
   * Check if project-client has any contact persons
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Promise<boolean>}
   */
  canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ){

      return this.projectService.hasContacts(route.parent.params.project);
  }
}
