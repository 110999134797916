import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

// Libraries, 3d party components
import { PaginationModule } from 'ngx-bootstrap';

// Emendis Blueprint
import { EmendisBlueprintModule } from '@blueprint/emendis-blueprint.module';

// Custom components
import { OfflineTableComponent } from '@shared/controls/table/offline-table.component';
import { OnlineTableComponent } from '@shared/controls/table/online-table.component';
import { AsteriksComponent } from '@shared/controls/asteriks/asteriks.component';
import { AppPatternRestrictDirective } from '@shared/directives/app-pattern-restrict.directive';
import { CheckboxModule, DataTableModule, DropdownModule, InputSwitchModule } from 'primeng/primeng';
import { TooltipModule } from 'primeng/primeng';
import { MultiSelectModule } from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import { SignatureComponent } from '@shared/controls/signature/signature.component';
import { SignaturePadModule } from '@node_modules/angular2-signaturepad';
import { SortByPropertyPipe } from '@shared/pipes/sort-by-property.pipe';

@NgModule({
  declarations: [
    OfflineTableComponent,
    OnlineTableComponent,
    AppPatternRestrictDirective,
    AsteriksComponent,
    SignatureComponent,
    SortByPropertyPipe,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    EmendisBlueprintModule,
    PaginationModule.forRoot(),
    DataTableModule,
    TableModule,
    TooltipModule,
    MultiSelectModule,
    DropdownModule,
    CheckboxModule,
    InputSwitchModule,
    SignaturePadModule
  ],
  exports: [
    OfflineTableComponent,
    OnlineTableComponent,
    AsteriksComponent,
    AppPatternRestrictDirective,
    PaginationModule,
    MultiSelectModule,
    DropdownModule,
    CheckboxModule,
    InputSwitchModule,
    SignatureComponent,
    SignaturePadModule,
    SortByPropertyPipe
  ]
})

export class SharedModule {
}
