import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem } from 'primeng/primeng';
import { Subject } from '@root/node_modules/rxjs';
import { Location } from '@domain/models/location.model';
import { distinctUntilChanged, takeUntil, filter } from 'rxjs/operators';
import * as toastr from 'toastr';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ProjectService } from '@shared/services/project.service';
import { UserService } from '@shared/services/user.service';
import { ValidateAlphaNumeric } from '@shared/validators/alpha-numeric-validator';
import { ValidateDutchZipcode } from '@shared/validators/dutch-zipcode-validator';
import { ValidateNonNegative } from '@shared/validators/non-negative-validator';
import { DataService } from '@shared/services/data.service';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';

@Component({
  selector: 'app-barracks-detail',
  templateUrl: './barracks-detail.component.html',
  styleUrls: ['./barracks-detail.component.scss']
})
export class ManageBarracksDetailComponent implements OnInit, OnDestroy {
  public barrack: Location;
  public loading: boolean;
  public showErrors: boolean;
  public form: FormGroup;
  public topmoverLocationList: SelectItem[];
  public barrackId: number;
  public mode = { isAdd: true };

  private destroy$: Subject<boolean>;

  constructor(private formBuilder: FormBuilder,
              private api: ApiServiceWithLoaderService,
              private router: Router,
              private route: ActivatedRoute,
              private synchronisationService: SynchronisationService,
              private projectService: ProjectService,
              private dataService: DataService,
              private userService: UserService) {
    this.barrack = new Location({});
    this.form = new FormGroup({});
    this.topmoverLocationList = [];

    this.mode.isAdd = false;
    this.loading = false;
    this.showErrors = false;
    this.destroy$ = new Subject<boolean>();
  }

  public async ngOnInit(): Promise<void> {
    this.loading = true;
    this.topmoverLocationList = await this.synchronisationService.getTopMoverLocations();
    this.topmoverLocationList = this.dataService.sortDropdownByLabel(this.topmoverLocationList);

    await this.initForm();

    // Get id of url to edit by route params
    this.route.params
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$), filter((params) => params['id']))
      .subscribe(async (params) => {
        this.barrackId = params['id'];
        this.mode.isAdd = (params['id'] === 'add');

        if (!this.mode.isAdd) {
          this.api.get(`/location/${this.barrackId}`)
          .pipe(takeUntil(this.destroy$))
          .subscribe(async (result: any) => {
            this.barrack = result.data;

            await this.initForm();

            this.loading = false;
          });
        } else {
          await this.initForm();

          this.loading = false;
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  /**
   * Retrieves auto fill of address
   *
   * @param event: any
   *
   * @returns Promise<void>
   */
  public async onAddressChange(event: any): Promise<void> {
    const zipcode = this.form.value.zipcode;
    const housenumber = this.form.value.housenumber;
    const street = this.form.value.street;
    const city = this.form.value.city;

    if (!zipcode || !housenumber) {
      return;
    }

    if (street || city) {
      return;
    }

    const result = await this.api
      .post('/address/search', {
        zipcode: this.form.value.zipcode,
        housenumber: this.form.value.housenumber
      })
      .toPromise();

    if (!result || !result.city || !result.street) {
      this.form.get('city').enable();
      this.form.get('street').enable();
      return;
    }

    // Update street and city values
    this.form.patchValue({
      street: result.street,
      city: result.city
    });
  }

  public async initForm(): Promise<void> {
    this.form = this.formBuilder.group({
      name: [this.barrack.name, Validators.required],
      street: [{ value: this.barrack.address && this.barrack.address.street ? this.barrack.address.street : '', disabled: true }, [Validators.required, ValidateAlphaNumeric]],
      housenumber: [this.barrack.address && this.barrack.address.housenumber ? this.barrack.address.housenumber : '', [Validators.required, ValidateNonNegative]],
      housenumber_suffix: [this.barrack.address && this.barrack.address.housenumber_suffix ? this.barrack.address.housenumber_suffix : ''],
      zipcode: [this.barrack.address && this.barrack.address.zipcode ? this.barrack.address.zipcode : '', [Validators.required, ValidateDutchZipcode]],
      city: [{ value: this.barrack.address && this.barrack.address.city ? this.barrack.address.city : '', disabled: true }, [Validators.required]],
      country: [this.barrack.address && this.barrack.address.country ? this.barrack.address.country : 'Nederland', [Validators.required, ValidateAlphaNumeric]],
      // parent_id: [this.barrack.parent_id ? this.barrack.parent_id : '', Validators.required]
    });

    // // Set parent_id to not required if user is locationmanager
    // if (this.userService.isLocationManager()) {
    //   this.form.get('parent_id').clearValidators();
    // }
  }

  public onSubmit(): void {
    if (this.form.valid) {
      this.showErrors = false;
      this.loading = true;

      const barrackData = {
        ...this.barrack,
        ...this.form.value,
        type: 'barrack',
        street: this.form.get('street').value,
        city: this.form.get('city').value
      };

      // Delete email, it comes in response on GET but we don't want it on a Barrack
      delete barrackData.email;

      let request;

      if (this.mode.isAdd) {
        request = this.api.post('/location', barrackData);
      } else {
        request = this.api.patch(`/location/${this.barrackId}`, barrackData);
      }

      request.subscribe(() => {
          toastr.success('Locatie succesvol opgeslagen', 'Locatie');
          this.projectService.barracksUpdated$.next();
        }, (error: any) => {
          if (error.status === 422 && error.json) {
            toastr.warning('Ongeldige invoer', 'Locatie');
          } else {
            toastr.error('Fout bij opslaan locatie', 'Locatie');
          }

          this.loading = false;
        });

      this.onCancel();
    } else {
      this.showErrors = true;
    }
  }

  public onCancel(): void {
    this.router.navigateByUrl('/admin/manage/barracks');
  }
}
