import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { OnlineTableOptions } from '@shared/controls/table/online-table.component';
import { ProjectService } from '@shared/services/project.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-topmovers-list',
  templateUrl: './topmovers-list.component.html',
  styleUrls: ['./topmovers-list.component.scss']
})
export class ManageTopMoversListComponent implements OnInit, OnDestroy {
  public tableOptions: OnlineTableOptions;
  public tableVisible: boolean;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private projectService: ProjectService
  ) {
    this.tableVisible = true;
  }

  public async ngOnInit(): Promise<void> {
    this.tableOptions = new OnlineTableOptions({
      columns: [
        { title: 'Naam', name: 'name', filter: { global: true } },
        { title: 'Adres', name: 'displayAddress', filter: { global: false }  },
        { title: 'E-mailadres', name: 'email', filter: { global: true } }
      ],
      endpoint: '/location/topmovers',
      search: true,
      withDelete: true,
      withFilter: false,
      rowDataTransformer: (rows) => this.transformRows(rows)
    });

    this.projectService.topMoversUpdated$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.tableVisible = false;

        setTimeout(() => {
          this.tableVisible = true;
        }, 0);
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  public onRowClick(row: any) {
    this.router.navigateByUrl(`admin/manage/topmovers(popup:admin/manage/topmovers/${row.id})`);
  }

  public addNewTopMover(): void {
    this.router.navigateByUrl('/admin/manage/topmovers(popup:admin/manage/topmovers/add)');
  }

  /**
   * Transforms rows
   *
   * @param rows: any[]
   *
   * @returns rows: any[]
   */
  private transformRows(rows: any[]): any[] {
    for (const row of rows) {
      row.displayAddress = row.address ? `${row.address.street} ${row.address.housenumber}${row.address.housenumber_suffix || ''}, ${row.address.zipcode}, ${row.address.city}` : '-';
      row.email = row.email ? row.email : '-';
    }

    return rows;
  }
}
