import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OnlineTableOptions } from '@shared/controls/table/online-table.component';

@Component({
  selector: 'app-addresses-list',
  templateUrl: './addresses-list.component.html',
  styleUrls: ['./addresses-list.component.scss']
})
export class ManageAddressesListComponent implements OnInit {
  public tableOptions: OnlineTableOptions;

  constructor(
      private router: Router
  ) {}

  public async ngOnInit(): Promise<void> {
    this.tableOptions = new OnlineTableOptions({
      columns: [
        { title: 'Straat', name: 'street', filter: { global: true } },
        { title: 'Plaats', name: 'city', filter: { global: true } },
        { title: 'Land', name: 'country', filter: { global: true } }
      ],
      endpoint: '/address-template',
      search: true,
      withDelete: true,
      withFilter: false,
      rowDataTransformer: (rows) => {
        for (const row of rows) {
          row.street = `${row.street} ${row.housenumber}${row.housenumber_suffix || ''}`;
          row.city = `${row.zipcode}, ${row.city}`;
        }
        return rows;
      },
    });
  }

  public onRowClick(row: any) {
    this.router.navigateByUrl(`admin/manage/addresses(popup:admin/manage/addresses/${row.id})`);
  }

  public addNewAddress(): void {
    this.router.navigateByUrl('/admin/manage/addresses(popup:admin/manage/addresses/add)');
  }
}
