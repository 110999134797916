import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Params } from '@angular/router';
import { MenuItem } from 'primeng/primeng';
import { ProjectService } from '@shared/services/project.service';
import { UserService } from '@shared/services/user.service';
import { Client } from '@domain/models/client.model';
import { combineLatest, Observable } from '@node_modules/rxjs';
import { Project } from '@domain/models/project.model';
import { first } from '@node_modules/rxjs/internal/operators';
import { of } from 'rxjs';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { SynchronisationService } from '@shared/services/synchronisation.service';

@Component({
  selector: 'app-inventory-flow',
  templateUrl: 'inventory-flow.component.html',
  styleUrls: ['./inventory-flow.component.scss']
})
export class InventoryFlowComponent implements OnInit {
  public currentIndex = 0;
  public project: Project;
  public routes: MenuItem[] = [
    { routerLink: 'client', label: 'Project' },
    { routerLink: 'address', label: 'Adressen' },
    { routerLink: 'contact', label: 'Contactpersonen' },
    { routerLink: 'pictures', label: 'Bestanden' },
    { routerLink: 'inventory', label: 'Inventarisatie' },
    { routerLink: 'options', label: 'Opties' },
    { routerLink: 'overview', label: 'Overzicht' },
    { routerLink: 'quotation', label: '(Na)calculatie' },
    { routerLink: 'work-assignment', label: 'Werkopdracht' },
  ];
  public client$: Observable<Client>;

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private projectService: ProjectService,
      private userService: UserService,
      private api: ApiServiceWithLoaderService,
      private syncService: SynchronisationService,
  ) {
    this.api.increaseLoaderValueByOne();

    if (this.userService.getUserRoleCode() === 'sales') {
      this.routes = this.routes.filter(route =>
        route.routerLink !== 'material' &&
        route.routerLink !== 'quotation' &&
        route.routerLink !== 'work-assignment'
      );
    }

    combineLatest([
      this.projectService.projectLoaded$,
      this.projectService.getCurrentClient(),
    ]).pipe(first()).subscribe(([project, client]) => {
      this.client$ = of(client);
      this.project = project;

      // this.projectService.updateProjectReadOnlyStatus(this.project);
      this.api.decreaseLoaderValueByOne();
    });
  }

  public ngOnInit(): any {
    this.processRouteParams();
    this.setCurrentIndex();

    // Listen to route changes to update current index
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setCurrentIndex();
      }
    });
  }

  public next(): void {
    if (this.currentIndex < this.routes.length) {
      this.router.navigate([this.routes[this.currentIndex + 1].routerLink], { relativeTo: this.route });
    }
  }

  public previous(): void {
    if (this.currentIndex > 0) {
      this.router.navigate([this.routes[this.currentIndex - 1].routerLink], { relativeTo: this.route });
    }
  }

  public cancel(): void {
    if (this.currentIndex === 0) {
      this.router.navigate(['/admin/projects']);
    }
  }

  public save(): void {
    this.router.navigateByUrl('/');
  }

  // Read project route id and load project in case of page refresh
  public processRouteParams(): void {
    this.route.params.subscribe(async (params: any) => {
      const projectId = params['project'];

      if (this.route.snapshot.queryParamMap.get('sync')) {
        await this.syncService.loadSingleProjectData(projectId);
      }

      await this.projectService.loadProject(projectId);
    });
  }

  // Set the index according to url
  public setCurrentIndex(): void {
    this.routes.forEach((route, index) => {
      if (this.router.url.includes(route.routerLink)) {
        this.currentIndex = index;
      }
    });
  }
}
