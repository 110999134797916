import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { ConfirmationService } from '@node_modules/primeng/components/common/confirmationservice';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';

export class OnlineTableOptions {
  public endpoint: string;
  public noResultsMessage = 'Er zijn geen gegevens gevonden';
  public search: boolean;
  public withDelete: boolean;
  public withFilter: boolean = true;
  public defaultFilters: {[key: string]: { value: string; }};

  public columns: Array<any> = [
    { title: 'Name', name: 'name' }
  ];

  public constructor(options: any = {}) {
    Object.assign(this, options);
  }

  public rowDataTransformer: (data: any[]) => any[] = (data) => data;
}

@Component({
  selector: 'em-online-table',
  templateUrl: 'online-table.component.html',
  styleUrls: ['./table.scss']
})
export class OnlineTableComponent implements OnInit {
  @ViewChild('dataTable') dataTable: any;

  @Input() options: OnlineTableOptions = new OnlineTableOptions();
  @Input() rows: any;

  @Output() rowClicked: EventEmitter<{ row: any, column: string }> = new EventEmitter<{ row: any, column: string }>();

  public loading: boolean;

  public totalRecords: number;
  private globalFilter$ = new Subject<string>();

  public constructor(private api: ApiServiceWithLoaderService, private confirmationService: ConfirmationService) {}

  public ngOnInit(): any {
    this.loading = true;

    // Subscribe to global filter
    this.globalFilter$.debounceTime(400)
      .distinctUntilChanged()
      .subscribe(term => {
        this.dataTable.filterGlobal(term, 'contains');
      });
  }

  public loadLazy(event) {
    this.loading = true;

    const pageIndex = Math.floor(event.first / event.rows) + 1;

    // Build API query
    let query = `?_pageIndex=${pageIndex}&_pageSize=${event.rows}`;

    // Global filter
    if (event.globalFilter) {
      query = `${query}&_globalFilter=${event.globalFilter}` +
        `&_globalFilterColumns=${this.options.columns.filter((column) => column.filter && column.filter.global)
          .map((column) => column.filter.field || column.name).join(',')}`;
    }

    // Column filter
    if (event.filters) {
      Object.keys(event.filters).forEach(key => {
        if (key === 'global') {
          return;
        }

        // Don't apply filter when value is empty
        if (event.filters[key].value === null || event.filters[key].value === '' || event.filters[key].value === undefined) {
          return;
        }

        query = `${query}&${key}=${event.filters[key].value}`;
      });
    }

    // Sorting
    if (event.sortField) {
      query = `${query}&_sortColumn=${event.sortField}&_sortDirection=${event.sortOrder === 1 ? 'asc' : 'desc'}`;
    }

    this.api.get(this.options.endpoint + query)
      .subscribe(result => {
        this.totalRecords = result.meta.pagination.total;
        this.rows = this.options.rowDataTransformer(result.data);
        this.loading = false;
    }, err => {
      this.loading = false;
    });
  }

  public onRowSelect(event) {
    this.rowClicked.emit(event.data);
  }

  public onSearchChanges(event) {
    this.globalFilter$.next(event.target.value);
  }

  public onFilterChange(column, value) {
    this.dataTable.filter(value, column, 'equals');
  }

  public onDeleteClick(value: any, event: any): void {
    event.stopPropagation();

    this.confirmationService.confirm({
      message: 'Weet u zeker dat u het geselecteerde item wilt verwijderen?',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      accept: () => {
        this.api.delete(`${this.options.endpoint }/${value.id}`).subscribe(() => {
          this.loadLazy({ first: 1, page: 1, filters: {}, rows: 10 });
        });
      }
    });
  }

  public getColumnValue(data: any, columnName: string): string {
    if (!data) {
      return '';
    }

    let result = data;
    const names = columnName.split('.');

    for (const name of names) {
      if (!result[name]) {
        return '';
      }

      result = result[name];
    }

    if (!result) {
      return '';
    }

    return result;
  }
}
