import { Component, OnDestroy, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/primeng';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProjectService } from '@shared/services/project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Client } from '@domain/models/client.model';
import { Project } from '@domain/models/project.model';
import { DataService, QueryOptions } from '@shared/services/data.service';
import { Subscription } from 'rxjs/Subscription';
import { UserService } from '@shared/services/user.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@environments/environment';
import { User } from '@domain/models/user.model';
import { Location } from '@domain/models/location.model';
import { CalendarLocale } from '@domain/models/calendar-locale.model';
import * as moment from 'moment';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { OrganisationUsers } from '@domain/models/organisation-users.model';
import { Organisation } from '@domain/models/organisation.model';

@Component({
  selector: 'app-inventory-client',
  templateUrl: 'client.component.html',
  styleUrls: ['./client.component.scss']
})

/**
 * InventoryClientComponent
 */
export class InventoryClientComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public errors: any = {};
  public showErrors = false;
  public client = new Client({});
  public project = new Project({});
  public usersList: SelectItem[] = [];
  public statusList: SelectItem[] = [];
  public locationsList: SelectItem[] = [];
  public organistionsList: SelectItem[] = [];
  public locations: Location[] = [];
  public organisations: Organisation[] = [];
  public environment: object;
  public localeNL: CalendarLocale = new CalendarLocale();
  public useOrganisations: boolean = false;

  private subscriptionProjectLoaded: Subscription;
  private subscriptionClientChanged: Subscription;
  private disabled: boolean;
  private currentUser: User;
  private usersOfLocation: User[];

  private canChangeStatus: boolean;

  private disabledForm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private destroy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public today: Date = new Date();

  /**
   * Constructor
   *
   * @param {FormBuilder} formBuilder
   * @param {ProjectService} projectService
   * @param {DataService} dataService
   * @param {ActivatedRoute} route
   * @param {Router} router
   * @param synchronisationService
   * @param userService
   */
  public constructor(
    private formBuilder: FormBuilder,
    private projectService: ProjectService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private synchronisationService: SynchronisationService,
    private userService: UserService
  ) {
    this.form = new FormGroup({});
    this.canChangeStatus = true;
    this.environment = environment;
  }

  public async ngOnInit(): Promise<void> {
    if (!this.userService.getUserId()) {
      return;
    }

    if (this.userService.getUserId() === 1) {
      // SuperUser doesn't exists in localDB.
      this.currentUser = new User({
        id: 1,
        role_id: 1,
        location_id: 1,
        name: 'Superuser',
        email: 'superuser@emendis.nl'
      });
    } else {
      this.currentUser = await this.dataService.getById('users', this.userService.getUserId());
    }

    this.usersOfLocation = [];
    this.project = this.projectService.getProject();

    await this.loadLists();

    this.disabled = this.project.status === 'booked';
    this.disabledForm$.next(this.disabled);

    if (!this.project.date_start) {
      this.project.date_start = moment(new Date()).format('YYYY-MM-DD');
    }

    if (this.project.client) {
      this.client = this.project.client;
    }

    // In case client has changed (selected from popup)
    this.subscriptionClientChanged = this.projectService.clientChanged$.subscribe(async (client) => {
      this.project.client_id = client.id;
      this.project.client = client;
      this.client = client;

      await this.updateForm();
    });

    // In case project loaded (in case of refresh)
    this.subscriptionProjectLoaded = this.projectService.projectLoaded$.subscribe(async (project) => {
      this.project = project;
      this.disabled = this.project.status === 'booked';
      this.disabledForm$.next(this.disabled);

      if (this.project.client) {
        this.client = this.project.client;
      } else {
        this.client = new Client({});
      }

      this.projectService.setCurrentClient(this.client);

      await this.updateForm();
    });

    this.initForm();
    await this.updateForm();

    this.disabledForm$.subscribe(async () => {
      /** Only check for this if project is booked */
      if (this.disabled) {
        this.canChangeStatus = this.userService.isAdministrator();
        await this.updateForm();
      }
    });
  }

  public async ngOnDestroy(): Promise<void> {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }

    if (this.subscriptionClientChanged) {
      this.subscriptionClientChanged.unsubscribe();
    }

    await this.saveClient();

    this.destroy$.next(true);
  }

  /**
   * Immediately update the form values when status is updated from superuser
   * @param event
   */
  public async onStatusChange(event: any): Promise<void> {
    if (this.disabled) {
      await this.saveClient();
    }
  }

  private async loadLists(): Promise<void> {
    let queryOptions = new QueryOptions({
      sortColumn: 'name',
      usePaging: false
    });

    let result = await this.dataService.get('locations', queryOptions, '/location-group/list');

    this.locations = result;

    for (const item of result) {
      if (item.type === 'topmover') {
        this.locationsList.push({ label: item.name, value: item.id });
      }
    }

    queryOptions = new QueryOptions({
      sortColumn: 'name_lcase',
      usePaging: false,
      columns: [{ name: 'location_id', filter: this.currentUser.location_id, filterMode: 'equals' }]
    });

    result = await this.dataService.get('users', queryOptions, '/users/list');

    this.usersOfLocation = result;
    this.usersList = [];
    result.forEach((user: User) => {
      this.usersList.push({ label: `${user.first_name} ${user.last_name}`, value: user.id });
    }, this);

    this.usersList = this.usersList.sort((one, two) => (one > two ? 1 : -1));

    this.statusList = Project.getStatusList();

    queryOptions = new QueryOptions({
      sortColumn: 'name',
      usePaging: false
    });

    this.organisations = await this.dataService.get('organisations', queryOptions, '/organisations/list');
    this.organistionsList = [];

    const userId = this.userService.getUserId();

    if (userId === 1) {
      this.organisations.forEach((organisation) => {
        this.organistionsList.push({
          label: organisation.name,
          value: organisation.id,
        });
      });
    } else {
      const organisationUsers = await OrganisationUsers.query.where({ user_id: userId }).toArray();

      if (organisationUsers && organisationUsers.length > 0) {
        organisationUsers.forEach((organisationUser) => {
          const matchedOrganisation = this.organisations.find(organisation => organisation.id === organisationUser.organisation_id);
          const organisationName = (matchedOrganisation && matchedOrganisation.name) ? matchedOrganisation.name : 'Onbekend';

          this.organistionsList.push({
            label: organisationName,
            value: organisationUser.organisation_id,
          });
        });
      }
    }

    this.organistionsList = this.organistionsList.sort((one, two) => (one > two ? 1 : -1));
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      reference_nr: this.formBuilder.control({
        value: this.project.reference_nr || '',
        disabled: true
      }),
      organisation_id: this.formBuilder.control({
        value: (this.project.organisation && this.project.organisation.id) ? this.project.organisation.id : this.project.organisation_id ? this.project.organisation_id : '',
        disabled: false
      }),
      digi_purchase_number: this.formBuilder.control({
        value: this.project.digi_purchase_number || '',
        disabled: (this.project.status === 'closed')
      }),
      a_number: this.formBuilder.control({
        value: this.project.a_number || '',
        disabled: (this.project.status === 'closed')
      }),
      name: this.formBuilder.control({ value: this.client.name || '', disabled: this.disabled }, [
        Validators.required,
        Validators.maxLength(255)
      ]),
      applicant: this.formBuilder.control({ value: this.project.applicant || '', disabled: this.disabled }, [
        Validators.required,
        Validators.maxLength(255)
      ]),
      location_id: this.formBuilder.control(
      {
        disabled: !(this.form.controls['status'] && this.form.controls['status'].value === 'new'),
        value: this.client.location_id || null
      }, [
        Validators.required
      ]),
      status: this.formBuilder.control({ value: this.project.status || '', disabled: false }),
      description: this.formBuilder.control({
        value: this.client.description || '',
        disabled: this.disabled
      }),
      date_start: this.formBuilder.control(
        {
          value: this.project.date_start || '',
          disabled: this.disabled
        },
        [Validators.required]
      ),
      remarks: this.formBuilder.control({ value: this.client.remarks || '', disabled: this.disabled })
    });
  }

  private async updateForm(): Promise<void> {
    this.form.reset({
      reference_nr: { value: this.project.reference_nr || '', disabled: true },
      organisation_id: { value: this.project.organisation_id || '', disabled: false },
      digi_purchase_number: { value: this.project.digi_purchase_number || '', disabled: (this.project.status === 'closed') },
      a_number: { value: this.project.a_number || '', disabled: (this.project.status === 'closed') },
      name: { value: this.client.name || '', disabled: this.disabled },
      applicant: { value: this.project.applicant || '', disabled: this.disabled },
      location_id: {
        value: this.client.location_id || null,
        disabled: !(this.form.controls['status'] && this.form.controls['status'].value === 'new'),
      },
      date_start: { value: this.project.date_start ? new Date(this.project.date_start as string) : null, disabled: !this.canChangeStatus },
      status: { value: this.project.status || '', disabled: false },
      description: { value: this.client.description || '', disabled: this.disabled },
      remarks: { value: this.client.remarks || '', disabled: this.disabled }
    });
  }

  private async saveClient(): Promise<void> {
    if (this.form.valid) {
      this.client.name = this.form.get('name').value;
      this.client.location_id = this.form.get('location_id').value;
      this.client.description = this.form.get('description').value;
      this.client.remarks = this.form.get('remarks').value;

      this.project.client = this.client;
      this.project.organisation_id = '22e96bec-f40a-4ec2-93cc-85808fe1aa2f';
      this.project.status = this.form.get('status').value;
      this.project.applicant = this.form.get('applicant').value;
      this.project.location.id = this.form.get('location_id').value;
      this.project.date_start = this.form.get('date_start').value
        ? `${moment(this.form.get('date_start').value).format('YYYY-MM-DD')}`
        : null;

      await this.projectService.saveClientAndProject();

      this.synchronisationService.setSynchronisingAction(true);
      this.projectService.setCurrentClient(this.client);
    }
  }
}
