import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { EmendisBlueprintModule } from '@blueprint/emendis-blueprint.module';

import { SharedModule } from '@shared/shared.module';

import { UserOverviewComponent } from './user-overview.component';
import { UserDetailComponent } from './user-detail.component';
import { RouterModule } from '@angular/router';
import { ProjectModule } from '@features/project/project.module';
import { CalendarModule } from 'primeng/primeng';

@NgModule({
  declarations: [
    UserOverviewComponent,
    UserDetailComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    EmendisBlueprintModule,
    SharedModule,
    RouterModule,
    ProjectModule,
    CalendarModule
  ],
  exports: [
    UserOverviewComponent
  ],
})

export class UserModule {
}
